import React, { useEffect } from 'react';
import { find, get, isEmpty, reduce } from 'lodash';
import { bugsnagNotify } from 'utils/bugsnagClientWrapper';
import { getCookie } from './cookie';

export const fieldInitialValues = fields =>
  reduce(fields, (acc, field) => ({ ...acc, [field.id]: get(field, 'initialValue', undefined) }), {});

export const getFieldValuesFromObject = (fields, values) =>
  reduce(
    fields,
    (acc, field) => ({ ...acc, [field.id]: get(values, [field.id], get(field, 'initialValue', undefined)) }),
    {},
  );

const mapQueryToFilterItems = (query, listFilters) => {
  const options = {};
  if (query && !isEmpty(query)) {
    Object.keys(query).forEach(type => {
      const filters = find(listFilters, { type });
      if (filters) {
        let filterItems = get(filters, 'items', []);
        if (filterItems?.length > 1) {
          options[type] = [];
          query[type].split(',').forEach(q => {
            const selectedFilter = filterItems.find(filterItem => {
              // NOTE: Ignoring the type check for finding matching filter item
              return get(filterItem, 'id') == q;
            });
            options[type].push({
              value: get(selectedFilter, 'id'),
              label: get(selectedFilter, 'name'),
              slug: get(selectedFilter, 'slug'),
            });
          });

          return;
        }

        filterItems = filterItems?.find(filterItem => {
          // NOTE: Ignoring the type check for finding matching filter item
          return get(filterItem, 'id') == get(query, type);
        });
        options[type] = {
          value: get(filterItems, 'id'),
          label: get(filterItems, 'name'),
          slug: get(filterItems, 'slug'),
        };
      }
    });
  }
  return options;
};

export function getFormFilters(formFields, queryValues, listFilters) {
  const queryFilterValues = mapQueryToFilterItems(queryValues, listFilters);
  const query = get(queryValues, 'query');
  if (query) {
    queryFilterValues.query = query;
  }
  const onlyFormValues = getFieldValuesFromObject(formFields, queryFilterValues);
  return onlyFormValues;
}

export function useFormFilters(formFields, queryValues, listFilters) {
  const fieldsInitialValues = fieldInitialValues(formFields);
  const [formValues, setFormValues] = React.useState(fieldsInitialValues);

  useEffect(() => {
    const queryFilterValues = mapQueryToFilterItems(queryValues, listFilters);
    const query = get(queryValues, 'query');
    if (query) {
      queryFilterValues.query = query;
    }
    const onlyFormValues = getFieldValuesFromObject(formFields, queryFilterValues);
    setFormValues(onlyFormValues);
  }, [formFields, queryValues, listFilters]);

  return [formValues, fieldsInitialValues];
}

export const getFlatFormValues = values => {
  const query = {};
  const keys = Object.keys(values);

  keys.forEach(key => {
    if (values[key] && typeof values[key] === 'string') {
      query[key] = values[key];
    }
    if (values[key] && values[key].value) {
      query[key] = values[key].value;
    }
  });
  return query;
};

export const getQueryStringFromObject = object =>
  Object.keys(object || {})
    .map(key => `${key}=${get(object, key)}`)
    .join('&');

export const submitHubspotForm = (portalId, formGuid, data) => {
  const hsCookieData = getCookie('hubspotutk');
  return fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({ ...data, hutk: hsCookieData }),
  });
};

export const submitHubspotEvent = (name, properties = {}) => {
  try {
    // eslint-disable-next-line no-underscore-dangle
    if (typeof window._hsq !== 'undefined')
      // eslint-disable-next-line no-underscore-dangle
      window._hsq.push([
        'trackCustomBehavioralEvent',
        {
          name,
          properties,
        },
      ]);
  } catch (e) {
    bugsnagNotify(e);
  }
};

export const getErrorText = (touched, errors, fieldName) => {
  if (!get(touched, fieldName, '')) {
    return null;
  }
  return get(errors, fieldName, '')
    .split(' ')
    .join(' ');
};

export const hasError = (touched, errors, fieldName) => get(touched, fieldName) && Boolean(get(errors, fieldName));
