import get from 'lodash/get';

import {
  checkUpdatePasswordKeyAction,
  cleanAggregationsAction,
  clearProfilesAction,
  enqueueSnackbarAction,
  getAgenciesAction,
  clearAgenciesAction,
  setStartAggregationAction,
  getAggregationsAction,
  getCompaniesAction,
  clearCompaniesAction,
  getProfilesAction,
  removeSnackbarAction,
  resetPasswordAction,
  updatePasswordAction,
  getArtistVoiceTypesAction,
  resetResetPasswordAction,
  getCitiesAction,
  resetCitiesAction,
  getVenuesAction,
  resetVenuesAction,
  getVenuesSearchAction,
  resetVenuesSearchAction,
  getStagesAction,
  useBufferValueAction,
  getWorkTypesAction,
  getMultipleWorkTypesAction,
  getWorkStylesAction,
  getPrivacyOptionsAction,
  getAOSProfessionsAction,
  getLanguagesAction,
  getDynamicCountriesAction,
  getMediaAttrTypesAction,
  getVideoFormatAttrTypesAction,
  getActiveProfileSubscriptionsAction,
  getProfileSubscriptionsAction,
  getSubscriptionsAction,
  setProfileIdsWithAccess,
  getSearchProductionsAction,
  addProfileIdsWithAccess,
  getProfileAccessRequestsAction,
  getOrganizationsAction,
} from './actions';
import { changePasswordAction } from '../UserContainers/AccountSettingsContainer/actions';
import {
  defaultEntityParams,
  defaultListParams,
  entityReducer,
  listReducer,
  namedListParams,
} from '../../utils/crudUtils';

import { CLEAN_SEARCH_PRODUCTIONS_LIST } from './constants';

export const initialState = {
  profilePermissions: {
    permissions: [],
    isLoading: false,
    loadedOnce: false,
    error: null,
  },
  savedPermissions: {
    permissions: [],
    agencies: [],
    companies: [],
    profileId: null,
  },
  profiles: [],
  isProfilesLoading: false,
  activeProfileData: {},
  isSwitchingProfile: false,

  activeProfileSubscriptions: [],
  profileSubscriptions: [],
  subscriptionChurnedStatus: false,
  subscriptions: defaultListParams,

  resetPasswordInfo: null,
  isResetPasswordKeyActive: false,
  isPasswordUpdated: false,

  startAggregations: false,
  aggregations: {
    error: null,
  },
  aggregationTypeLoading: '',

  artistProfessions: [],
  agencies: [],
  agenciesLoading: false,
  companies: [],
  companiesLoading: false,

  profilesList: [],
  profilesListLoading: false,
  profileIdsWithAccess: [],

  loading: false,
  error: null,
  notifications: [],
  loginError: null,
  loginLoading: false,
  loginTries: 0,

  voiceTypes: defaultListParams,
  cities: defaultListParams,
  venues: defaultListParams,
  venueSearch: [],
  venuesSearchLoading: false,
  stages: defaultListParams,

  settings: namedListParams('settings'),
  bufferValue: defaultEntityParams,
  workTypes: defaultListParams,
  workStyles: defaultListParams,
  privacyOptions: defaultListParams,
  aosProfessions: defaultListParams,
  notificationBanner: null,
  languages: defaultListParams,
  dynamicCountries: namedListParams('dynamicCountries'),
  userInfo: {
    data: {},
    error: null,
    isLoading: false,
  },
  isUserInfoLoading: false,
  attributeTypes: namedListParams('attributeTypes'),
  videoFormats: namedListParams('videoFormats'),

  searchProductions: defaultListParams,

  profileAccessRequests: [],
};

const appReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case changePasswordAction.TRIGGER:
      return { ...state, loading: true };
    case changePasswordAction.SUCCESS:
    case changePasswordAction.ERROR:
      return { ...state, loading: false, error: payload };

    case setProfileIdsWithAccess.TRIGGER:
    case setProfileIdsWithAccess.SUCCESS:
    case setProfileIdsWithAccess.FAILURE:
      return { ...state, profileIdsWithAccess: payload };

    case addProfileIdsWithAccess.TRIGGER:
      return { ...state, profileIdsWithAccess: [...state.profileIdsWithAccess, ...(payload || [])] };

    case getActiveProfileSubscriptionsAction.SUCCESS:
      return { ...state, activeProfileSubscriptions: payload };

    case getProfileSubscriptionsAction.SUCCESS:
      return { ...state, profileSubscriptions: payload };

    case getSubscriptionsAction.TRIGGER:
    case getSubscriptionsAction.SUCCESS:
    case getSubscriptionsAction.FAILURE: {
      return listReducer(state, type, payload, 'subscriptions');
    }

    case setStartAggregationAction.TRIGGER:
      return { ...state, startAggregations: true };

    case getAggregationsAction.TRIGGER:
      return { ...state, aggregationTypeLoading: get(payload, 'fieldName') || get(payload, 'query.aggregation_type') };
    case getAggregationsAction.SUCCESS:
      return {
        ...state,
        aggregations: { ...state.aggregations, [payload.aggregationType]: payload.data, error: null },
        aggregationTypeLoading: null,
        startAggregations: false,
      };
    case getAggregationsAction.FAILURE:
      return { ...state, aggregations: { error: payload }, aggregationTypeLoading: '', startAggregations: false };
    case cleanAggregationsAction.TRIGGER:
      return { ...state, aggregations: { ...state.aggregations, [payload]: [], error: null } };

    case getAgenciesAction.TRIGGER:
      return { ...state, agenciesLoading: true };
    case getAgenciesAction.SUCCESS:
      return { ...state, agencies: payload, agenciesLoading: false };
    case getAgenciesAction.FAILURE:
      return { ...state, agencies: [], agenciesLoading: false, error: payload };
    case clearAgenciesAction.TRIGGER:
      return { ...state, agencies: [] };

    case getCompaniesAction.TRIGGER:
      return { ...state, companiesLoading: true };
    case getCompaniesAction.SUCCESS:
      return { ...state, companies: payload, companiesLoading: false };
    case getCompaniesAction.FAILURE:
      return { ...state, companies: [], error: payload, companiesLoading: false };
    case clearCompaniesAction.TRIGGER:
      return { ...state, companies: [], companiesLoading: false };

    case getOrganizationsAction.TRIGGER:
      return { ...state, organizationsLoading: true };
    case getOrganizationsAction.SUCCESS:
      return { ...state, organizations: payload, organizationsLoading: false };
    case getOrganizationsAction.FAILURE:
      return { ...state, organizations: [], error: payload, organizationsLoading: false };

    case getProfilesAction.TRIGGER:
      return { ...state, profilesListLoading: true };
    case getProfilesAction.SUCCESS:
      return { ...state, profilesList: payload, profilesListLoading: false };
    case getProfilesAction.FAILURE:
      return { ...state, profilesList: [], error: payload, profilesListLoading: false };
    case clearProfilesAction.TRIGGER:
      return { ...state, profilesList: [], profilesListLoading: false };

    case resetPasswordAction.SUCCESS:
      return { ...state, resetPasswordInfo: 'success' };
    case resetPasswordAction.FAILURE:
      return { ...state, resetPasswordInfo: payload };

    case resetResetPasswordAction.TRIGGER:
      return { ...state, resetPasswordInfo: '' };

    case checkUpdatePasswordKeyAction.SUCCESS:
      return { ...state, isResetPasswordKeyActive: true };
    case checkUpdatePasswordKeyAction.FAILURE:
      return { ...state, resetPasswordInfo: payload };

    case updatePasswordAction.SUCCESS:
      return { ...state, isPasswordUpdated: true };

    case enqueueSnackbarAction.TRIGGER:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          { ...payload, key: get(payload, 'options.key', new Date().getTime() + Math.random()) },
        ],
      };

    case removeSnackbarAction.TRIGGER:
      return {
        ...state,
        notifications: state.notifications.filter(notification => get(notification, 'key') !== payload),
      };

    case getArtistVoiceTypesAction.TRIGGER:
    case getArtistVoiceTypesAction.SUCCESS:
    case getArtistVoiceTypesAction.FAILURE:
      return listReducer(state, type, payload, 'voiceTypes');

    case getCitiesAction.TRIGGER:
    case getCitiesAction.SUCCESS:
    case getCitiesAction.FAILURE:
      return { ...listReducer(state, type, payload, 'cities'), aggregationTypeLoading: get(payload, 'fieldName', '') };

    case resetCitiesAction.TRIGGER:
      return { ...state, cities: { ...state.cities, data: [] } };

    case getVenuesSearchAction.TRIGGER:
      return { ...state, venuesSearchLoading: true };
    case getVenuesSearchAction.SUCCESS:
      return { ...state, venueSearch: payload, venuesSearchLoading: false };
    case getVenuesSearchAction.FAILURE:
      return { ...state, venueSearch: [], error: payload, venuesSearchLoading: false };
    case resetVenuesSearchAction.TRIGGER:
      return { ...state, venueSearch: [], venuesSearchLoading: false };

    case getVenuesAction.TRIGGER:
    case getVenuesAction.SUCCESS:
    case getVenuesAction.FAILURE:
      return listReducer(state, type, payload, 'venues');
    case resetVenuesAction.TRIGGER:
      return { ...state, venues: { ...state.venues, data: [] } };

    case getStagesAction.TRIGGER:
    case getStagesAction.SUCCESS:
    case getStagesAction.FAILURE:
      return listReducer(state, type, payload, 'stages');

    case useBufferValueAction.TRIGGER:
    case useBufferValueAction.SUCCESS:
    case useBufferValueAction.FAILURE:
      return entityReducer(state, type, payload, 'bufferValue');

    case getWorkTypesAction.TRIGGER:
    case getWorkTypesAction.SUCCESS:
    case getWorkTypesAction.FAILURE:
      return listReducer(state, type, payload, 'workTypes');

    case getMultipleWorkTypesAction.TRIGGER:
    case getMultipleWorkTypesAction.SUCCESS:
    case getMultipleWorkTypesAction.FAILURE:
      return listReducer(state, type, payload, 'multipleWorkTypes');

    case getWorkStylesAction.TRIGGER:
    case getWorkStylesAction.SUCCESS:
    case getWorkStylesAction.FAILURE:
      return listReducer(state, type, payload, 'workStyles');

    case getPrivacyOptionsAction.TRIGGER:
    case getPrivacyOptionsAction.SUCCESS:
    case getPrivacyOptionsAction.FAILURE:
      return listReducer(state, type, payload, 'privacyOptions');

    case getAOSProfessionsAction.TRIGGER:
    case getAOSProfessionsAction.SUCCESS:
    case getAOSProfessionsAction.FAILURE:
      return listReducer(state, type, payload, 'aosProfessions');

    case getLanguagesAction.TRIGGER:
    case getLanguagesAction.SUCCESS:
    case getLanguagesAction.FAILURE:
      return listReducer(state, type, payload, 'languages');

    case getDynamicCountriesAction.TRIGGER:
    case getDynamicCountriesAction.SUCCESS:
    case getDynamicCountriesAction.FAILURE:
      return {
        ...listReducer(state, type, payload, state.dynamicCountries.listName),
        aggregationTypeLoading: payload?.fieldName || '',
      };

    case getMediaAttrTypesAction.FAILURE:
    case getMediaAttrTypesAction.TRIGGER:
    case getMediaAttrTypesAction.SUCCESS:
      return listReducer(state, type, payload, initialState.attributeTypes.listName);

    case getVideoFormatAttrTypesAction.FAILURE:
    case getVideoFormatAttrTypesAction.TRIGGER:
    case getVideoFormatAttrTypesAction.SUCCESS:
      return listReducer(state, type, payload, state.videoFormats.listName);

    case getSearchProductionsAction.FAILURE:
    case getSearchProductionsAction.TRIGGER:
    case getSearchProductionsAction.SUCCESS:
      return listReducer(state, type, payload, 'searchProductions');

    case CLEAN_SEARCH_PRODUCTIONS_LIST:
      return { ...state, searchProductions: defaultListParams };

    case getProfileAccessRequestsAction.TRIGGER:
    case getProfileAccessRequestsAction.SUCCESS:
    case getProfileAccessRequestsAction.FAILURE:
      return { ...state, profileAccessRequests: payload };

    default:
      return state;
  }
};

export default appReducer;
