import {
  MEDIA_ATTRIBUTES_TYPES_IDS,
  MEDIA_TAGS_TYPES_IDS,
  MEDIA_TYPES_IDS,
  PERFORMANCE_TYPES_ID,
  PRODUCTION_ADDED_IDENTIFIERS_IDS,
  TP,
  VIDEO_ATTRIBUTE_SLUGS,
  VALIDATION_STATUS,
} from '../../constants/consts';
import { venueFields } from '../../components/EditComponents/VenuesFieldsGroup/constants';

export const GET_PRODUCTION_DATA = 'app/ProductionUpdateSteps/GET_PRODUCTION_DATA';
export const SET_PRODUCTION = 'app/ProductionUpdateSteps/SET_PRODUCTION';

export const CREATE_PRODUCTION = 'app/ProductionUpdateSteps/CREATE_PRODUCTION';
export const UPDATE_PRODUCTION = 'app/ProductionUpdateSteps/UPDATE_PRODUCTION';

export const GET_COMPANIES = 'app/ProductionUpdateSteps/GET_COMPANIES';
export const GET_ORGANIZATIONS = 'app/ProductionUpdateSteps/GET_ORGANIZATIONS';
export const CLEAN_OPTIONS_VALUES = 'app/ProductionUpdateSteps/CLEAN_OPTIONS_VALUES';

export const SET_PROFESSIONS = 'app/ProductionUpdateSteps/SET_PROFESSIONS';
export const SET_ROLES = 'app/ProductionUpdateSteps/SET_ROLES';
export const GET_PRODUCTION_NAMES = 'app/ProductionUpdateSteps/GET_PRODUCTION_NAMES';
export const GET_VENUES = 'app/ProductionUpdateSteps/GET_VENUES';
export const GET_PRODUCTION_CASTS = 'app/ProductionUpdateSteps/GET_PRODUCTION_CASTS';
export const CREATE_PRODUCTION_CASTS = 'app/ProductionUpdateSteps/CREATE_PRODUCTION_CASTS';
export const UPDATE_PRODUCTION_PERFORMANCES = 'app/ProductionUpdateSteps/UPDATE_PRODUCTION_PERFORMANCES';
export const GET_LIBRETTIST_NAMES = 'app/ProductionUpdateSteps/GET_LIBRETTIST_NAMES';
export const CREATE_COMPOSER = 'app/ProductionUpdateSteps/CREATE_COMPOSER';
export const GET_PRODUCTION_DATES = 'app/ProductionUpdateSteps/GET_PRODUCTION_DATES';
export const GET_PRODUCTION_IMAGES = 'app/ProductionUpdateSteps/GET_PRODUCTION_IMAGES';
export const GET_PRODUCTION_VIDEO = 'app/ProductionUpdateSteps/GET_PRODUCTION_VIDEO';
export const ADD_SEVERAL_MEDIA = 'app/ProductionUpdateSteps/ADD_SEVERAL_MEDIA';
export const CLIENT_REMOVE_MEDIA = 'app/ProductionUpdateSteps/CLIENT_REMOVE_MEDIA';
export const UPDATE_MEDIA = 'app/ProductionUpdateSteps/UPDATE_MEDIA';
export const GET_PERFORMANCE_IMAGES = 'app/ProductionUpdateSteps/GET_PERFORMANCE_IMAGES';
export const GET_PERFORMANCE_VIDEO = 'app/ProductionUpdateSteps/GET_PERFORMANCE_VIDEO';
export const DELETE_MEDIA_ACTION = 'app/ProductionUpdateSteps/DELETE_MEDIA_ACTION';
export const DELETE_PRODUCTION_ENSEMBLE = 'app/ProductionUpdateSteps/DELETE_PRODUCTION_ENSEMBLE';

// artist specific data
export const GET_ARTIST_PRODUCTION_DATES = 'app/ProductionUpdateSteps/GET_ARTIST_PRODUCTION_DATES';
export const GET_ARTISTS_SUGGESTIONS = 'app/ProductionUpdateSteps/GET_ARTISTS_SUGGESTIONS';
export const GET_PRODUCTION_MEDIA_ATTRIBUTE_TYPES = 'app/ProductionUpdateSteps/GET_PRODUCTION_MEDIA_ATTRIBUTE_TYPES';
export const GET_FESTIVALS = 'app/ProductionUpdateSteps/GET_FESTIVALS';
export const GET_PERFORMANCE_CITIES = 'app/ProductionUpdateSteps/GET_PERFORMANCE_CITIES';
export const CLEAR_PERFORMANCE_CITIES = 'app/ProductionUpdateSteps/CLEAR_PERFORMANCE_CITIES';
export const GET_PERFORMANCE_STAGES = 'app/ProductionUpdateSteps/GET_PERFORMANCE_STAGES';
export const CLEAR_PERFORMANCE_STAGES = 'app/ProductionUpdateSteps/CLEAR_PERFORMANCE_STAGES';
export const GET_COMPANY_VENUES = 'app/ProductionUpdateSteps/GET_COMPANY_VENUES';
export const CLEAR_PRODUCTION = 'app/ProductionUpdateSteps/CLEAR_PRODUCTION';
export const GET_WORKS = 'app/ProductionUpdateSteps/GET_WORKS';
export const CLEAR_WORKS = 'app/ProductionUpdateSteps/CLEAR_WORKS';
export const GET_PRODUCTION_SYNOPSIS = 'app/ProductionUpdateSteps/GET_PRODUCTION_SYNOPSIS';
export const DELETE_PRODUCTION_DOCUMENT = 'app/ProductionUpdateSteps/DELETE_PRODUCTION_DOCUMENT';
export const UPLOAD_PRODUCTION_DOCUMENTS = 'app/ProductionUpdateSteps/UPLOAD_PRODUCTION_DOCUMENTS';
export const UPDATE_PRODUCTION_SYNOPSIS_DOCUMENTS = 'app/ProductionUpdateSteps/UPDATE_PRODUCTION_SYNOPSIS_DOCUMENTS';
export const ADD_PRODUCTION_IMAGE = 'app/ProductionUpdateSteps/ADD_PRODUCTION_IMAGE';
export const ADD_PRODUCTION_VIDEO = 'app/ProductionUpdateSteps/ADD_PRODUCTION_VIDEO';
export const ADD_PRODUCTION_POSTER = 'app/ProductionUpdateSteps/ADD_PRODUCTION_POSTER';
export const ADD_PRODUCTION_TRAILER = 'app/ProductionUpdateSteps/ADD_PRODUCTION_TRAILER';
export const GET_COMPOSERS = 'app/ProductionUpdateSteps/GET_COMPOSERS';
export const CLEAR_COMPOSERS = 'app/ProductionUpdateSteps/CLEAR_COMPOSERS';
export const GET_WORK_STAGE_TYPE = 'app/ProductionUpdateSteps/GET_WORK_STAGE_TYPE';
export const CLEAR_WORK_STAGING_TYPES = 'app/App/CLEAR_WORK_STAGING_TYPES';
export const GET_PRODUCTION_LIVESTREAMS = 'app/ProductionUpdateSteps/GET_PRODUCTION_LIVESTREAMS';
export const UPDATE_PRODUCTION_LIVESTREAM = 'app/ProductionUpdateSteps/UPDATE_PRODUCTION_LIVESTREAM';
export const GET_PRODUCTION_VIDEO_ON_DEMAND = 'app/ProductionUpdateSteps/GET_PRODUCTION_VIDEO_ON_DEMAND';
export const GET_PRODUCTION_TRAILER = 'app/ProductionUpdateSteps/GET_PRODUCTION_TRAILER';
export const DELETE_PRODUCTION_TRAILER = 'app/ProductionUpdateSteps/DELETE_PRODUCTION_TRAILER';
export const DELETE_PRODUCTION_POSTER = 'app/ProductionUpdateSteps/DELETE_PRODUCTION_POSTER';
export const GET_PRODUCTION_POSTER = 'app/ProductionUpdateSteps/GET_PRODUCTION_POSTER';
export const UPDATE_VIDEO_ON_DEMAND = 'app/ProductionUpdateSteps/UPDATE_VIDEO_ON_DEMAND';
export const GET_VIDEO_COST_TYPES = 'app/ProductionUpdateSteps/GET_VIDEO_COST_TYPES';
export const CREATE_DIGITAL_EVENT = 'app/ProductionUpdateSteps/CREATE_DIGITAL_EVENT';
export const DELETE_DIGITAL_EVENT = 'app/ProductionUpdateSteps/DELETE_DIGITAL_EVENT';
export const GET_IMAGE_FORMATS = 'app/ProductionUpdateSteps/GET_IMAGE_FORMATS';
export const GET_VIDEO_FORMATS = 'app/ProductionUpdateSteps/GET_VIDEO_FORMATS';
export const GET_PRODUCTION_BOOKLET_DATA = 'app/ProductionUpdateSteps/GET_PRODUCTION_BOOKLET_DATA';
export const CREATE_PRODUCTION_BOOKLET = 'app/ProductionUpdateSteps/CREATE_PRODUCTION_BOOKLET';
export const UPDATE_PRODUCTION_BOOKLET = 'app/ProductionUpdateSteps/UPDATE_PRODUCTION_BOOKLET';
export const PRODUCTION_MEDIA_ACTIONS = 'app/ProductionUpdateSteps/PRODUCTION_MEDIA_ACTIONS';
export const DELETE_PRODUCTION_BOOKLET = 'app/ProductionUpdateSteps/DELETE_PRODUCTION_BOOKLET';

// New production screen

export const GET_REVIEWS = 'app/Productions/GET_REVIEWS';
export const DELETE_REVIEWS = 'app/Productions/DELETE_REVIEWS';
export const GET_REVIEW_DETAILS = 'app/Productions/GET_REVIEW_DETAILS';
// STEP ACTIONS
export const GET_MW_STEP_DATA = 'app/ProductionUpdateSteps/GET_MW_STEP_DATA';
export const GET_LIVE_EVENTS_STEP_DATA = 'app/ProductionUpdateSteps/GET_LIVE_EVENTS_STEP_DATA';
export const GET_DIGITAL_EVENTS_STEP_DATA = 'app/ProductionUpdateSteps/GET_DIGITAL_EVENTS_STEP_DATA';
export const GET_MEDIA_STEP_DATA = 'app/ProductionUpdateSteps/GET_MEDIA_STEP_DATA';
export const CREATE_PRODUCTION_SYNOPSIS_AND_DESCRIPTION =
  'app/ProductionSteps/CREATE_PRODUCTION_SYNOPSIS_AND_DESCRIPTION';
export const GET_ENSEMBLES = 'app/ProductionUpdateSteps/GET_ENSEMBLES';
export const CREATE_PRODUCTION_ENSEMBLES = 'app/ProductionUpdateSteps/CREATE_PRODUCTION_ENSEMBLES';
export const GET_PRODUCTION_ENSEMBLES = 'app/ProductionUpdateSteps/GET_PRODUCTION_ENSEMBLES';
export const GET_PRODUCTION_SYNOPSIS_AND_DESCRIPTION = 'app/ProductionSteps/GET_PRODUCTION_SYNOPSIS_AND_DESCRIPTION';
export const DELETE_CAST = 'app/ProductionUpdateSteps/DELETE_CAST';
export const GET_WORK_CAST_CREW = 'app/ProductionUpdateSteps/GET_WORK_CAST_CREW';
export const GET_PRODUCTION_WORKS = 'app/ProductionUpdateSteps/GET_PRODUCTION_WORKS';
export const SET_PRODUCTION_WORKS = 'app/ProductionUpdateSteps/SET_PRODUCTION_WORKS';
export const CREATE_PERFORMANCES = 'app/ProductionUpdateSteps/CREATE_PERFORMANCES';
export const CREATE_PRODUCTION_DATES_V1 = 'app/ProductionDates/CREATE_PRODUCTION_DATES_V1';
export const UPDATE_PRODUCTION_DATES_V1 = 'app/ProductionDates/UPDATE_PRODUCTION_DATES_V1';
export const GET_PRODUCTION_DATES_V1 = 'app/ProductionUpdateSteps/GET_PRODUCTION_DATES_V1';
export const GET_PRODUCTION_CALENDAR_DATES = 'app/ProductionUpdateSteps/GET_PRODUCTION_CALENDAR_DATES';
export const DELETE_CAST_PROXY = 'app/ProductionUpdateSteps/DELETE_CAST_PROXY';
export const CREATE_PRODUCTION_DATES_PROXY = 'app/ProductionDates/CREATE_PRODUCTION_DATES_PROXY';

export const GET_NEW_PRODUCTION_SYNOPSIS = 'app/ProductionSteps/GET_NEW_PRODUCTION_SYNOPSIS';
export const UPDATE_PRODUCTION_SYNOPSIS = 'app/ProductionSteps/UPDATE_PRODUCTION_SYNOPSIS';
export const DELETE_PRODUCTION_SYNOPSIS = 'app/ProductionSteps/DELETE_PRODUCTION_SYNOPSIS';
export const GET_PRODUCTION_DESCRIPTION = 'app/ProductionSteps/GET_PRODUCTION_DESCRIPTION';
export const GET_CAST_CREW_DATA = 'app/ProductionSteps/GET_CAST_CREW_DATA';
export const CREATE_PRODUCTION_DESCRIPTION = 'app/ProductionSteps/CREATE_PRODUCTION_DESCRIPTION';
export const UPDATE_PRODUCTION_DESCRIPTION = 'app/ProductionSteps/UPDATE_PRODUCTION_DESCRIPTION';
export const DELETE_PRODUCTION_DESCRIPTION = 'app/ProductionSteps/DELETE_PRODUCTION_DESCRIPTION';

export const cardFields = {
  ...venueFields,
  time: {
    name: 'time',
    value: [],
    performanceField: 'time',
  },
  isTour: {
    name: PERFORMANCE_TYPES_ID.TOUR,
    value: false,
  },
  isFestivalPart: {
    name: PERFORMANCE_TYPES_ID.FESTIVAL,
    value: false,
  },
  festival: {
    name: 'festival',
    value: null,
  },
};

export const publishedCardFields = {
  cancel: {
    name: PERFORMANCE_TYPES_ID.CANCELLED,
    label: `${TP}.FN_EDITSEASON_MARK_AS_CANCELLED`,
    value: false,
  },
};

export const getFieldName = (parentFieldName, field) => `${parentFieldName}.${field?.name}`;

export const EDITING_PRODUCTION_ENTITY_TYPES = {
  COMPANY: 'company',
  ARTIST: 'artist',
  FESTIVAL: 'festival',
  ORGANIZATION: 'organization',
};

export const DIGITAL_EVENT_TYPES = {
  LIVESTREAM: 'livestream',
  VOD: 'video-on-denmand',
};

export const LIVESTREAM_REQUEST_PARAMS = {
  media_tag_type_id: MEDIA_TAGS_TYPES_IDS.PRODUCTION,
  media_type_id: MEDIA_TYPES_IDS.VIDEO,
  media_attribute_type_id: MEDIA_ATTRIBUTES_TYPES_IDS.VIDEO,
  media_attribute_slug: VIDEO_ATTRIBUTE_SLUGS.LIVESTREAM,
};

export const VOD_REQUEST_PARAMS = {
  media_tag_type_id: MEDIA_TAGS_TYPES_IDS.PRODUCTION,
  media_type_id: MEDIA_TYPES_IDS.VIDEO,
  media_attribute_type_id: MEDIA_ATTRIBUTES_TYPES_IDS.VIDEO,
  media_attribute_slug: VIDEO_ATTRIBUTE_SLUGS.VIDEO_ON_DEMAND,
};

export const PRODUCTION_AVAILABILITY_TYPE = {
  WORK: 'work',
  PRODUCTION: 'production',
};

export const OPERA_PRODUCTION_WORK_TYPES = {
  STAGED: 1,
  SEMI_STAGED: 2,
  OPERA_IN_CONCERT: 3,
  OTHER: 4,
};

export const BALLET_PRODUCTION_WORK_TYPES = {
  BALLET_WITH_ORCHESTRA: 5,
  BALLET_WITHOUT_ORCHESTRA: 6,
  BALLET_WITH_SETS: 7,
  BALLET_WITHOUT_SETS: 8,
  OTHER: 9,
};

export const PRODUCTION_TYPES = [
  {
    id: OPERA_PRODUCTION_WORK_TYPES.STAGED,
    key: `${TP}.FN_STAGED`,
    value: OPERA_PRODUCTION_WORK_TYPES.STAGED.toString(),
    availability: PRODUCTION_AVAILABILITY_TYPE.WORK,
  },
  {
    id: OPERA_PRODUCTION_WORK_TYPES.SEMI_STAGED,
    key: `${TP}.FN_SEMI_STAGED`,
    value: OPERA_PRODUCTION_WORK_TYPES.SEMI_STAGED.toString(),
    availability: PRODUCTION_AVAILABILITY_TYPE.WORK,
  },
  {
    id: OPERA_PRODUCTION_WORK_TYPES.OPERA_IN_CONCERT,
    key: `${TP}.m_PERF_INCONCERT`,
    value: OPERA_PRODUCTION_WORK_TYPES.OPERA_IN_CONCERT.toString(),
    availability: PRODUCTION_AVAILABILITY_TYPE.WORK,
  },
  {
    id: OPERA_PRODUCTION_WORK_TYPES.OTHER,
    key: `${TP}.FN_OTHERS`,
    value: OPERA_PRODUCTION_WORK_TYPES.OTHER.toString(),
    availability: PRODUCTION_AVAILABILITY_TYPE.WORK,
  },
  {
    id: 8,
    key: `${TP}.FN_OPERA`,
    value: '8',
    availability: 'production',
  },
  {
    id: 6,
    key: `${TP}.m_PERF_INCONCERT`,
    value: '6',
    availability: 'production',
  },
  {
    id: 7,
    key: `${TP}.FN_BALLET`,
    value: '7',
    availability: 'production',
  },
  {
    id: 9,
    key: `${TP}.FN_ALL`,
    value: '9',
    availability: 'production',
  },
];

export const PROUCTION_CO_PRODUCER_TYPE = [
  {
    key: `${TP}.FN_COMPANY`,
    value: PRODUCTION_ADDED_IDENTIFIERS_IDS.COMPANY.toString(),
  },
  {
    key: `${TP}.FN_FESTIVAL`,
    value: PRODUCTION_ADDED_IDENTIFIERS_IDS.FESTIVAL.toString(),
  },
];

export const PRODUCTION_DATES_SELECTOR_TYPE = {
  RECORDING_DATE: 'Recording Date',
  PHYSICAL_DATE: `Physical Date`,
};

export const PRODUCTION_DATES_SELECTOR_OPTIONS = [
  {
    key: `${TP}.ADD_RECORDING_DATE`,
    value: PRODUCTION_DATES_SELECTOR_TYPE.RECORDING_DATE,
  },
  {
    key: `${TP}.SELECT_PHYSICAL_DATE`,
    value: PRODUCTION_DATES_SELECTOR_TYPE.PHYSICAL_DATE,
  },
];

export const PRODUCTION_PRODUCER_TYPE = [
  ...PROUCTION_CO_PRODUCER_TYPE,
  {
    key: `${TP}.FN_OTHER`,
    value: 'producerReference',
  },
];

export const REVIEW_ENITITES_TYPES = {
  WORKS: { VALUE: 1, NAME: 'works' },
  COMPOSER: { VALUE: 2, NAME: 'workComposers' },
  PRODUCTIONS: { VALUE: 3, NAME: 'productions' },
  PRODUCTION_DATE: { VALUE: 4, NAME: 'productionDates' },
  PRODUCTION_CAST: { VALUE: 5, NAME: 'productionCasts' },
  PROFILE: { VALUE: 6, NAME: 'profiles' },
  WORK_ROLE: { VALUE: 7, NAME: 'workRoles' },
  VENUE: { VALUE: 8, NAME: 'venues' },
  COMPANIES: { VALUE: 9, NAME: 'companies' },
  FESTIVALS: { VALUE: 10, NAME: 'festivals' },
};

export const PERFORMANCE_DATE_TYPES = {
  PHYSICAL: 'physical',
  LIVESTREAM: 'livestream',
  FULL_VIDEO: 'vod',
};

export const PERFOMANCE_DATE_MODE_TYPES = {
  [PERFORMANCE_DATE_TYPES.PHYSICAL]: 'stage',
  [PERFORMANCE_DATE_TYPES.LIVESTREAM]: 'digital-ls',
  [PERFORMANCE_DATE_TYPES.FULL_VIDEO]: 'digital-vod',
};

export const PERFORMANCE_DATE_MODE = {
  PHYSICAL: 'physical',
  LIVESTREAM: 'digital-ls',
  FULL_VIDEO: 'digital-vod',
};

export const PERFORMANCE_TYPES = {
  WORLD_PREMIERE: 1,
  NATIONAL_PREMIERE: 2,
  CONCERT: 3,
  NEW_PRODUCTION: 4,
  CANCELLED: 5,
  TOUR: 6,
  FESTIVAL: 7,
  MATINEE: 8,
  MULTI_BILL: 9,
  RECITAL: 10,
};

export const PERFORMANCE_TYPES_V1 = {
  WORLD_PREMIERE: { value: 1, label: `${TP}.FN_PERFORMACE_TYPE_WORLD_PREMIERE`, key: 'isWorldPremiere' },
  NATIONAL_PREMIERE: { value: 2, label: `${TP}.m_NATPREMIERE`, key: 'isPremiere' },
  PRACTICE_RUN: { value: 10, label: `${TP}.FN_PRACTICE_RUN`, key: 'isPracticeRun' },
  CONCERT: { value: 3, label: `${TP}.FN_PERFORMACE_TYPE_CONCERT`, key: 'is' },
  NEW_PRODUCTION: { value: 4, label: `${TP}.m_NEWPROD`, key: 'is' },
  CANCELLED: { value: 5, label: `${TP}.PERFORMANCE_CANCELLED`, key: 'is' },
  TOUR: { value: 6, label: `${TP}.FN_PERFORMACE_TYPE_TOUR`, key: 'isTour' },
  FESTIVAL: { value: 7, label: `${TP}.m_FESTIVAL`, key: '' },
  MATINEE: { value: 8, label: `${TP}.FN_PERFORMACE_TYPE_MATINEE`, key: 'is' },
  MULTI_BILL: { value: 9, label: `${TP}.FN.MULTI_BILL`, key: 'is' },
  RECITAL: { value: 1, label: `${TP}.FN_RECITAL`, key: 'is' },
};

export const DEVICE_AVAILABILITIES = {
  ALL: { label: `${TP}.FN_ALL`, key: 'all' },
  ANDROID_TV: { label: `${TP}.FN_ANDROID_TV`, key: 'android-tv' },
  ANDROID: { label: `${TP}.FN_ANDROID`, key: 'android' },
  IOS: { label: `${TP}.FN_IOS`, key: 'ios' },
  APPLE_TV: { label: `${TP}.FN_APPLE_TV`, key: 'apple-tv' },
  SAMSUNG: { label: `${TP}.FN_SAMSUNG`, key: 'samsung' },
  ROKU: { label: `${TP}.FN_ROKU`, key: 'roku' },
  XBOX: { label: `${TP}.FN_XBOX`, key: 'xbox' },
  MAC_OS: { label: `${TP}.FN_MAC_OS`, key: 'mac-os' },
  WINDOWS: { label: `${TP}.FN_WINDOWS`, key: 'windows' },
  LINUX: { label: `${TP}.FN_LINUX`, key: 'linux' },
  AMAZON_FIRE_STICK: { label: `${TP}.FN_AMAZON_FIRE_STICK`, key: 'amazon-fire-stick' },
  CHROMECAST: { label: `${TP}.FN_CROMECAST`, key: 'chromecast' },
  TIZEN: { label: `${TP}.FN_TIZEN`, key: 'tizen' },
};

export const PERFORMANCE_PRICING_TYPE = {
  FREE: { label: `${TP}.FN_FREE`, value: 'free' },
  PAY_PER_VIEW: { label: `${TP}.FN_PAY_PER_VIEW`, value: 'pay-per-view' },
  SUBSCRIPTION: { label: `${TP}.FN_SUBSCRIPTION`, value: 'subscription' },
};

export const STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  PROCESSING: 'processing',
  APPROVED_HIDDEN: 'approved-hidden',
};

export const PENDING_PROCESSING_APPROVED_FILTER = `${STATUS.PENDING},${STATUS.PROCESSING},${STATUS.APPROVED}`;
export const VALID_ENTITY_FILTER = `${VALIDATION_STATUS.PENDING},${VALIDATION_STATUS.APPROVED},${VALIDATION_STATUS.APPROVED_HIDDEN}`;
export const VALID_REVIEW_FILTER = `${STATUS.PENDING},${STATUS.PROCESSING},${STATUS.APPROVED},${STATUS.APPROVED_HIDDEN}`;
