import { defaultProductionPagination } from '../../constants/consts';

export const SET_COMPANY = 'app/Company/SET_COMPANY';
export const SET_SEASONS = 'app/Company/SET_SEASONS';
export const CLEAR_EDIT_SEASONS = 'app/Company/CLEAR_EDIT_SEASONS';
export const UPDATE_RENTAL_INCLUDES_ACTION = 'app/Company/UPDATE_RENTAL_INCLUDES_ACTION';

export const UPDATE_ORG = 'app/Org/UPDATE_ORG';
export const GET_ORG_TRANSLATIONS = 'app/Org/GET_ORG_TRANSLATIONS';
export const UPDATE_ORG_TRANSLATIONS = 'app/Org/UPDATE_ORG_TRANSLATIONS';
export const DELETE_ORG_TRANSLATIONS = 'app/Org/DELETE_ORG_TRANSLATIONS';
export const GET_ORG_DESCRIPTIONS = 'app/Org/GET_ORG_DESCRIPTIONS';
export const CREATE_ORG_DESCRIPTION = 'app/Org/CREATE_ORG_DESCRIPTION';
export const UPDATE_ORG_DESCRIPTION = 'app/Org/UPDATE_ORG_DESCRIPTION';
export const DELETE_ORG_DESCRIPTION = 'app/Org/DELETE_ORG_DESCRIPTION';
export const UPDATE_ORG_INFO = 'app/Org/UPDATE_ORG_INFO';
export const UPDATE_ORG_VENUE = 'app/Org/UPDATE_ORG_VENUE';
export const UPDATE_ORG_VIDEO = 'app/Org/UPDATE_ORG_VIDEO';
export const GET_ORG_VENUES = 'app/Org/GET_ORG_VENUES';
export const GET_ORG_RELATIONS = 'app/Org/GET_ORG_RELATIONS';
export const CREATE_ORG_RELATIONS = 'app/Org/CREATE_ORG_RELATIONS';
export const DELETE_ORG_RELATIONS = 'app/Org/DELETE_ORG_RELATIONS';
export const GET_ORG_CONTACT = 'app/Org/GET_ORG_CONTACT';
export const ADD_AND_UPDATE_CONTACTS = 'app/Org/ADD_AND_UPDATE_CONTACTS';
export const GET_ABOUT_ORG_VIDEO = 'app/Org/GET_ABOUT_ORG_VIDEO';
export const GET_ORG_ADDRESSES = 'app/Org/GET_ORG_ADDRESSES';
export const GET_ORG_SEASONS_VALUES = 'app/Org/GET_ORG_SEASONS_VALUES';
export const SET_ORG_PRODUCTION_RENTAL_ACTION = 'app/Org/SET_ORG_PRODUCTION_RENTAL_ACTION';
export const GET_ORG_DATA = 'app/Org/GET_ORG_DATA';
export const DELETE_ORG_VIDEO = 'app/Org/DELETE_ORG_VIDEO';

export const PRODUCTIONS_TYPES = {
  published: 'published',
  unpublished: 'unpublished',
};

export const defaultOrgProductionsPagination = defaultProductionPagination;

// edit

export const orgEditTabValues = {
  edit: 'edit',
  seasons: 'seasons',
  prod_rent: 'prod_rent',
};

export const REVIVAL_STATUS_TYPE = {
  PENDING: 'pending',
  FINISHED: 'finished',
  FAILED: 'failed',
};

export const REVIVAL_SEARCH_TYPE = {
  SEASON: 'season',
  ALL: 'all',
};

export const ADD_ACTION_TYPE = {
  QUICK_ADD: 'quick-add',
  ADD: 'add',
  REVIVE: 'revive',
  CANCEL: 'cancel',
};

export const editOrgTabValues = {
  seasons: 'seasons',
  musicalWorks: 'musical-works',
  rentals: 'rentals',
  videos: 'videos',
  photos: 'photos',
  reviews: 'reviews',
  companyInfo: 'company-info',
  digitalPresence: 'digital-presence',
  staffManagement: 'staff-management',
  contactInfo: 'contact-info',
  draftsSeasons: 'draftsSeasons',
};
