import { CONTACT_TYPE, CONTACT_TYPES, PRIVACY_ATTRIBUTE_IDS } from 'constants/index';
import { digitalPresenceFormFields } from 'constants/formFields';
import { get, first } from 'lodash';

export const getProfessionName = source => get(source, 'profession.name');

export const getShortProfessionDetails = artist => {
  if (Array.isArray(artist?.professions) && artist?.professions?.length > 0) {
    const primaryProfession = artist.professions.filter(a => a.isPrimary);
    const otherProfessions = artist.professions.filter(a => !a.isPrimary);
    const primaryProfessionToDisplay =
      getProfessionName(first(primaryProfession)) || get(artist, 'profession.name') || get(artist, 'summary');

    if (primaryProfessionToDisplay) {
      if (otherProfessions.length > 0) {
        return `${primaryProfessionToDisplay} (+${otherProfessions.length}) `;
      }

      return primaryProfessionToDisplay;
    }

    if (otherProfessions.length > 0) {
      const otherProfessionsToDisplay = getProfessionName(first(otherProfessions));

      if (otherProfessions.length > 1) {
        return `${otherProfessionsToDisplay} (+${otherProfessions.length - 1}) `;
      }

      return otherProfessionsToDisplay;
    }
  }

  return get(artist, 'profession.name') || get(artist, 'summary');
};

export const getLongProfessionDetails = artist => {
  if (Array.isArray(artist?.professions) && artist?.professions?.length > 0) {
    const primaryProfession = artist?.professions?.filter(a => a.isPrimary) || [];
    const otherProfessions = artist?.professions?.filter(a => !a.isPrimary) || [];
    const professions = [...primaryProfession, ...otherProfessions].map(
      item => item?.profession.name || artist?.summary,
    );
    return professions;
  }

  if (artist?.profession) {
    return [artist?.profession.name || artist?.summary];
  }
  return [];
};

const getSocialLinksPayload = (values, category) =>
  values.reduce((acc, curr) => {
    if (curr?.type?.value && curr?.value) {
      const digitalPresenceFieldsType = digitalPresenceFormFields[curr.type.value];
      const { contactTypeId, id, sns } = digitalPresenceFieldsType;
      acc.push({
        value: curr.value,
        ...(category ? { category } : {}),
        contactType: { id: contactTypeId },
        name: curr?.type?.value,
        sns,
        privacyAttributes: [{ id: PRIVACY_ATTRIBUTE_IDS.PRIVATE }],
      });
      return acc;
    }
    return acc;
  }, []);

export const getProfileContactsPayload = (formValues, category) => {
  const newContacts = getSocialLinksPayload(formValues?.contacts, category);
  const otherContacts = {
    [CONTACT_TYPE.EMAIL]: {
      contactType: { id: CONTACT_TYPES.EMAIL },
    },
    [CONTACT_TYPE.WEBSITE]: {
      contactType: { id: CONTACT_TYPES.WEBSITE },
    },
    [CONTACT_TYPE.PHONE]: {
      contactType: { id: CONTACT_TYPES.PHONE },
    },
  };

  Object.keys(otherContacts).forEach(contact => {
    if (formValues?.[contact]) {
      newContacts.push({
        contactType: otherContacts?.[contact]?.contactType,
        name: contact,
        ...(category ? { category } : {}),
        value: formValues?.[contact],
        privacyAttributes: [{ id: PRIVACY_ATTRIBUTE_IDS.PRIVATE }],
      });
    }
  });

  return newContacts;
};
