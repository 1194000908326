import shuffle from 'lodash/shuffle';

import { SEARCH_ENTITY_TYPE_MAP } from 'constants/index';

export const transformListToSingleData = response => ({
  ...response?.data,
  data: response?.data?.data?.[0],
});

export const transformGetRandomisedOrderedList = response => {
  const total = response?.data?.length || 0;
  const order = shuffle(Array.from({ length: total }, (_i, i) => i + 1));

  return {
    ...response,
    data: response?.data?.map((item, index) => ({ ...item, order: order[index] || 0 })) || [],
  };
};

export const transformSearchResultsToEntity = response => ({
  ...response,
  data: response?.data?.map(result => {
    const { entity, entity_type, ...rest } = result || {};

    return {
      entityType: SEARCH_ENTITY_TYPE_MAP[entity_type],
      entity: {
        name: entity,
        ...rest,
      },
    };
  }),
});
