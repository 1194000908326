import { buildImageUrl, setConfig } from 'cloudinary-build-url';
import { STORAGE_TYPES } from '@cld-apis/utils';
import countBy from 'lodash/countBy';

import { IMAGE_ATTRIBUTE_SLUGS } from 'constants/consts';
import { ENTITY_TYPE } from 'constants/index';

import { getProductionName } from 'utils/common';

const CLOUD_NAME = 'FAKE_CLOUD_NAME';
const DEFAULT_STORAGE_TYPE = STORAGE_TYPES.UPLOAD;

setConfig({
  cloudName: CLOUD_NAME,
  storageType: DEFAULT_STORAGE_TYPE,
  secureDistribution: 'images.operabase.com',
});

export const getCloudinaryURL = ({ src, transformations }) => {
  if (!src) {
    return null;
  }

  const { storageType, ...rest } = transformations || {};

  let fallbackURL = buildImageUrl(src, {
    transformations: {
      flags: 'progressive',
      ...(rest && rest),
      format: 'png',
    },
  })
    .replace(`${CLOUD_NAME}/`, '')
    .replace(/(\/https:\/\/static.truelinked.com\/)/, '/bucket/')
    .replace(/(\/https:\/\/quality-static.truelinked.net\/)/, '/bucketqa/')
    .replace(/(\/https:\/\/data.operabase.com\/)/, '/bucket/')
    .replace(/(\/https:\/\/quality-data.operabase.net\/)/, '/bucketqa/');
  // TODO: Remove older url support later @junaid

  if (storageType) {
    fallbackURL = fallbackURL.replace(`/image/${DEFAULT_STORAGE_TYPE}`, `/image/${storageType}`);
  }

  return {
    fallback: fallbackURL,
    sources: [
      {
        type: 'image/avif',
        path: fallbackURL.replace('f_png', 'f_avif'),
      },
      {
        type: 'image/webp',
        path: fallbackURL.replace('f_png', 'f_webp'),
      },
    ],
  };
};

export const getStaticMediaPath = path =>
  `${process.env.NODE_ENV === 'development' ? '' : `${process.env.STATIC_ASSET_URL}/${process.env.BUILD_ID}`}/${path}`;

export const isPortraitMedia = media => {
  const attributes = media?.attributes || [];
  const height = parseInt(
    attributes.find(attribute => attribute?.attributeType?.slug === IMAGE_ATTRIBUTE_SLUGS.HEIGHT)?.name,
    10,
  );
  const width = parseInt(
    attributes.find(attribute => attribute?.attributeType?.slug === IMAGE_ATTRIBUTE_SLUGS.WIDTH)?.name,
    10,
  );

  if (height > 0 && width > 0) {
    return {
      isPortrait: height > width,
      height,
      width,
      aspectRatio: width / height,
    };
  }

  return {
    isPortrait: false,
    height,
    width,
    aspectRatio: null,
  };
};

export const regroupMedia = media =>
  media.reduce((acc, item, index) => {
    const lastSet = acc?.[acc?.length - 1]?.items;

    if (acc?.length === 0 || lastSet?.length === 4) {
      acc.push({
        direction: 'row',
        items: [item],
      });

      return acc;
    }

    const isPortrait = item?.isPortrait;
    const orientationCounts = countBy(lastSet, 'isPortrait');

    const lCount = orientationCounts.false ?? 0;
    const pCount = orientationCounts.true ?? 0;
    const direction = lCount === 1 && pCount === 1 && !isPortrait ? 'column' : 'row';

    if (lastSet?.length === 1) {
      const lastPop = acc.pop();
      acc.push({
        ...lastPop,
        items: [...lastPop.items, item],
      });

      return acc;
    }

    if (lastSet?.length === 2) {
      if (lastSet[0].isPortrait) {
        if (!isPortrait) {
          const lastPop = acc.pop();
          acc.push({
            direction,
            items: [...lastPop.items, item],
          });

          return acc;
        }

        if (pCount === 2 && media?.[index + 1]?.isPortrait) {
          const lastPop = acc.pop();
          acc.push({
            direction,
            items: [...lastPop.items, item],
          });

          return acc;
        }
      }

      if (!lastSet[0].isPortrait && lCount === 1) {
        const lastPop = acc.pop();
        acc.push({
          direction,
          items: [...lastPop.items, item],
        });

        return acc;
      }
    }

    if (lastSet?.length === 3 && pCount === 3 && isPortrait) {
      const lastPop = acc.pop();
      acc.push({
        ...lastPop,
        items: [...lastPop.items, item],
      });
      return acc;
    }

    acc.push({
      direction: 'row',
      items: [item],
    });

    return acc;
  }, []);

export const getMediaSource = (media, skipLarge = false) => {
  const urls = media?.file?.urls;

  if (!skipLarge && urls?.large) {
    return urls?.large;
  }

  return urls?.medium || urls?.small || urls?.original;
};

export const getGalleryMediaDimensions = isPortrait => {
  if (isPortrait) {
    return {
      orientation: 'P',
      height: 500,
      width: 367,
    };
  }

  return {
    orientation: 'L',
    height: 496,
    width: 740,
  };
};

export const processMediaList = (data = [], mediaContent = {}) =>
  data?.map(media => {
    const isPortrait = media?.isPortrait;
    const dimensions = getGalleryMediaDimensions(isPortrait);

    return {
      media,
      isPortrait,
      ...dimensions,
      src: getMediaSource(media),
      mediaContent,
    };
  });

export const getFillerText = ({ entity, entityType, production }) => {
  const productionName = production && getProductionName(production);

  if (productionName) {
    return productionName;
  }

  let entityName = '';

  switch (entityType) {
    case ENTITY_TYPE.ARTIST:
      entityName = productionName ? entity?.firstName : entity?.name;
      break;
    case ENTITY_TYPE.ORGANIZATION:
      entityName = entity?.name;
      break;
    case ENTITY_TYPE.PRODUCTION:
      entityName = getProductionName(entity);
      break;
    case ENTITY_TYPE.WORK:
      entityName = entity?.name;
      break;
    default:
      entityName = '';
  }

  return entityName;
};

export const getRenderDimensions = ({ width, height, aspectRatio, minHeight, minWidth }) => {
  const calculatedHeight = height || (width && aspectRatio && width / aspectRatio);
  const calculatedWidth = width || (height && aspectRatio && height * aspectRatio);

  if (minWidth && minHeight) {
    if (calculatedWidth >= minWidth && calculatedHeight >= minHeight) {
      return {
        height: calculatedHeight,
        width: calculatedWidth,
      };
    }

    if (calculatedWidth >= minWidth && calculatedHeight < minHeight) {
      return getRenderDimensions({ height: minHeight, aspectRatio, minWidth, minHeight });
    }

    if (calculatedWidth < minWidth && calculatedHeight >= minHeight) {
      return getRenderDimensions({ width: minWidth, aspectRatio, minWidth, minHeight });
    }
  }

  if (minWidth && calculatedWidth < minWidth) {
    return getRenderDimensions({ width: minWidth, aspectRatio, minWidth, minHeight });
  }

  if (minHeight && calculatedHeight < minHeight) {
    return getRenderDimensions({ height: minHeight, aspectRatio, minWidth, minHeight });
  }

  return {
    height: calculatedHeight,
    width: calculatedWidth,
  };
};
