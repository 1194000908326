import mapValues from 'lodash/mapValues';
import invert from 'lodash/invert';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { createDate } from 'utils/date';

import {
  TP,
  DATE_FORMATS,
  ENTITY_MAIN_TABS,
  URL_SLUG_ORDER,
  URL_SLUG_TYPES,
  VIEW_MODES_VALUE,
  SORT_OPTION_TYPES,
  ENTITY_TYPE,
} from 'constants/index';

import {
  TAB_FILTERS,
  ENTITY_TAB_FILTERS,
  FILTER_TYPES,
  FILTER_LABELS,
  FILTER_SLUG_TYPE,
  FILTER_DEFAULT_EXPANDED,
  TAB_H1_FILTER_PARTS,
  VALID_VIEW_MODE_OPTIONS,
  BOOLEAN_OPERATOR_REGEX,
  BOOLEAN_OPERATORS_VALUES,
  BOOLEAN_SEARCH_PARTS,
  ADVANCED_FILTER_TYPES,
  ENTITY_LISTING_FILTERS,
} from 'constants/filters';
import { getEntitySlug } from 'utils/globals';

export const getCustomDateFilter = ({ dateFrom, dateTo }) => {
  const today = createDate();
  const dateFromMoment = createDate(dateFrom);
  const dateToMoment = createDate(dateTo);

  if (dateFrom === dateTo) {
    const formattedToday = today.format(DATE_FORMATS.FULL_DATE);
    if (dateFrom === formattedToday) {
      return {
        id: formattedToday,
        label: `${TP}.m_TODAY`,
      };
    }

    const formattedTomorrow = today.add(1, 'day').format(DATE_FORMATS.FULL_DATE);
    if (dateFrom === formattedTomorrow) {
      return {
        id: formattedTomorrow,
        label: `${TP}.m_TOMORROW`,
      };
    }
  }

  if (dateFromMoment.format(DATE_FORMATS.YEAR) === dateToMoment.format(DATE_FORMATS.YEAR)) {
    if (dateFromMoment.format(DATE_FORMATS.SHORT_MONTH) === dateToMoment.format(DATE_FORMATS.SHORT_MONTH)) {
      const startOfMonth = dateFromMoment.startOf('month').format(DATE_FORMATS.FULL_DATE);
      const endOfMonth = dateFromMoment.endOf('month').format(DATE_FORMATS.FULL_DATE);

      if (startOfMonth === dateFrom && endOfMonth === dateTo) {
        return {
          id: `${dateFrom}-${dateTo}`,
          label: dateFromMoment.format(DATE_FORMATS.MONTH_YEAR),
        };
      }

      return {
        id: `${dateFrom}-${dateTo}`,
        label: `${dateFromMoment.format(DATE_FORMATS.DATE)} - ${dateToMoment.format(DATE_FORMATS.DATE_LABEL)}`,
      };
    }

    const startOfYear = dateFromMoment.startOf('year').format(DATE_FORMATS.FULL_DATE);
    const endOfYear = dateFromMoment.endOf('year').format(DATE_FORMATS.FULL_DATE);

    if (startOfYear === dateFrom && endOfYear === dateTo) {
      return {
        id: `${dateFrom}-${dateTo}`,
        label: dateFromMoment.format(DATE_FORMATS.YEAR),
      };
    }
  }

  return {
    id: `${dateFrom}-${dateTo}`,
    label: `${dateFromMoment.format(DATE_FORMATS.DATE_LABEL)} - ${dateToMoment.format(DATE_FORMATS.DATE_LABEL)}`,
  };
};

export const getContextualFilters = ({ entityId, entityType, tabKey = ENTITY_MAIN_TABS.HOME }) => {
  if (!entityType) {
    return [];
  }

  if (!entityId) {
    return ENTITY_LISTING_FILTERS?.[entityType] || [];
  }

  const validFilters = ENTITY_TAB_FILTERS?.[entityType] || TAB_FILTERS;

  return validFilters[tabKey] || [];
};

export const getFilterDetails = type => {
  if (!FILTER_TYPES[type]) {
    return null;
  }

  const slugType = FILTER_SLUG_TYPE[type];

  return {
    label: FILTER_LABELS[type],
    slugType,
    order: URL_SLUG_ORDER.findIndex(urlSlugType => urlSlugType === slugType),
    queryParam: FILTER_SLUG_TYPE[type],
    isDefaultExpanded: FILTER_DEFAULT_EXPANDED.includes(type),
  };
};

const canURLPathInclude = ({ order, values, slugType, entityType, entityId }) => {
  const hasOrder = order >= 0;
  const isArrayOfOneItem = Array.isArray(values) && values?.length === 1;
  const hasId = !!values?.[0]?.id;
  const isArtistIndexPage = entityType === ENTITY_TYPE.ARTIST && !entityId;
  const isOrganizationIndexPage = entityType === ENTITY_TYPE.ORGANIZATION && !entityId;

  if (URL_SLUG_TYPES.PROFESSION === slugType) {
    if (isArtistIndexPage) {
      return hasOrder && isArrayOfOneItem;
    }

    return false;
  }

  if (URL_SLUG_TYPES.ORGANIZATION_TYPE === slugType) {
    if (isOrganizationIndexPage) {
      return hasOrder && isArrayOfOneItem;
    }

    return false;
  }

  return hasOrder && isArrayOfOneItem && hasId;
};

export const getFilterURLParams = ({
  entityType,
  entityId,
  filters,
  path = '',
  queryParams: initialQueryParams = {},
}) => {
  const queryTypeMap = invert(FILTER_SLUG_TYPE);
  let pathParams = {};
  let queryParams = initialQueryParams;

  mapValues(filters, (values, key) => {
    const filterType = queryTypeMap[key];
    const filterDetails = getFilterDetails(filterType);

    if (!filterDetails) {
      return;
    }

    const { order } = filterDetails;

    if (filterType === FILTER_TYPES.DATE) {
      const dateTo = filters?.[key]?.[`${key}_to`];
      const dateFrom = filters?.[key]?.[`${key}_from`];

      const startOfMonth = createDate(dateFrom).startOf('month');
      const endOfMonth = createDate(dateTo).endOf('month');

      if (dateTo === dateFrom || (!dateTo && dateFrom) || (dateTo && !dateFrom)) {
        pathParams = {
          ...pathParams,
          [order]: createDate(dateFrom || dateTo)
            .locale('en')
            .format(DATE_FORMATS.URL_FULL_DATE)
            .toLowerCase(),
        };
      } else if (
        startOfMonth.format(DATE_FORMATS.FULL_DATE) === dateFrom &&
        endOfMonth.format(DATE_FORMATS.FULL_DATE) === dateTo
      ) {
        if (startOfMonth.format(DATE_FORMATS.SHORT_MONTH) === endOfMonth.format(DATE_FORMATS.SHORT_MONTH)) {
          pathParams = {
            ...pathParams,
            [order]: startOfMonth.format(DATE_FORMATS.URL_MONTH_YEAR).toLowerCase(),
          };
        } else if (startOfMonth.format(DATE_FORMATS.YEAR) === endOfMonth.format(DATE_FORMATS.YEAR)) {
          pathParams = {
            ...pathParams,
            [order]: `${startOfMonth.format(DATE_FORMATS.YEAR)}`,
          };
        } else {
          queryParams = {
            ...queryParams,
            ...values,
          };
        }
      } else {
        queryParams = {
          ...queryParams,
          ...values,
        };
      }
    } else if (filterType === FILTER_TYPES.VIEW_MODE) {
      if (values === VIEW_MODES_VALUE.CALENDAR && path?.length > 0) {
        pathParams = {
          ...pathParams,
          [order]: values,
        };
      } else {
        queryParams = {
          ...queryParams,
          [key]: values,
        };
      }
    } else if (canURLPathInclude({ order, values, slugType: filterDetails.slugType, entityType, entityId })) {
      pathParams = {
        ...pathParams,
        [order]: getEntitySlug({
          entity: values?.[0],
          slugType: filterDetails.slugType,
        }),
      };
    } else if (values !== null && values !== undefined && values !== '') {
      queryParams = {
        ...queryParams,
        [key]: Array.isArray(values) ? values?.map(value => value?.id || value) : values,
      };
    }
  });

  const pathParamsOrder = Object.keys(pathParams).sort();
  let newPath = path;

  if (pathParamsOrder?.length > 0) {
    const paths = pathParamsOrder
      .sort((order1, order2) => order1 - order2)
      .map(key => pathParams[key])
      .join('/');

    if (newPath?.length > 0) {
      newPath += '/';
    }

    newPath += `${paths}`;
  }

  return {
    path: newPath?.toLowerCase(),
    queryParams,
  };
};

export const getTitleFilterParts = ({ filters, tab }) => {
  if (isEmpty(filters)) {
    return '';
  }

  const queryTypeMap = invert(FILTER_SLUG_TYPE);
  let filterPrefixes = {};

  mapValues(filters, (values, key) => {
    const filterType = queryTypeMap[key];

    if (!TAB_H1_FILTER_PARTS?.[tab?.key]?.includes(filterType)) {
      return;
    }

    const filterDetails = getFilterDetails(filterType);

    if (!filterDetails) {
      return;
    }

    const { order } = filterDetails;

    if (filterType === FILTER_TYPES.DATE) {
      const dateTo = filters?.[key]?.[`${key}_to`];
      const dateFrom = filters?.[key]?.[`${key}_from`];

      const startOfMonth = createDate(dateFrom).startOf('month');
      const endOfMonth = createDate(dateTo).endOf('month');

      let dateLabel = '';

      if (
        startOfMonth.format(DATE_FORMATS.FULL_DATE) === dateFrom &&
        endOfMonth.format(DATE_FORMATS.FULL_DATE) === dateTo
      ) {
        dateLabel = `${startOfMonth.format(DATE_FORMATS.YEAR)} ${startOfMonth.format('MMMM')}`;
      } else if (dateTo === dateFrom) {
        dateLabel = `${createDate(dateFrom).format(DATE_FORMATS.DATE_LABEL)}`;
      } else {
        dateLabel = `${createDate(dateFrom).format(DATE_FORMATS.DATE_LABEL)} - ${createDate(dateTo).format(
          DATE_FORMATS.DATE_LABEL,
        )}`;
      }

      if (dateLabel) {
        filterPrefixes = {
          ...filterPrefixes,
          [order]: dateLabel,
        };
      }
    } else if (order >= 0 && Array.isArray(values) && values?.length === 1 && values?.[0]?.label) {
      filterPrefixes = {
        ...filterPrefixes,
        [order]: values?.[0]?.label,
      };
    }
  });

  const prefixOrder = Object.keys(filterPrefixes).sort();

  return prefixOrder
    .sort((order1, order2) => order1 - order2)
    .map(key => filterPrefixes?.[key])
    .join(', ');
};

export const getFiltersFromRouteContext = ({ validFilters, context, queryParams }) => {
  const filters = validFilters?.reduce((data, type) => {
    let appliedFilters = { ...data };
    const slugType = FILTER_SLUG_TYPE[type];

    if (type === FILTER_TYPES.DATE) {
      const dateFrom = queryParams?.[`${slugType}_from`];
      const dateTo = queryParams?.[`${slugType}_to`];

      const yearContext = context?.[URL_SLUG_TYPES.YEAR]?.[0]?.id;
      const monthYearContext = context?.[URL_SLUG_TYPES.MONTH_YEAR]?.[0]?.id;
      const dateContext = context?.[URL_SLUG_TYPES.DATE]?.[0]?.id;

      if (dateFrom && dateTo) {
        appliedFilters = {
          ...appliedFilters,
          [slugType]: { [`${slugType}_from`]: dateFrom, [`${slugType}_to`]: dateTo },
        };
      } else if (dateContext) {
        const selectedDate = createDate(dateContext);

        appliedFilters = {
          ...appliedFilters,
          [slugType]: {
            [`${slugType}_from`]: selectedDate.format(DATE_FORMATS.FULL_DATE),
            [`${slugType}_to`]: selectedDate.format(DATE_FORMATS.FULL_DATE),
          },
        };
      } else if (monthYearContext) {
        const selectedMonthYear = createDate(monthYearContext);

        appliedFilters = {
          ...appliedFilters,
          [slugType]: {
            [`${slugType}_from`]: selectedMonthYear.startOf('month').format(DATE_FORMATS.FULL_DATE),
            [`${slugType}_to`]: selectedMonthYear.endOf('month').format(DATE_FORMATS.FULL_DATE),
          },
        };
      } else if (yearContext) {
        const selectedYear = createDate(`${yearContext}`, DATE_FORMATS.YEAR);

        appliedFilters = {
          ...appliedFilters,
          [slugType]: {
            [`${slugType}_from`]: selectedYear.startOf('year').format(DATE_FORMATS.FULL_DATE),
            [`${slugType}_to`]: selectedYear.endOf('year').format(DATE_FORMATS.FULL_DATE),
          },
        };
      }
    } else if (FILTER_TYPES.PRODUCTION_PERIOD === type) {
      const contextFilters = context?.[slugType];

      if (contextFilters?.length > 0) {
        appliedFilters = {
          ...appliedFilters,
          [slugType]: contextFilters,
        };
      }
    } else if (FILTER_TYPES.VIEW_MODE === type) {
      const contextFilters = context?.[slugType];
      const viewMode = queryParams?.[slugType] || contextFilters?.[0]?.slug;

      if (VALID_VIEW_MODE_OPTIONS.includes(viewMode)) {
        appliedFilters = {
          ...appliedFilters,
          [slugType]: viewMode,
        };
      }
    } else if (FILTER_TYPES.BOOLEAN_SEARCH === type) {
      const booleanSearchQuery = queryParams?.[slugType];

      if (booleanSearchQuery?.length > 0) {
        appliedFilters = {
          ...appliedFilters,
          [slugType]: booleanSearchQuery,
        };
      }
    } else if (FILTER_TYPES.PAGE === type) {
      const page = Number.parseInt(queryParams?.[slugType], 10);

      if (page) {
        appliedFilters = {
          ...appliedFilters,
          [slugType]: page,
        };
      }
    } else if ([FILTER_TYPES.LETTER, FILTER_TYPES.QUERY].includes(type)) {
      const value = queryParams?.[slugType];

      if (value) {
        appliedFilters = {
          ...appliedFilters,
          [slugType]: value,
        };
      }
    } else {
      const value = context?.[slugType];
      let contextFilters = value;

      if (Array.isArray(value)) {
        contextFilters = value?.map(filter => {
          const id = filter?.id;

          return { id, slug: filter?.slug || id };
        });
      }

      if (contextFilters) {
        appliedFilters = {
          ...appliedFilters,
          [slugType]: contextFilters,
        };
      }
    }

    return appliedFilters;
  }, {});

  return filters;
};

export const transformFiltersToParams = filters => {
  if (!filters) {
    return {};
  }

  const transformedFilters = mapValues(filters, values => {
    if (Array.isArray(values)) {
      return values.map(({ id }) => id);
    }

    return values;
  });

  const { chorus_id, orchestra_id, company_id, festival_id, ...rest } = transformedFilters || {};
  const organization_id = [
    ...(chorus_id || []),
    ...(orchestra_id || []),
    ...(company_id || []),
    ...(festival_id || []),
  ];

  return {
    ...omitBy(omit(rest, [URL_SLUG_TYPES.DATE]), isNil),
    ...(rest?.[URL_SLUG_TYPES.DATE] || {}),
    ...(rest?.[URL_SLUG_TYPES.CREATOR]?.length > 0 && { work_creator_profession: 'composer' }),
    ...(organization_id?.length > 0 && { organization_id }),
  };
};

export const splitBooleanSearchQuery = (query = '') =>
  query
    .replace(BOOLEAN_OPERATOR_REGEX, '*$1*')
    .split('*')
    .reduce((acc, part) => {
      if (part?.length > 0) {
        acc.push({
          type: BOOLEAN_OPERATORS_VALUES.includes(part) ? BOOLEAN_SEARCH_PARTS.OPERATOR : BOOLEAN_SEARCH_PARTS.QUERY,
          value: part,
        });
      }

      return acc;
    }, []);

export const transformUpcomingDateFilters = filters => {
  const { date_from: dateFrom, date_to: dateTo, ...restFilters } = filters || {};
  const today = createDate().startOf('day');
  let dateFilters = {
    from: today,
  };

  if (dateFrom) {
    const selectedDate = createDate(dateFrom).startOf('day');

    if (selectedDate.isAfter(today)) {
      dateFilters = {
        ...dateFilters,
        from: selectedDate,
      };
    }
  }

  if (dateTo) {
    const selectedDate = createDate(dateTo).startOf('day');

    if (selectedDate.isBefore(dateFilters.from) || selectedDate.isSame(dateFilters.from)) {
      dateFilters = {
        ...dateFilters,
        to: selectedDate,
      };
    }
  }

  return {
    ...restFilters,
    date_from: dateFilters?.from?.format(DATE_FORMATS.FULL_DATE),
    ...(dateFilters?.to && { date_to: dateFilters?.to?.format(DATE_FORMATS.FULL_DATE) }),
  };
};

export const transformPastDateFilters = filters => {
  const { date_from: dateFrom, date_to: dateTo, ...restFilters } = filters || {};
  const yesterday = createDate()
    .subtract(1, 'day')
    .endOf('day');
  let dateFilters = {
    to: yesterday,
  };

  if (dateTo) {
    const selectedDate = createDate(dateTo).endOf('day');

    if (selectedDate.isBefore(yesterday)) {
      dateFilters = {
        ...dateFilters,
        to: selectedDate,
      };
    }
  }

  if (dateFrom) {
    const selectedDate = createDate(dateFrom).endOf('day');

    if (selectedDate.isBefore(dateFilters.to) || selectedDate.isSame(dateFilters.to)) {
      dateFilters = {
        ...dateFilters,
        from: selectedDate,
      };
    }
  }

  return {
    ...restFilters,
    date_from: dateFilters?.from?.format(DATE_FORMATS.FULL_DATE),
    ...(dateFilters?.to && {
      date_to: dateFilters?.to?.format(DATE_FORMATS.FULL_DATE),
      date_max: dateFilters?.to?.format(DATE_FORMATS.FULL_DATE),
    }),
  };
};

export const transformUpcomingPerformanceFilters = filters => ({
  ...transformUpcomingDateFilters(filters),
  sort: SORT_OPTION_TYPES.YEAR_ASC.value,
});

export const getH1FilterString = ({ filters, tabKey, separator = '/' }) => {
  const filterTypes = TAB_H1_FILTER_PARTS?.[tabKey] || [];
  if (isEmpty(filters) || !filterTypes?.length) {
    return '';
  }

  const appliedFilters = filterTypes?.reduce((acc, type) => {
    const typeFilters = filters.find(filter => filter?.type === FILTER_SLUG_TYPE[type]);
    const items = typeFilters?.items || [];

    const { order } = getFilterDetails(type);
    if (order >= 0 && items?.length === 1) {
      acc[order] = items?.[0]?.name || items?.[0]?.id;
    }

    return acc;
  }, {});

  const prefixOrder = Object.keys(appliedFilters).sort();

  return prefixOrder
    .sort((order1, order2) => order1 - order2)
    .map(key => appliedFilters?.[key])
    .join(` ${separator} `);
};

export const getFilterUsage = ({ entityType, filters, isLoggedIn }) => {
  const queryTypeMap = invert(FILTER_SLUG_TYPE);

  const usage = Object.keys(filters).reduce(
    (acc, key) => {
      const filterType = queryTypeMap[key];
      const isAdvancedFilter = ADVANCED_FILTER_TYPES[entityType]?.includes(filterType);

      if (isAdvancedFilter) {
        acc.advancedFilterTypes += 1;
        acc.usedAdvancedFilters.push(key);
      } else if (![FILTER_TYPES.SEASON].includes(filterType)) {
        acc.freeFilterTypes += 1;
        acc.usedFreeFilters.push(key);
      }

      return acc;
    },
    {
      freeFilterTypes: 0,
      advancedFilterTypes: 0,
      usedFreeFilters: [],
      usedAdvancedFilters: [],
    },
  );

  let limitExceeded = false;

  if (isLoggedIn) {
    limitExceeded = usage.advancedFilterTypes > 1;
  } else {
    limitExceeded = usage.freeFilterTypes > 1;
  }

  const focusFilterTypeKey = usage?.usedAdvancedFilters?.[0] || usage?.usedFreeFilters?.[0] || null;

  return {
    ...usage,
    focusFilterType: focusFilterTypeKey ? queryTypeMap[focusFilterTypeKey] : null,
    limitExceeded,
  };
};
