import { all, put, takeLatest, call } from 'redux-saga/effects';
import get from 'lodash/get';
import { getCountriesAction, getUserConstants } from './actions';
import * as API from '../utils/API';

import appSaga from '../containers/App/saga';
import articleSaga from '../containers/Article/saga';
import articlesSaga from '../containers/Articles/saga';
import artistSaga from '../containers/Artist/saga';
import composerSaga from '../containers/Composer/saga';
import artistsSaga from '../containers/Artists/saga';
import organizationSaga from '../containers/Organizations/saga';
import homeSaga from '../containers/Home/saga';
import managersSaga from '../containers/Managers/saga';
import onthisdaySaga from '../containers/Onthisday/saga';
import productionUpdateSaga from '../containers/ProductionUpdateSteps/saga';
import rentalsSaga from '../containers/Rentals/saga';
import statisticsSaga from '../containers/Statistics/saga';
import workSaga from '../containers/Work/saga';
import venueModalSaga from '../containers/VenueModal/saga';
import editImagesSaga from '../containers/EditImages/saga';
import accountSettingsSaga from '../containers/UserContainers/AccountSettingsContainer/saga';

export function* fetchCountries({ payload, serverCookies }) {
  try {
    const response = yield call(API.getCountries, payload, {}, serverCookies);
    yield put(getCountriesAction.success(get(response, 'data.data', [])));
  } catch (err) {
    yield put(getCountriesAction.failure(err.message));
  } finally {
    yield put(getCountriesAction.fulfill());
  }
}

export function* fetchConstants({ serverCookies }) {
  try {
    const response = yield call(API.getConstants, serverCookies);
    yield put(getUserConstants.success(get(response, 'data.data')));
  } catch (err) {
    yield put(getUserConstants.failure(err.message));
  } finally {
    yield put(getUserConstants.fulfill());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(getCountriesAction.TRIGGER, fetchCountries),
    takeLatest(getUserConstants.TRIGGER, fetchConstants),
    appSaga(),
    articleSaga(),
    articlesSaga(),
    artistSaga(),
    composerSaga(),
    artistsSaga(),
    organizationSaga(),
    homeSaga(),
    managersSaga(),
    onthisdaySaga(),
    productionUpdateSaga(),
    rentalsSaga(),
    accountSettingsSaga(),
    statisticsSaga(),
    workSaga(),
    venueModalSaga(),
    editImagesSaga(),
  ]);
}

// TODO REG Clear out registration saga
