import { takeLatest, put, call, takeEvery, cancelled } from 'redux-saga/effects';
import get from 'lodash/get';

import { fetchAbortController } from 'utils/globals/app';
import { getArticles as articlesList, getArticlesAggregations as articlesAggregationsList } from '../../utils/API';
import { getArticles, getArticlesAggregations } from './actions';

export function* getArticlesRequest({ payload, serverCookies }) {
  try {
    const data = yield call(articlesList, payload, serverCookies);
    yield put(getArticles.success(data));
  } catch (err) {
    yield put(getArticles.failure(err.message));
  } finally {
    yield put(getArticles.fulfill());
  }
}

export function* getArticlesAggregationsRequest({ payload, serverCookies }) {
  const abortController = fetchAbortController();
  const signal = abortController ? abortController.signal : null;
  try {
    payload.abortSignal = signal;
    const response = yield call(articlesAggregationsList, payload, serverCookies);
    yield put(getArticlesAggregations.success({ ...get(response, 'data', []), aggregationType: payload.type }));
  } catch (err) {
    if (yield cancelled() && abortController) {
      abortController.abort();
    }
    yield put(getArticlesAggregations.failure({ type: payload.type, message: err.message }));
  } finally {
    if (yield cancelled() && abortController) {
      abortController.abort();
    }
    yield put(getArticlesAggregations.fulfill());
  }
}

export default function* dataPerformances() {
  yield takeLatest(getArticles.TRIGGER, getArticlesRequest);
  yield takeEvery(getArticlesAggregations.TRIGGER, getArticlesAggregationsRequest);
}
