/* eslint-disable import/no-cycle */
import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Tooltip from 'components/uiLibrary/Tooltip';
import usePageContext from 'utils/hooks/usePageContext';
import { ENTITY_TYPE } from 'constants/index';
import classes from './TrackingTester.module.scss';

const TrackingContext = createContext({
  showTrackedClicks: false,
});
export const useTrackingTester = () => useContext(TrackingContext);

export const useTrackingClickStyles = (trackingData, skipTracking) => {
  const { showTrackedClicks } = useTrackingTester();
  const hasMissingTracking = showTrackedClicks && !skipTracking && !trackingData?.section;
  const hasTracking = showTrackedClicks && trackingData?.section;
  // Skipped Tracking: (showTrackedClicks && skipTracking)

  if (hasMissingTracking) {
    return 'show_tracked__click-error'; // Classnames is in styles/styles.scss
  }
  if (hasTracking) {
    return 'show_tracked__click-success';
  }
  return '';
};

const entityTypes = [
  ENTITY_TYPE.ARTIST,
  ENTITY_TYPE.ORGANIZATION,
  ENTITY_TYPE.PRODUCTION,
  ENTITY_TYPE.WORK,
  ENTITY_TYPE.PERFORMANCE,
  ENTITY_TYPE.MANAGER,
];

const TrackingTester = ({ children }) => {
  const { permissions } = usePageContext();
  const [showEntities, setShowEntities] = useState(false);
  const [activeEntities, setActiveEntities] = useState(
    entityTypes.reduce((acc, type) => ({ ...acc, [type]: false }), {}),
  );
  const [showTrackedClicks, setShowTrackedClicks] = useState(false);

  useEffect(() => {
    entityTypes.forEach(type => {
      const className = `show_tracked__${type}`;
      if (activeEntities[type]) {
        document.body.classList.add(className);
      } else {
        document.body.classList.remove(className);
      }
    });
  }, [activeEntities]);

  const toggleEntity = type => {
    setActiveEntities(prev => ({ ...prev, [type]: !prev[type] }));
  };

  const toggleTracking = () => setShowTrackedClicks(!showTrackedClicks);

  const contextValue = useMemo(() => ({ showTrackedClicks }), [showTrackedClicks]);

  if (!permissions?.isAdmin) {
    return children;
  }

  return (
    <TrackingContext.Provider value={contextValue}>
      <div className={classes.trackingTester}>
        <Tooltip title="Test Impressions" placement="left">
          <div>
            <SpriteIcon icon="radar_icon" size={32} onClick={() => setShowEntities(!showEntities)} />
          </div>
        </Tooltip>

        <div
          className={classnames(classes.entityList, {
            [classes.toggleEntities]: !showEntities,
          })}
        >
          {entityTypes.map(type => (
            <div key={type} className={`ob-${type}`}>
              <label>
                <input type="checkbox" checked={activeEntities[type]} onChange={() => toggleEntity(type)} /> {type}
              </label>
            </div>
          ))}
          <div>
            <label>
              <input type="checkbox" checked={showTrackedClicks} onChange={toggleTracking} /> Check Tracked Clicks
            </label>
          </div>
        </div>
      </div>
      {children}
    </TrackingContext.Provider>
  );
};

export default TrackingTester;
