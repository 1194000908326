import { createSelector } from 'reselect';
import get from 'lodash/get';
import { createSelectFieldOptions, getCityWithAreasLabel } from 'utils/globals/app';
import { initialState } from './reducer';

const selectAppDomain = state => state.App || initialState;

const selectUserData = () => createSelector(selectAppDomain, subState => get(subState, 'userData'));

const selectUserProfiles = () => createSelector(selectAppDomain, subState => get(subState, 'profiles'));

const selectProSubscription = profileTypeId =>
  createSelector(selectAppDomain, subState => {
    const subscriptions = get(subState, 'subscriptions.data');
    return subscriptions.find(
      subscription => subscription.profileType?.id === Number(profileTypeId) && subscription.name !== 'free',
    );
  });

const selectAggregationsOptions = (key, valueName = 'key', labelName = 'name', additionalProps = true) =>
  createSelector(selectAppDomain, subState => {
    const aggregationsList = get(subState, `aggregations.${key}`, []);
    return createSelectFieldOptions(aggregationsList, valueName, labelName, { type: key }, additionalProps);
  });

const selectAggregationsLoading = () =>
  createSelector(selectAppDomain, subState => get(subState, 'aggregationTypeLoading'));

const selectStartAggregation = () => createSelector(selectAppDomain, subState => get(subState, 'startAggregations'));

const selectResetPasswordMessage = () =>
  createSelector(selectAppDomain, subState => get(subState, 'resetPasswordInfo'));

const selectPasswordKeyStatus = () =>
  createSelector(selectAppDomain, subState => get(subState, 'isResetPasswordKeyActive'));

const selectPasswordUpdateStatus = () =>
  createSelector(selectAppDomain, subState => get(subState, 'isPasswordUpdated'));

const selectNotifications = () => createSelector(selectAppDomain, subState => get(subState, 'notifications', []));

const selectVoiceTypesOptions = () =>
  createSelector(selectAppDomain, subState =>
    createSelectFieldOptions(get(subState, 'voiceTypes.data', []), 'id', 'name', {}, true),
  );

const selectCitiesOptions = () =>
  createSelector(selectAppDomain, subState => ({
    options: createSelectFieldOptions(get(subState, 'cities.data', []), 'id', getCityWithAreasLabel),
    loading: get(subState, 'cities.loading', false),
  }));

const selectVenuesOptions = () =>
  createSelector(selectAppDomain, subState => ({
    loading: get(subState, 'venues.loading', false),
    options: createSelectFieldOptions(get(subState, 'venues.data', []), 'id', 'name', {}, true),
  }));

const selectStages = () =>
  createSelector(selectAppDomain, subState => ({
    loading: get(subState, 'stages.loading', false),
    data: get(subState, 'stages.data', []),
  }));

const selectBufferValueData = () =>
  createSelector(selectAppDomain, subState => ({
    loading: get(subState, 'bufferValue.loading', false),
    entity: get(subState, 'bufferValue.entity', []),
  }));

const selectWorkTypesLoading = () => createSelector(selectAppDomain, subState => get(subState, 'workTypes.loading'));

const selectWorkTypesOptions = () =>
  createSelector(selectAppDomain, subState =>
    createSelectFieldOptions(get(subState, 'workTypes.data', []), 'id', 'name'),
  );

const selectWorkStylesOptions = () =>
  createSelector(selectAppDomain, subState =>
    createSelectFieldOptions(get(subState, 'workStyles.data', []), 'id', 'name'),
  );

const selectPrivacyOptions = () =>
  createSelector(selectAppDomain, subState =>
    createSelectFieldOptions(get(subState, 'privacyOptions.data', []), 'id', 'name'),
  );

const selectLanguages = () => createSelector(selectAppDomain, subState => get(subState, 'languages.data', false));

const selectLanguagesOptions = () =>
  createSelector(selectLanguages(), langs => createSelectFieldOptions(langs, 'id', 'name', {}, true));

const selectDynamicCountriesOptions = () =>
  createSelector(selectAppDomain, subState =>
    createSelectFieldOptions(get(subState, `${initialState.dynamicCountries.listName}.data`), 'id', 'name'),
  );

const selectMediaAttrTypes = () =>
  createSelector(selectAppDomain, subState => get(subState, 'attributeTypes.data', []));

const selectVideoFormatsNames = () =>
  createSelector(selectAppDomain, subState =>
    get(subState, `${initialState.videoFormats.listName}.data`, [])?.map(format => format?.slug),
  );

const selectSearchProductionsList = () =>
  createSelector(selectAppDomain, subState => get(subState, 'searchProductions'));

export {
  selectAppDomain,
  selectUserData,
  selectUserProfiles,
  selectVoiceTypesOptions,
  selectAggregationsOptions,
  selectResetPasswordMessage,
  selectPasswordKeyStatus,
  selectPasswordUpdateStatus,
  selectAggregationsLoading,
  selectNotifications,
  selectStartAggregation,
  selectCitiesOptions,
  selectVenuesOptions,
  selectStages,
  selectBufferValueData,
  selectWorkTypesOptions,
  selectWorkStylesOptions,
  selectPrivacyOptions,
  selectLanguages,
  selectLanguagesOptions,
  selectDynamicCountriesOptions,
  selectMediaAttrTypes,
  selectVideoFormatsNames,
  selectProSubscription,
  selectSearchProductionsList,
  selectWorkTypesLoading,
};
