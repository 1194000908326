const AUTH = 'auth';
const AUTH_TOKEN = `${AUTH}/token`;
const AUTH_REFRESH_TOKEN = `${AUTH}/refreshtoken`;
const SOCIAL_LOGIN_URLS = `${AUTH}/oauth`;
const COMPANIES = 'companies';
const AGENCIES = 'agencies';
const ADMIN_REQUESTS = 'admin_access_requests';
const CONVERSATIONS = 'conversations';
const YAP = 'yap';
const CONVERSATION_CREATE_MESSAGE = id => `${CONVERSATIONS}/${id}/messages`;

const COUNTRIES_ENDPOINT = 'countries';
const COUNTRY_CITY_ENDPOINT = countryId => `${COUNTRIES_ENDPOINT}/${countryId}/cities`;
const CITY_ENDPOINT = `${COUNTRIES_ENDPOINT}/cities`;
const CURRENCIES = 'currencies';
const FILTER_AGGREGATION_SEARCH = endpoint => `${endpoint}/aggregations`;
const FORGOT_PASSWORD = `${AUTH}/forgotPassword`;
const LANGUAGES_LIST = 'languages';
const PERFORMANCES = 'performances';
const USER_PERFORMANCES = 'users/performances';
const PRODUCTIONS = 'productions';
const CHANGE_REQUEST = 'change_requests';
const PRODUCTIONS_REVIVALS = 'productions/revivals';
const CASTS_ENDPOINT = `${PRODUCTIONS}/castings`;
const PRODUCTIONS_AGGREGATIONS = 'productions/aggregations';
const COMPANIES_AGGREGATIONS = 'companies/aggregations';
const WORKS_AGGREGATIONS = 'works/aggregations';
const PRODUCTIONS_NEWANDRARE = 'productions/newandrare';
const NEWANDRARE_AGGREGATIONS = 'productions/newandrare/aggregations';
const COMPETITIONS = 'competitions';
const COMPETITIONS_AGGREGATIONS = 'competitions/aggregations';
const REPERTOIRE = 'repertoire';
const CAREER_GRAPH = 'careerGraph';
const PROFILES_ENDPOINT = 'profiles';
const PAGERULES_ENDPOINT = 'pagerules';
const PROFILES_BIO_ENDPOINT = profileId => `${PROFILES_ENDPOINT}/${profileId}/descriptions`;
const PROFILES_WITH_SIMILAR_PROFESSION_ENDPOINT = profileId => `${PROFILES_ENDPOINT}/${profileId}/similar`;
const PROFILE_CONTACTS_ENDPOINT = profileId => `${PROFILES_ENDPOINT}/${profileId}/contacts`;
const ARTIST_RECOMMENDATIONS = profileId => `${PROFILES_ENDPOINT}/${profileId}/recommendations`;
const ARTIST_TRANSLATIONS = profileId => `${PROFILES_ENDPOINT}/${profileId}/${TRANSLATIONS}`;
const PROFILE_PREFERRED_ENTITIES = profileId => `${PROFILES_ENDPOINT}/${profileId}/preferredEntities`;
const SUGGESTED_ENTITIES = profileId => `${PROFILES_ENDPOINT}/${profileId}/suggestions`;
const PROFILE_CHECKLIST = profileId => `${PROFILES_ENDPOINT}/${profileId}/checklist`;
const PROFILE_CUSTOMIZATIONS = profileId => `${PROFILES_ENDPOINT}/${profileId}/customizations`;
const INTERMEDIATE_SCREEN_BASE_URL = imgName =>
  `https://public.operabase.com/images/artists/intermediate_screens/${imgName}`;

const SEARCH_ENDPOINT = 'search';
const JOBS_ENDPOINT = 'jobs';
const MUSICAL_WORK_ENDPOINT = 'works';
const MUSICAL_WORK_TYPES_ENDPOINT = `${MUSICAL_WORK_ENDPOINT}/types`;
const WORK_STAGING_TYPES_ENDPOINT = `${MUSICAL_WORK_ENDPOINT}/stagingTypes`;
const MUSICAL_WORK_STYLES_ENDPOINT = `${MUSICAL_WORK_ENDPOINT}/styles`;
const VENUES = 'venues';
const VENUES_STAGES_ENDPOINT = venueId => `${VENUES}/${venueId}/stages`;
const VENUES_CONTACTS = id => `${VENUES}/${id}/${CONTACTS}`;
const CONTACTS = 'contacts';
const ADDRESSES = 'addresses';
const VENUES_AGGREGATIONS = 'venues/aggregations';
const NETWORK_INVITATIONS = 'network/invitations';
const PROJECT_CANDIDATES = id => `${PROJECTS}/${id}/candidates`;
const PROJECTS = 'projects';
const FESTIVALS = 'festivals';
const ORGANIZATIONS = 'organizations';
const ORGANIZATIONS_TYPES = 'organizations/types';
const FESTIVAL_CONTACTS = id => `${FESTIVALS}/${id}/${CONTACTS}`;
const FESTIVAL_EMPLOYEES = festivalId => `${FESTIVALS}/${festivalId}/${EMPLOYEES}`;
const FESTIVAL_CERTAIN_EMPLOYEE = (festivalId, employeeId) =>
  employeeId ? `${FESTIVALS}/${festivalId}/${EMPLOYEES}/${employeeId}` : `${FESTIVALS}/${festivalId}/${EMPLOYEES}`;
const FESTIVAL_DESCRIPTIONS = id => `${FESTIVALS}/${id}/descriptions`;
const FESTIVAL_TRANSLATIONS = festivalId => `${FESTIVALS}/${festivalId}/${TRANSLATIONS}`;
const FESTIVAL_YOUNG_ARTIST_PROGRAM = festivalId => `${FESTIVALS}/${festivalId}/yap`;

const ARTISTS = 'artists';
const VIDEOS = 'proxy/videos';
const ARTICLES = 'articles';
const COMPOSER = 'works/composers';
const COMPOSER_REVIEWS = id => `${COMPOSER}/${id}/reviews`;
const REVIEWS_ENDPOINT = 'reviews';
const WORK_ROLES = 'works/roles';
const AGENTS = 'agents';
const VIDEO_URL = 'videos';
const RENTALS = 'rentals';
const OFFICES = 'offices';
const NETWORK = 'network';
const CONNECTIONS = 'connections';
const INVITATIONS = 'invitations';
const ONTHISDAY = date => `stats/onthisday${date ? `/${date}` : ''}`;
const ACCOUNT_CREATE = 'users';
const ACCOUNT_SETTINGS = userId => `users/${userId}/settings`;
const USER_PAYMENTS = userId => `users/${userId}/payments`;
const SUBSCRIPTIONS = 'subscriptions';
const PRODUCTS = 'products';
const CHECKOUTS = 'checkouts';
const REGISTRATIONS = 'users/registrations';
const REGISTRATION = userId => `users/${userId}/registrations`;
const ADVANCED_STATS = 'stats/advanced';
const ADVANCED_STATS_AGGREGATIONS = 'stats/advanced/aggregations';
const USER_SUBSCRIPTIONS = userId => `users/${userId}/subscriptions`;
const PRODUCTION_CASTS = productionId => `productions/${productionId}/contributions`;
const AGENT_PROFILE_AGENCIES = `${AGENCIES}/${AGENTS}`;
const ARTIST_PROFILE_AGENCIES = `${AGENCIES}/${ARTISTS}`;

const AGENCY = id => `${AGENCIES}/${id}`;
const AGENCY_ARTISTS = id => `${AGENCIES}/${id}/${ARTISTS}`;
const AGENCY_AGENTS = id => `${AGENCIES}/${id}/${AGENTS}`;
const AGENCY_REQUESTS = id => `${AGENCIES}/${id}/access_requests`;
const AGENCY_ACTIONS = id => `${AGENCIES}/${id}/actions`;

const AGENCY_CERTAIN_AGENT = (agencyId, agentId) => `${AGENCIES}/${agencyId}/${AGENTS}/${agentId}`;

const OFFICE = (agencyId, officeId) => `${AGENCIES}/${agencyId}/${OFFICES}${officeId ? `/${officeId}` : ''}`;

const FESTIVAL_VENUES = festivalId => `${FESTIVALS}/${festivalId}/venues`;
const EMPLOYEES = 'employees';
const TRANSLATIONS = 'translations';
const WORK_TYPES = 'workTypes';
const STAGES = 'stages';
const ORGANIZATION_ABOUT = id => `${ORGANIZATIONS}/${id}/${TRANSLATIONS}`;
const COMPANY_YOUNG_ARTIST_PROGRAM = companyId => `${COMPANIES}/${companyId}/yap`;
const COMPANY_FESTIVALS = companyId => `${COMPANIES}/${companyId}/${FESTIVALS}`;
const RENTAL_ACTION = prodId => `${PRODUCTIONS}/${prodId}/${RENTALS}`;
const RENTAL_INCLUDES = (prodId, rentalId) => `${PRODUCTIONS}/${prodId}/${RENTALS}/${rentalId}`;

const MW_WORK_TYPES = workId => `${PRODUCTIONS}/${MUSICAL_WORK_TYPES_ENDPOINT}/${workId}`;

const PROFILE_CONNECTIONS = profileId => `${NETWORK}/connections?participant_id=${profileId}`;
const PROFILE_ACCESSES = `${PROFILES_ENDPOINT}/profileaccesses`;
const DELETE_PROFILE_ACCESSES = profileAccessId => `${PROFILE_ACCESSES}/${profileAccessId}`;
const PROFILE_ACCESS_REQUESTS = `${PROFILES_ENDPOINT}/profileaccessrequests`;
const SET_PROFILE_ACCESS_REQUESTS = profileId => `${PROFILES_ENDPOINT}/${profileId}/profileaccessrequests`;

const ARTIST_AGREEMENTS = `${AGENCIES}/agreements`;

const AGENCY_AGENTS_INVITATIONS = agencyId => `${AGENCIES}/${agencyId}/${INVITATIONS}`;
const AGENCY_OFFICES = agencyId => `${AGENCIES}/${agencyId}/${OFFICES}`;

const PROFILE_ACTIONS = profileId => `${PROFILES_ENDPOINT}/${profileId}/actions`;

const PROFILE_REPERTOIRE = profileId => `${PROFILES_ENDPOINT}/${profileId}/${REPERTOIRE}`;
const PROFILE_CAREER_GRAPH = profileId => `${PROFILES_ENDPOINT}/${profileId}/${CAREER_GRAPH}`;
const MEDIA = 'media';
const MEDIA_SHOWREELS = 'media/showreelsmedia';
const MEDIA_SHOWREELS_ORDER = 'media/showreelsmedia/order';
const PRODUCTION_MEDIA = id => `productions/${id}/media`;
const PRODUCTION_REVIEWS_V1 = `api/reviews`;
const PRODUCTION_REVIEWS_V2 = productionId => `api/productions/${productionId}/reviews`;
const MEDIA_ATTRIBUTES = 'media/attributes';
const MEDIA_TAGS = 'media/tags';
const MEDIA_ACTIONS = mediaId => `media/${mediaId}/actions`;
const MEDIA_ORDER = `${MEDIA}/order`;
const REVIEWS = profileId => `${PROFILES_ENDPOINT}/${profileId}/reviews`;
const PRODUCTION_REVIEWS = reviewId => `reviews/${reviewId}`;
const NEW_REVIEWS = 'reviews';
const REVIEW_ACTIONS = reviewId => `${NEW_REVIEWS}/${reviewId}/actions`;
const REVIEW_ORDER = `${NEW_REVIEWS}/order`;

const STATS_NUMBERS = `stats/numbers`;
const STATS_NUMBERS_BY_ID = id => `stats/numbers/${id}`;
const SEASONS = 'seasons';
const SEASONS_COMPANIES = 'seasons/companies';
const PROFESSIONS = 'professions';
const INSTRUMENTS = 'instruments';
const PROFILE_PROFESSION = profileId => `${PROFILES_ENDPOINT}/${profileId}/${PROFESSIONS}`;
const REGIONS = 'regions';
const PING_URL = 'users/ping/status';
const BANK_CARDS = 'users/cards';
const REQUESTACCESS = `${PROFILES_ENDPOINT}/accessrequests`;
const PRIVACY_OPTIONS = 'privacy_attributes';
const PRODUCTION_DOCUMENTS = `${PRODUCTIONS}/documents`;
const PRODUCTION_DOCUMENTS_V1 = id => `api/productions/${id}/documents`;
const PRODUCTION_PRIVACY = productionId => `${PRODUCTIONS}/${productionId}/privacy`;

const PRODUCTION_DATES = productionId => `${PRODUCTIONS}/${productionId}/productionPerformances`;
const PRODUCTION_PERFORMANCE_DATES = (productionId, perfId) =>
  `${PRODUCTIONS}/${productionId}/productionPerformances/${perfId}`;
const PRODUCTION_DATE_ACTIONS = productionId => `${PRODUCTIONS}/${productionId}/actions`;
const PRODUCTION_CHNAGE_REQUEST_ACTIONS = productionId => `${CHANGE_REQUEST}/${productionId}/actions`;
const PRODUCTION_DOCUMENTS_BY_ID = productionId => `${PRODUCTIONS}/${productionId}/documents`;
const NEW_PRODUCTION_DOCUMENT_BY_ID = (productionId, documentId) =>
  `${PRODUCTIONS}/${productionId}/documents/${documentId}`;
const PRODUCTION_ACTIONS = productionId => `${PRODUCTIONS}/${productionId}/actions`;
const PRODUCTION_SYNOPSIS = productionId => `${PRODUCTIONS}/${productionId}/synopsis`;
const PRODUCTION_SYNOPSIS_BY_ID = (productionId, synopsisId) => `${PRODUCTIONS}/${productionId}/synopsis/${synopsisId}`;
const PRODUCTION_ENSEMBLES = productionId => `${PRODUCTIONS}/${productionId}/contributions`;
const PRODUCTION_TICKETS = productionId => `${PRODUCTIONS}/${productionId}/tickets`;
const ENSEMBLES = 'ensembles';
const PRODUCTION_WORKS = productionId => `${PRODUCTIONS}/${productionId}/works`;
const PRODUCTION_TO_PRODUCTION_WORKS = workId => `${PRODUCTIONS}/productionWorks/${workId}/workTypes`;
const PRODUCTION_WORK_TYPES = id => `${PRODUCTIONS}/${id}/workTypes`;
const PRODUCTION_TAG_TYPES = `${PRODUCTIONS}/productionTags`;
const PRODUCTION_TO_TAG_TYPES = id => `${PRODUCTIONS}/${id}/productionToTags`;

const SUPPORT_REQUESTS = 'users/supportRequests';
const MEDIA_SUGGESTIONS = 'media/suggestions';
const MEDIA_AGGREGATIONS = 'media/aggregations';

const NOTIFICATIONS = 'notifications';
const USER_NOTIFICATIONS = 'notifications';
const PROFILE_NOTIFICATIONS = 'profiles/notifications';

const ARTISTS_WORKED_WITH = userId => `users/${userId}/referrals`;
const GIFT_SUBSCRIPTIONS = userId => `users/${userId}/referralStatus`;
const USER_REWARD_BALANCE = 'users/rewardBalance';
const USER_REFERRALS = `users/giftTokens`;
const USER_REFERRALS_STATUS = 'users/referralStatus';
const AGENCY_ARTIST_ROSTER = `agencyArtists`;
const BIO_SUMMARY = (entity, entityId) => `${entity}/${entityId}/descriptions`;
const ENTITY_CONTACTS = (entity, entityId) => `${entity}/${entityId}/contacts`;
const ARTIST_AGENCIES = `${AGENCIES}/artists`;
const ENTITY_RECOMMENDATIONS = (entity, entityId) => `${entity}/${entityId}/recommendations`;
const PROFILES_VIEWED = 'profiles/viewed';
const USER_NEW_PERFORMANCES = 'users/performances';

const PROFILE_TYPE_PREFERENCES = 'profiles/types';
const PROFILE_PREFERENCES = profileId => `profiles/${profileId}/preferences`;
const PROFILE = profileId => `profiles/${profileId}`;
const USER_PERFORMACES = userId => `users/${userId}/performances`;

const CREDIT_NOTES = 'invoices/creditNotes';
const INVOICES = 'invoices';

const IDENTITY_VERIFICATION = userId => `users/${userId}/kyc`;
const USERS_SUBSCRIPTIONS = 'users/subscriptions';

const TESTIMONIALS = 'users/testimonials';

const ORGANIZATION = 'organizations';
const RELATIONS = 'relations';
const ORGANIZATION_TRANSLATIONS = orgId => `${ORGANIZATION}/${orgId}/${TRANSLATIONS}`;
const ORGANIZATION_DESCRIPTIONS = orgId => `${ORGANIZATION}/${orgId}/descriptions`;
const ORGANIZATION_VENUES = orgId => `${ORGANIZATION}/${orgId}/venues`;
const ORGANIZATION_RELATIONS = orgId => `${ORGANIZATION}/${orgId}/${RELATIONS}`;
const ORGANIZATION_CONTACTS = orgId => `${ORGANIZATION}/${orgId}/${CONTACTS}`;
const ORGANIZATION_ADDRESSES = orgId => `${ORGANIZATION}/${orgId}/${ADDRESSES}`;
const ORGANIZATION_EMPLOYEES = orgId => `${ORGANIZATION}/${orgId}/${EMPLOYEES}`;
const ORGANIZATION_CERTAIN_EMPLOYEE = (orgId, empId) =>
  empId ? `${ORGANIZATION}/${orgId}/${EMPLOYEES}/${empId}` : `${ORGANIZATION}/${orgId}/${EMPLOYEES}`;
const ORGANIZATION_ACTIONS = orgId => `${ORGANIZATION}/${orgId}/actions`;
const ORGANIZATION_TYPES = `${ORGANIZATION}/types`;
const AOS_PROFESSIONS = `${ORGANIZATION}/employees/professions`;
const PERFORMANCES_TYPES = `${PERFORMANCES}/types`;
const ORGANIZATION_PERFORMANCE_TYPES = orgId => `${ORGANIZATION}/${orgId}/performanceTypes`;
const ORGANIZATION_WORK_TYPES = orgId => `${ORGANIZATION}/${orgId}/${WORK_TYPES}`;
const ORGANIZATION_WORK_TYPES_DELETE = ({ orgId, workId }) => `${ORGANIZATION}/${orgId}/${WORK_TYPES}/${workId}`;
const ORGANIZATION_STAGES = orgId => `${ORGANIZATION}/${orgId}/${STAGES}`;
const ORGANIZATION_STAGE_DELETE = ({ orgId, stageId }) => `${ORGANIZATION}/${orgId}/${STAGES}/${stageId}`;
const ORGANIZATIONS_DEPARTMENTS = `${ORGANIZATION}/departments`;

const INSTRUMENTS_ROLES = 'instruments/roles';
const INSTRUMENTS_ID_ROLES = id => `instruments/${id}/roles`;

const PROFILE_DOCUMENTS = profileId => `profiles/${profileId}/documents`;
const DELETE_PROFILE_DOCUMENT = ({ profileId, documentId }) => `profiles/${profileId}/documents/${documentId}`;

module.exports = {
  PROFILE_CAREER_GRAPH,
  ACCOUNT_CREATE,
  ACCOUNT_SETTINGS,
  ADMIN_REQUESTS,
  ADVANCED_STATS,
  ADVANCED_STATS_AGGREGATIONS,
  AGENCIES,
  YAP,
  AGENCY,
  AGENCY_AGENTS,
  AGENCY_AGENTS_INVITATIONS,
  AGENCY_ARTISTS,
  AGENCY_CERTAIN_AGENT,
  AGENCY_OFFICES,
  AGENCY_REQUESTS,
  AGENT_PROFILE_AGENCIES,
  AGENTS,
  AOS_PROFESSIONS,
  ARTICLES,
  ARTIST_AGREEMENTS,
  ARTIST_PROFILE_AGENCIES,
  ARTIST_RECOMMENDATIONS,
  ARTIST_TRANSLATIONS,
  ARTISTS,
  AUTH,
  AUTH_REFRESH_TOKEN,
  AUTH_TOKEN,
  SOCIAL_LOGIN_URLS,
  BANK_CARDS,
  CASTS_ENDPOINT,
  CHECKOUTS,
  CITY_ENDPOINT,
  COMPANIES,
  COMPANY_YOUNG_ARTIST_PROGRAM,
  COMPANY_FESTIVALS,
  COMPETITIONS,
  COMPETITIONS_AGGREGATIONS,
  COMPOSER,
  COMPOSER_REVIEWS,
  CONNECTIONS,
  CONVERSATION_CREATE_MESSAGE,
  CONVERSATIONS,
  COUNTRIES_ENDPOINT,
  COUNTRY_CITY_ENDPOINT,
  CURRENCIES,
  EMPLOYEES,
  FESTIVAL_CERTAIN_EMPLOYEE,
  FESTIVAL_CONTACTS,
  FESTIVAL_DESCRIPTIONS,
  FESTIVAL_EMPLOYEES,
  FESTIVALS,
  FESTIVAL_TRANSLATIONS,
  FESTIVAL_YOUNG_ARTIST_PROGRAM,
  FILTER_AGGREGATION_SEARCH,
  FORGOT_PASSWORD,
  LANGUAGES_LIST,
  PERFORMANCES,
  USER_PERFORMANCES,
  PRODUCTIONS,
  CHANGE_REQUEST,
  PRODUCTIONS_REVIVALS,
  PRODUCTIONS_NEWANDRARE,
  NEWANDRARE_AGGREGATIONS,
  REPERTOIRE,
  PROFILES_ENDPOINT,
  PROFILE_ACCESSES,
  DELETE_PROFILE_ACCESSES,
  PROFILE_ACCESS_REQUESTS,
  SEARCH_ENDPOINT,
  INVITATIONS,
  JOBS_ENDPOINT,
  MEDIA,
  MEDIA_ATTRIBUTES,
  MEDIA_TAGS,
  MUSICAL_WORK_ENDPOINT,
  MUSICAL_WORK_STYLES_ENDPOINT,
  MUSICAL_WORK_TYPES_ENDPOINT,
  NETWORK,
  NETWORK_INVITATIONS,
  OFFICE,
  OFFICES,
  ONTHISDAY,
  SUBSCRIPTIONS,
  PRODUCTS,
  USER_SUBSCRIPTIONS,
  PROFILE_CONNECTIONS,
  PROFILE_REPERTOIRE,
  REVIEWS,
  PRODUCTION_REVIEWS,
  PRODUCTION_REVIEWS_V1,
  PRODUCTION_REVIEWS_V2,
  NEW_REVIEWS,
  REVIEW_ACTIONS,
  STATS_NUMBERS,
  STATS_NUMBERS_BY_ID,
  SEASONS,
  SEASONS_COMPANIES,
  PROFESSIONS,
  INSTRUMENTS,
  REGIONS,
  REQUESTACCESS,
  PAGERULES_ENDPOINT,
  PING_URL,
  PRIVACY_OPTIONS,
  PRODUCTION_ACTIONS,
  PRODUCTION_CASTS,
  PRODUCTION_DATES,
  PRODUCTION_PERFORMANCE_DATES,
  PRODUCTION_DATE_ACTIONS,
  PRODUCTION_DOCUMENTS,
  PRODUCTION_DOCUMENTS_BY_ID,
  PRODUCTION_DOCUMENTS_V1,
  MEDIA_ACTIONS,
  PRODUCTION_PRIVACY,
  PRODUCTIONS_AGGREGATIONS,
  SET_PROFILE_ACCESS_REQUESTS,
  PROFILE_CONTACTS_ENDPOINT,
  PROFILE_PROFESSION,
  PROFILES_BIO_ENDPOINT,
  PROFILES_WITH_SIMILAR_PROFESSION_ENDPOINT,
  PROJECT_CANDIDATES,
  PROJECTS,
  REGISTRATIONS,
  REGISTRATION,
  RENTALS,
  REVIEWS_ENDPOINT,
  SUPPORT_REQUESTS,
  MEDIA_SUGGESTIONS,
  MEDIA_AGGREGATIONS,
  PRODUCTION_SYNOPSIS,
  PRODUCTION_ENSEMBLES,
  NEW_PRODUCTION_DOCUMENT_BY_ID,
  PRODUCTION_SYNOPSIS_BY_ID,
  PRODUCTION_TICKETS,
  PRODUCTION_WORKS,
  PRODUCTION_TO_PRODUCTION_WORKS,
  PRODUCTION_WORK_TYPES,
  PRODUCTION_TAG_TYPES,
  PRODUCTION_TO_TAG_TYPES,
  USER_PAYMENTS,
  VENUES,
  VENUES_AGGREGATIONS,
  VENUES_CONTACTS,
  VENUES_STAGES_ENDPOINT,
  WORK_ROLES,
  VIDEOS,
  ENSEMBLES,
  NOTIFICATIONS,
  PRODUCTION_MEDIA,
  PRODUCTION_CHNAGE_REQUEST_ACTIONS,
  USER_NOTIFICATIONS,
  PROFILE_NOTIFICATIONS,
  PROFILE_ACTIONS,
  AGENCY_ACTIONS,
  MEDIA_SHOWREELS,
  MEDIA_SHOWREELS_ORDER,
  REVIEW_ORDER,
  MEDIA_ORDER,
  RENTAL_ACTION,
  RENTAL_INCLUDES,
  FESTIVAL_VENUES,
  WORKS_AGGREGATIONS,
  ARTISTS_WORKED_WITH,
  GIFT_SUBSCRIPTIONS,
  USER_REFERRALS,
  USER_REWARD_BALANCE,
  AGENCY_ARTIST_ROSTER,
  BIO_SUMMARY,
  ENTITY_CONTACTS,
  COMPANIES_AGGREGATIONS,
  ARTIST_AGENCIES,
  ENTITY_RECOMMENDATIONS,
  USER_NEW_PERFORMANCES,
  MW_WORK_TYPES,
  WORK_STAGING_TYPES_ENDPOINT,
  VIDEO_URL,
  CREDIT_NOTES,
  INVOICES,
  PROFILE,
  USER_PERFORMACES,
  IDENTITY_VERIFICATION,
  USERS_SUBSCRIPTIONS,
  PROFILE_TYPE_PREFERENCES,
  PROFILE_PREFERENCES,
  USER_REFERRALS_STATUS,
  TESTIMONIALS,
  ORGANIZATIONS,
  ORGANIZATIONS_TYPES,
  ORGANIZATION_ABOUT,
  ORGANIZATION,
  ORGANIZATION_DESCRIPTIONS,
  ORGANIZATION_TRANSLATIONS,
  ORGANIZATION_VENUES,
  ORGANIZATION_RELATIONS,
  ORGANIZATION_CONTACTS,
  ORGANIZATION_EMPLOYEES,
  ORGANIZATION_CERTAIN_EMPLOYEE,
  ORGANIZATION_ACTIONS,
  ORGANIZATION_TYPES,
  ORGANIZATION_ADDRESSES,
  PERFORMANCES_TYPES,
  ORGANIZATION_PERFORMANCE_TYPES,
  ORGANIZATION_WORK_TYPES,
  ORGANIZATION_WORK_TYPES_DELETE,
  ORGANIZATION_STAGES,
  ORGANIZATION_STAGE_DELETE,
  ORGANIZATIONS_DEPARTMENTS,
  PROFILE_CHECKLIST,
  PROFILE_CUSTOMIZATIONS,
  PROFILE_PREFERRED_ENTITIES,
  SUGGESTED_ENTITIES,
  INTERMEDIATE_SCREEN_BASE_URL,
  INSTRUMENTS_ROLES,
  INSTRUMENTS_ID_ROLES,
  PROFILES_VIEWED,
  PROFILE_DOCUMENTS,
  DELETE_PROFILE_DOCUMENT,
};
