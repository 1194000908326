import { SPOTLIGHT_PRODUCTION_TYPE } from 'constants/index';
import { search_types } from 'constants/search';
export const PAGE_TYPE = {
  LISTING_PAGE: 'listingPage',
  STUB_PAGE: 'stubPage',
  LANDING_PAGE: 'landingPage',
  GLOBAL_SEARCH: 'globalSearch',
  POP_UP: 'popUp',
};

export const PAGE_SUB_TYPE = {
  ORGANISATION: 'organization',
  PROFILE: 'profile',
  NEW_AND_RARE: 'newAndRare',
  PRODUCTION: 'production',
  SEASON: 'season',
  WORK: 'work',
  STATISTICS: 'statistics',
  HIGHLIGHT: 'highlight',
  MANAGER: 'manager',
  COMPETITION: 'competition',
  RENTAL: 'rental',
  VENUES: 'venues',
  VIDEO: 'video',
  PRO_COMPANY: 'proCompany',
  PRO_AGENCY: 'proAgency',
  PRO_ARTIST: 'proArtist',
  HOME: 'home',
  CASTING_TOOL: 'castingTool',
  ALL_SEARCH: 'allSearch',
  RECENT_VIEWED: 'recentViewed',
  PROFILE_SEARCH: 'profileSearch',
  PRODUCTION_SEARCH: 'productionSearch',
  WORK_SEARCH: 'workSearch',
  VENUE_SEARCH: 'venueSearch',
  ORGANISATION_SEARCH: 'organisationSearch',
  VIDEO_SEARCH: 'videoSearch',
  AGENCY_SEARCH: 'agencySearch',
  FINGERPRINT: 'fingerprint',
  PRO_TOOLS: 'proTools',
  PRO_DETAILS: 'proDetails',
};

export const SPOTLIGHT_PRODUCTION_TYPE_MAPPING_TO_PAGESUB_TYPE = {
  [SPOTLIGHT_PRODUCTION_TYPE.NEW_RARE]: PAGE_SUB_TYPE.NEW_AND_RARE,
  [SPOTLIGHT_PRODUCTION_TYPE.HIGHLIGHTS]: PAGE_SUB_TYPE.HIGHLIGHT,
};

export const SEARCH_SUB_TYPES_MAPPING = {
  [search_types.PROFILES]: PAGE_SUB_TYPE.PROFILE_SEARCH,
  [search_types.COMPOSERS]: PAGE_SUB_TYPE.PROFILE_SEARCH,
  [search_types.PRODUCTIONS]: PAGE_SUB_TYPE.PRODUCTION_SEARCH,
  [search_types.COMPANIES]: PAGE_SUB_TYPE.ORGANISATION_SEARCH,
  [search_types.VENUES]: PAGE_SUB_TYPE.VENUE_SEARCH,
  [search_types.AGENCIES]: PAGE_SUB_TYPE.AGENCY_SEARCH,
  [search_types.WORKS]: PAGE_SUB_TYPE.WORK_SEARCH,
  [search_types.FESTIVALS]: PAGE_SUB_TYPE.ORGANISATION_SEARCH,
  [search_types.OPERA_ONLINE]: PAGE_SUB_TYPE.VIDEO_SEARCH,
  [search_types.ALL]: PAGE_SUB_TYPE.ALL_SEARCH,
};

export const PAGE_ENTITY_TYPE = {
  FESTIVAL: 'festival',
  PRODUCTION: 'production',
  PROFILE: 'profile',
  COMPANY: 'company',
  COMPETITION: 'competition',
  MANAGER: 'manager',
  RENTAL: 'rental',
  VENUES: 'venues',
  WORK: 'work',
};

export const FOOTPRINT_TRACKING_ENDPOINT = {
  postFingerprint: 'fingerprints',
  getUserByFingerprint: 'fingerprints/user',
}

export const COMPONENT_TYPE = {
  QUICK_PREVIEW: 'quickPreview',
  ACCORDION: 'accordion',
};
