import { createRoutine } from 'redux-saga-routines';

import {
  CHECK_UPDATE_PASSWORD_KEY,
  CLEAN_AGGREGATIONS,
  CLEAR_PROFILES_LIST,
  CLEAR_COMPANIES,
  CRUD_GET_LIST,
  CRUD_GET_ONE,
  CRUD_GET_WHOLE_LIST,
  ENQUEUE_SNACKBAR,
  GET_ACTIVE_PROFILE_SUBSCRIPTIONS,
  GET_PROFILE_SUBSCRIPTIONS,
  GET_AGENCIES,
  CLEAR_AGENCIES,
  GET_AGGREGATIONS,
  GET_COMPANIES,
  GET_PROFILES_LIST,
  LOGOUT,
  REMOVE_SNACKBAR,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  CRUD_UPDATE_ONE,
  CRUD_CREATE,
  SET_START_AGGREGATIONS,
  CRUD_DELETE,
  GET_ARTIST_VOICE_TYPES,
  RESET_RESET_PASSWORD,
  GET_CITIES,
  RESET_CITIES,
  GET_VENUES,
  GET_VENUES_SEARCH,
  RESET_VENUES,
  GET_STAGES,
  USE_BUFFER_VALUE,
  GET_WORK_TYPES,
  GET_WORK_STYLES,
  GET_PRIVACY_OPTIONS,
  GET_AOS_PROFESSIONS,
  GET_LANGUAGES,
  GET_DYNAMIC_COUNTRIES,
  GET_MEDIA_ATTRS_TYPES,
  GET_VIDEO_FORMAT_TYPES,
  SET_PROFILE_IDS_WITH_ACCESS,
  GET_SUBSCRIPTIONS,
  GET_SEARCH_PRODUCTIONS_LIST,
  CLEAN_SEARCH_PRODUCTIONS_LIST,
  RESET_VENUES_SEARCH,
  ADD_PROFILE_IDS_WITH_ACCESS,
  GET_PROFILE_ACCESS_REQUESTS,
  GET_MULTIPLE_WORK_TYPES,
  GET_ORGANIZATIONS,
} from './constants';

export const logoutAction = createRoutine(LOGOUT);
export const setProfileIdsWithAccess = createRoutine(SET_PROFILE_IDS_WITH_ACCESS);
export const addProfileIdsWithAccess = createRoutine(ADD_PROFILE_IDS_WITH_ACCESS);
export const getActiveProfileSubscriptionsAction = createRoutine(GET_ACTIVE_PROFILE_SUBSCRIPTIONS);
export const getProfileSubscriptionsAction = createRoutine(GET_PROFILE_SUBSCRIPTIONS);
export const resetPasswordAction = createRoutine(RESET_PASSWORD);
export const resetResetPasswordAction = createRoutine(RESET_RESET_PASSWORD);
export const updatePasswordAction = createRoutine(UPDATE_PASSWORD);
export const checkUpdatePasswordKeyAction = createRoutine(CHECK_UPDATE_PASSWORD_KEY);

export const setStartAggregationAction = createRoutine(SET_START_AGGREGATIONS);
export const getAggregationsAction = createRoutine(GET_AGGREGATIONS);
export const cleanAggregationsAction = createRoutine(CLEAN_AGGREGATIONS);
export const getArtistVoiceTypesAction = createRoutine(GET_ARTIST_VOICE_TYPES);
export const getAgenciesAction = createRoutine(GET_AGENCIES);
export const clearAgenciesAction = createRoutine(CLEAR_AGENCIES);
export const getCompaniesAction = createRoutine(GET_COMPANIES);
export const clearCompaniesAction = createRoutine(CLEAR_COMPANIES);
export const getProfilesAction = createRoutine(GET_PROFILES_LIST);
export const clearProfilesAction = createRoutine(CLEAR_PROFILES_LIST);
export const getSubscriptionsAction = createRoutine(GET_SUBSCRIPTIONS);

export const getList = createRoutine(CRUD_GET_LIST);
export const getWholeList = createRoutine(CRUD_GET_WHOLE_LIST);
export const getOne = createRoutine(CRUD_GET_ONE);
export const updateOne = createRoutine(CRUD_UPDATE_ONE);
export const createEntity = createRoutine(CRUD_CREATE);
export const deleteEntity = createRoutine(CRUD_DELETE);

export const enqueueSnackbarAction = createRoutine(ENQUEUE_SNACKBAR);
export const removeSnackbarAction = createRoutine(REMOVE_SNACKBAR);

export const getCitiesAction = createRoutine(GET_CITIES);
export const resetCitiesAction = createRoutine(RESET_CITIES);
export const getVenuesAction = createRoutine(GET_VENUES);
export const getVenuesSearchAction = createRoutine(GET_VENUES_SEARCH);
export const resetVenuesAction = createRoutine(RESET_VENUES);
export const resetVenuesSearchAction = createRoutine(RESET_VENUES_SEARCH);
export const getStagesAction = createRoutine(GET_STAGES);

export const useBufferValueAction = createRoutine(USE_BUFFER_VALUE);
export const getWorkTypesAction = createRoutine(GET_WORK_TYPES);
export const getMultipleWorkTypesAction = createRoutine(GET_MULTIPLE_WORK_TYPES);
export const getWorkStylesAction = createRoutine(GET_WORK_STYLES);
export const getPrivacyOptionsAction = createRoutine(GET_PRIVACY_OPTIONS);
export const getAOSProfessionsAction = createRoutine(GET_AOS_PROFESSIONS);

export const getLanguagesAction = createRoutine(GET_LANGUAGES);
export const getDynamicCountriesAction = createRoutine(GET_DYNAMIC_COUNTRIES);
export const getMediaAttrTypesAction = createRoutine(GET_MEDIA_ATTRS_TYPES);
export const getVideoFormatAttrTypesAction = createRoutine(GET_VIDEO_FORMAT_TYPES);

export const getSearchProductionsAction = createRoutine(GET_SEARCH_PRODUCTIONS_LIST);

export const getProfileAccessRequestsAction = createRoutine(GET_PROFILE_ACCESS_REQUESTS);

export const getOrganizationsAction = createRoutine(GET_ORGANIZATIONS);

export function cleanSearchProductionsListAction() {
  return {
    type: CLEAN_SEARCH_PRODUCTIONS_LIST,
    payload: {},
  };
}
