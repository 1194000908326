export const TP = 'operabase.general';
export const ENTITY_SLUG_PATTERN = /^.*-[a-z]{1,2}\d+$/;

export const MEDIA_TAG_RELATIONS = {
  VENUE: 'venue',
  COMPOSER: 'composer',
  WORK: 'work',
  CAST: 'cast',
  ORGANIZATION: 'organization',
};

export const EDIT_PAGE_TYPE = {
  ARTIST: 'artist',
  COMPANY: 'company',
  FESTIVAL: 'festival',
  IMAGES: 'images',
  VIDEOS: 'videos',
  ORGANIZATION: 'organization',
};

export const PRO_PAGES = {
  All: 'all',
  COMPANIES: 'companies',
  AGENCIES: 'agencies',
  ARTISTS: 'artists',
  ACADEMIA: 'academia',
  JOURNALIST: 'journalist',
  CASTING_TOOL: 'casting',
  AUDIENCE: 'proaudience',
};

export const GENERAL_MANAGEMENT_AGREEMENT_TYPE = 'general management';

export const GENERAL_QUERY_LINKS = {
  en: 'https://help.operabase.com/knowledge/en/general-queries',
  fr: 'https://help.operabase.com/fr/knowledge/en/questions-g%C3%A9n%C3%A9rales',
  ru: 'https://help.operabase.com/ru/knowledge/en',
  es: 'https://help.operabase.com/es/knowledge/en/consultas-generales',
  it: 'https://help.operabase.com/it/knowledge/en/domande-generali',
  ga: 'https://help.operabase.com/de/knowledge/en/allgemeine-fragen',
};

export const MEDIA_TAGS_TYPES_IDS = {
  PROFILE: 1,
  COMPANY: 2,
  VENUE: 3,
  PRODUCTION: 4,
  COMPOSER: 5,
  DATE: 6,
  FESTIVAL: 7,
  WORK: 8,
  CAST: 9,
  PRODUCTION_COMPANY: 10,
  PRODUCTION_FESTIVAL: 11,
  ORGANIZATION: 12,
  PRODUCTION_CONTRIBUTION: 13,
};

// TODO: Update filter params to use these constants
export const VALID_QUERY_PARAMS = {
  SCROLL_TO: 'st',
  OPEN_MODAL: 'm',
  AGENCY_CONNECTION_ID: 'connection_id',
};

export const BASE_PAGE_ROUTES = {
  HOME: '/',
  ARTICLES: '/articles',
  ARTISTS: '/artists',
  ORGANIZATIONS: '/organisations',
  COMPANIES: '/companies',
  COMPETITIONS: '/competitions',
  COMPOSERS: '/composers',
  EMAIL: '/email',
  FESTIVALS: '/festivals',
  HIGHLIGHTS: '/highlights',
  LOGIN: '/login',
  MANAGERS: '/managers',
  NEW_AND_RARE: '/new-and-rare',
  ON_THIS_DAY: '/onthisday',
  PAYMENT: '/payment',
  PRO: '/pro',
  PRODUCTIONS: '/productions',
  PROFILE_ACCESS: '/profile-access',
  RENTALS: '/rentals',
  SEARCH: '/search',
  SEASONS: '/seasons',
  SELECT_PROFILE: '/select-profile',
  VENUES: '/venues',
  VIDEOS: '/videos',
  WORKS: '/works',
  ACCOUNT_SETTINGS: '/account-settings',
  CASTING: '/casting',
  CONTACT: '/contact',
  COOKIE_POLICY: '/cookpol',
  INTRO: '/intro',
  LINK: '/link',
  MAPS: '/maps',
  NOTIFICATIONS: '/notifications',
  PAYMENT_TERMS: '/payment-terms',
  PERMISSION_TEST: '/permtest',
  PRIVACY_POLICY: '/privpol',
  PRO_AUDIENCE: '/proaudience',
  REGISTER: '/register',
  STATISTICS: '/statistics',
  TERM_POLICY: '/termpol',
  V4: '/v4',
};

export const ENTITY_TYPE = {
  ARTIST: 'artist',
  ORGANIZATION: 'organization',
  PRODUCTION: 'production',
  WORK: 'work',
  MANAGER: 'manager',
  VENUE: 'venue', // TODO: [ORG-BUILD] DEPRECATED
  PERFORMANCE: 'performance', // TODO: [ORG-BUILD] DEPRECATED
  AGENCY: 'agency', // TODO: [ORG-BUILD] DEPRECATED
};

export const ENTITY_TYPE_MAP = {
  artist: ENTITY_TYPE.ARTIST,
  organization: ENTITY_TYPE.ORGANIZATION,
  work: ENTITY_TYPE.WORK,
  production: ENTITY_TYPE.PRODUCTION,
};

export const ENTITY_META_DETA_TYPES = {
  SEASON: 'season_type',
};

export const PROFILE_TYPE_IDS = {
  ARTIST: 1,
  AGENT: 2,
  AOS: 3,
  AUDIENCE: 4,
  PRO: 5,
  CASTING: 6,
  ACADEMICS: 7,
  JOURNALIST_MEDIA: 8,
  OTHER: 9,
};

export const ORGANIZATION_TYPE_IDS = {
  COMPANY: 1,
  FESTIVAL: 2,
  YAP: 3,
  ORCHESTRA: 4,
  CHORUS: 5,
  BALLET: 6,
  CHOIR: 7,
  OTHER: 8,
  VENUE: 30,
  COMPETITION: 14,
};

export const ENTITY_TYPE_ROUTE_MAP = {
  [ENTITY_TYPE.ARTIST]: BASE_PAGE_ROUTES.HOME,
  [ENTITY_TYPE.ORGANIZATION]: BASE_PAGE_ROUTES.HOME,
  [ENTITY_TYPE.PRODUCTION]: BASE_PAGE_ROUTES.PRODUCTIONS,
  [ENTITY_TYPE.WORK]: BASE_PAGE_ROUTES.WORKS,
  [ENTITY_TYPE.MANAGER]: BASE_PAGE_ROUTES.MANAGERS,
  [ENTITY_TYPE.PERFORMANCE]: BASE_PAGE_ROUTES.PRODUCTIONS, // TODO: [ORG-BUILD] DEPRECATED
};

export const ORGANIZATION_TYPE_BASE_ROUTE_MAP = {
  [ORGANIZATION_TYPE_IDS.COMPANY]: BASE_PAGE_ROUTES.COMPANIES,
  [ORGANIZATION_TYPE_IDS.FESTIVAL]: BASE_PAGE_ROUTES.FESTIVALS,
  [ORGANIZATION_TYPE_IDS.VENUE]: BASE_PAGE_ROUTES.VENUES,
};

export const CONTACT_CATEGORY_TYPE = {
  COMPANY: 'company',
  FESTIVAL: 'festival',
};

export const SPOTLIGHT_PRODUCTION_TYPE = {
  HIGHLIGHTS: 'highlights',
  NEW_RARE: 'new_and_rare',
};

export const LOCATION_TYPE = {
  COUNTRY: 'country',
  CITY: 'city',
};

export const VALIDATION_STATUSES = {
  PENDING: 1,
  PROCESSING: 2,
  APPROVED: 3,
  REJECTED: 4,
};

export const STATUS = { APPROVED: { ID: 3, VALUE: 'approved' } };

export const KEY_CODE = {
  ENTER: 'Enter',
};

export const PROFESSIONS_AVAILABILITY = {
  CASTING_TOOL: 'casting_tool',
  EDIT_PROFESSION: 'update_production,create_crew_profile',
  CAST_CREW_PROFESSION: 'update_production_cast',
  UPDATE_PRODUTION: 'update_production',
  CREATE_CREW_PROFILE: 'create_crew_profile',
};

export const CONTRIBUTORS_ENTITY_TYPE = {
  INDIVIDUAL: 'individual',
  ORGANIZATION: 'organization',
};

export const URL_SLUG_TYPES = {
  ARTIST: 'profile_id',
  INDIVIDUAL: 'individual_id',
  ORGANIZATION: 'organization_id',
  ORGANIZATION_TYPE: 'organization_type_id',
  CO_CAST: 'co_cast_id', // INFO: Gets replaced to individual ID when passed to API
  CONDUCTOR: 'conductor_id',
  DIRECTOR: 'director_id',
  CREATOR: 'work_creator_id',
  COMPANY: 'company_id',
  FESTIVAL: 'festival_id',
  PRODUCTION: 'production_id',
  WORK: 'work_id',
  VENUE: 'venue_id',
  DATE: 'date',
  CITY: 'city_id',
  COUNTRY: 'country_id',
  PUBLISHER: 'publisher',
  MONTH_YEAR: 'month-year',
  YEAR: 'year',
  SINCE_YEAR: 'since_year',
  LANGUAGE: 'language_id',
  SURTITLE: 'surtitle_id',
  VIDEO_TYPE: 'video_type',
  REPERTOIRE_STATUS: 'repertoire_status',
  PRODUCTION_PERIOD: 'period',
  VIEW_MODE: 'mode',
  BOOLEAN_SEARCH: 'expression',
  WORK_ROLE: 'work_role_id',
  WORK_ROLE_REFERENCE: 'work_role_reference',
  PROFESSION: 'profession_id',
  SEASON: 'season_id',
  CHORUS: 'chorus_id',
  ORCHESTRA: 'orchestra_id',
  GENRE: 'work_type_id',
  LETTER: 'letter',
  PAGE: 'page',
  QUERY: 'query',
};

export const URL_STATIC_PATHS = {
  EDIT: 'edit',
  MODIFY_PRODUCTION: 'modify-production',
  REVIVALS: 'revivals',
  SEARCH_PRODUCTIONS: 'search-productions',
  VALIDATION: 'validation',
  SEARCH: 'search',
  COMPANIES: 'companies',
  INSIGHTS: 'insights',
  AGENCY_MODIFY: 'agency_modif',
  AGENCY_ROSTER_ADD: 'roster-add',
  AGENCY_ROSTER_UPDATE: 'roster-update',
  ORG_TYPE_COMPANY: 'company',
  ORG_TYPE_FESTIVAL: 'festival',
  ORG_TYPE_VENUE: 'venue',
  ORG_TYPE_COMPETITON: 'competition',
};

export const URL_SLUG_ORDER = [
  URL_SLUG_TYPES.PRODUCTION,
  URL_SLUG_TYPES.PROFESSION,
  URL_SLUG_TYPES.ORGANIZATION_TYPE,
  URL_SLUG_TYPES.COUNTRY,
  URL_SLUG_TYPES.CITY,
  URL_SLUG_TYPES.PUBLISHER,
  URL_SLUG_TYPES.WORK,
  URL_SLUG_TYPES.VENUE,
  URL_SLUG_TYPES.ARTIST,
  URL_SLUG_TYPES.CO_CAST,
  URL_SLUG_TYPES.CONDUCTOR,
  URL_SLUG_TYPES.DIRECTOR,
  URL_SLUG_TYPES.COMPANY,
  URL_SLUG_TYPES.FESTIVAL,
  URL_SLUG_TYPES.CREATOR,
  URL_SLUG_TYPES.VIEW_MODE,
  URL_SLUG_TYPES.SEASON,
  URL_SLUG_TYPES.DATE,
];

export const CONTACTS_CATEGORY = {
  ORGANIZATION: 'organization',
  BOX_OFFICE: 'box_office',
};

export const URL_SLUG_PREFIXES = [
  { prefix: 'a', type: URL_SLUG_TYPES.ARTIST, entityType: ENTITY_TYPE.ARTIST },
  { prefix: 'b', type: URL_SLUG_TYPES.CITY },
  { prefix: 'c', type: URL_SLUG_TYPES.COMPANY },
  { prefix: 'd', type: URL_SLUG_TYPES.DATE },
  { prefix: 'e', type: URL_SLUG_TYPES.CONDUCTOR },
  { prefix: 'f', type: URL_SLUG_TYPES.FESTIVAL },
  { prefix: 'g', type: URL_SLUG_TYPES.DIRECTOR },
  { prefix: 'h', type: null },
  { prefix: 'i', type: null },
  { prefix: 'j', type: null },
  { prefix: 'k', type: URL_SLUG_TYPES.CREATOR },
  { prefix: 'l', type: URL_SLUG_TYPES.COUNTRY },
  { prefix: 'm', type: null },
  { prefix: 'n', type: URL_SLUG_TYPES.PUBLISHER },
  { prefix: 'o', type: URL_SLUG_TYPES.ORGANIZATION, entityType: ENTITY_TYPE.ORGANIZATION },
  { prefix: 'p', type: URL_SLUG_TYPES.PRODUCTION, entityType: ENTITY_TYPE.PRODUCTION },
  { prefix: 'q', type: URL_SLUG_TYPES.PROFESSION },
  { prefix: 'r', type: URL_SLUG_TYPES.WORK_ROLE },
  { prefix: 'r', type: URL_SLUG_TYPES.WORK_ROLE_REFERENCE },
  { prefix: 't', type: URL_SLUG_TYPES.CO_CAST },
  { prefix: 'u', type: null },
  { prefix: 'v', type: URL_SLUG_TYPES.VENUE },
  { prefix: 'w', type: URL_SLUG_TYPES.WORK, entityType: ENTITY_TYPE.WORK },
  { prefix: 'x', type: null },
  { prefix: 'y', type: URL_SLUG_TYPES.YEAR },
  { prefix: 'z', type: URL_SLUG_TYPES.SEASON },
];

export const ENTITY_TYPE_PREFIX = URL_SLUG_PREFIXES.filter(item => !!item?.entityType).reduce((acc, curr) => {
  acc[curr.entityType] = curr.prefix;
  return acc;
}, {});

// NOTE: Converts URL_ENTITY_PREFIX variable to object with key as entity type and value as prefix
export const ENTITY_SLUG_ID_PREFIX = URL_SLUG_PREFIXES.reduce((acc, curr) => {
  if (curr.entityType) {
    acc[curr.entityType] = curr.prefix;
  }
  return acc;
}, {});

export const PROFESSION_TYPE = {
  COMPOSER: 'composer',
};

export const OPERABASE_MAIN_ROUTES = [
  { path: BASE_PAGE_ROUTES.ARTISTS, key: 'Artists', title: `${TP}.m_ARTISTS` },
  { path: BASE_PAGE_ROUTES.PRODUCTIONS, key: 'Productions', title: `${TP}.m_PROD` },
  { path: BASE_PAGE_ROUTES.COMPANIES, key: 'Companies', title: `${TP}.m_OPCOS` },
  { path: BASE_PAGE_ROUTES.FESTIVALS, key: 'Festivals', title: `${TP}.m_FESTIVALS` },
  { path: BASE_PAGE_ROUTES.VENUES, key: 'Venues', title: `${TP}.m_VENUES` },
  { path: BASE_PAGE_ROUTES.COMPOSERS, key: 'Composers', title: `${TP}.m_COMPOSERS` },
  { path: BASE_PAGE_ROUTES.MANAGERS, key: 'Managers', title: `${TP}.m_MANAGERS` },
  { path: BASE_PAGE_ROUTES.VIDEOS, key: 'Videos', title: `${TP}.m_VIDEOS` },
  { path: BASE_PAGE_ROUTES.WORKS, key: 'Musical works', title: `${TP}.FN_MUSICAL_WORKS` },
  { path: BASE_PAGE_ROUTES.COMPETITIONS, key: 'Competitions', title: `${TP}.m_COMPETITIONS` },
  { path: BASE_PAGE_ROUTES.SEASONS, key: 'Seasons', title: `${TP}.m_SEASON` },
  { path: BASE_PAGE_ROUTES.RENTALS, key: 'Rentals', title: `${TP}.m_RENTALS` },
];

export const SHARE_ACCESS_TYPE = {
  [ENTITY_TYPE.ARTIST]: 'artist_page',
  company: 'company_page',
  festival: 'festival_page',
  // Need to re name when working with agency
  AGENCY_PAGE: 'agency_page',
  AGENCY_PAGE_ARTIST: 'agency_page_profile',
};

export const SEARCH_ENTITY_TYPE_MAP = {
  profiles: ENTITY_TYPE.ARTIST,
  organizations: ENTITY_TYPE.ORGANIZATION,
  agencies: ENTITY_TYPE.MANAGER,
  works: ENTITY_TYPE.WORK,
};

// TODO: DEPRECATED below profile type ids and start using constant PROFILE_TYPE_IDS
export const ARTIST_TYPE_ID = PROFILE_TYPE_IDS.ARTIST;
export const CASTING_TYPE_ID = PROFILE_TYPE_IDS.CASTING;
export const AGENCY_TYPE_ID = PROFILE_TYPE_IDS.AGENT;
export const COMPANY_TYPE_ID = PROFILE_TYPE_IDS.AOS;
export const AUDIENCE_TYPE_ID = PROFILE_TYPE_IDS.AUDIENCE;
export const ACADEMICS_TYPE_ID = PROFILE_TYPE_IDS.ACADEMICS;
export const JOURNALIST_TYPE_ID = PROFILE_TYPE_IDS.JOURNALIST_MEDIA;

export const PROFESSION_IDS = {
  COMPOSER: 5,
  LIBRETTIST: 16,
  STAGE_DIRECTOR: 2,
  CONDUCTOR: 4,
  INSTRUMENTATION: 3,
  COSTUME_DESIGNER: 10,
  SINGER: 1,
};

export const WORK_ROLE_TYPE_IDS = {
  CHARACTER: 1,
  INSTRUMENTALIST: 3,
};

export const ENTITY_API_PATH = {
  [ENTITY_TYPE.ARTIST]: 'profiles',
  [ENTITY_TYPE.ORGANIZATION]: 'organizations',
  [ENTITY_TYPE.MANAGER]: 'agencies',
};

// cloudnary Image constants
const TRANSFORMATION_BASE = {
  crop: 'thumb',
  quality: 100,
};

const TRANSFORMATION_PROFILE_IMAGES = {
  gravity: 'face',
  ...TRANSFORMATION_BASE,
};

export const TRANSFORMATION_ARTIST_SUGGESTIONS_NEW = {
  width: 109,
  height: 160,
  radius: 8,
  ...TRANSFORMATION_PROFILE_IMAGES,
};

export const TRANSFORMATION_COMPANY_SUGGESTIONS_NEW = {
  width: 190,
  height: 190,
  radius: 16,
  ...TRANSFORMATION_PROFILE_IMAGES,
};

export const TRANSFORMATION_FESTIVAL_SUGGESTIONS_NEW = {
  width: 190,
  height: 190,
  radius: 16,
  ...TRANSFORMATION_PROFILE_IMAGES,
};

export const TRANSFORMATION_COMPOSERS_SUGGESTIONS_NEW = {
  width: 197,
  height: 252,
  radius: 16,
  ...TRANSFORMATION_PROFILE_IMAGES,
};

export const VIEW_MODES_VALUE = {
  PRODUCTION: 'production',
  PERFORMANCE: 'performance',
  SUMMARY: 'summary',
  DETAILED: 'detailed',
  CALENDAR: 'calendar',
  DAY: 'day',
  PRO: 'pro',
  MEDIA: 'media',
  AOS_PRO: 'aos_pro',
  CAREER_GRAPH: 'career_graph',
};

export const SNS = {
  CUETV: 'cuetv',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',
  YOUTUBE: 'youtube',
  INSTAGRAM: 'instagram',
  VK: 'vk',
  VIMEO: 'vimeo',
  WIKIPEDIA: 'wikipedia',
};

export const TAG_TYPES = {
  PRODUCTION: 'productionTag',
  WORK: 'workTag',
  DATE: 'dateTag',
  FESTIVAL: 'festivalTag',
};

export const TAGS = {
  WORLD_PREMIERE: 'WORLD_PREMIERE',
  NATIONAL_PREMIERE: 'NATIONAL_PREMIERE',
  NEW_PRODUCTION: 'NEW_PRODUCTION',
  IS_CANCELLED: 'IS_CANCELLED',
  IS_FESTIVAL: 'IS_FESTIVAL',
  IS_DOUBLE_TRIPLE_BILL: 'IS_DOUBLE_TRIPLE_BILL',
  IS_DOUBLE_BILL: 'IS_DOUBLE_BILL',
  IS_MATINEE: 'IS_MATINEE',
  IS_TOUR: 'IS_TOUR',
  WORK: 'WORK',
  REVIVAL: 'REVIVAL',
};

export const ENTITY_DETAILS_TAB = {
  GENERAL: 'featured',
  PRODUCTIONS: 'productions',
  INTRO_SHOWREEL: 'intro-showreel',
  AUDITION_SHOWREEL: 'audition-showreel',
  DIGITAL: 'watch-online',
  CALENDAR: 'calendar',
  PAST: 'past',
};

export const ENTITY_FEATURED_TABS_TITLE = {
  [ENTITY_DETAILS_TAB.GENERAL]: `${TP}.FN_FEATURED`,
  [ENTITY_DETAILS_TAB.INTRO_SHOWREEL]: `${TP}.FN_ARTIST_INTRO_SHOWREEL_SECTION`,
  [ENTITY_DETAILS_TAB.AUDITION_SHOWREEL]: `${TP}.FN_ARTIST_AUDITION_SHOWREEL_SECTION`,
  [ENTITY_DETAILS_TAB.DIGITAL]: `${TP}.FN_WATCH_ONLINE_VIDEOS`,
};

export const ENTITY_MAIN_TABS = {
  HOME: 'home',
  PERFORMANCES: 'performances',
  VIDEOS: 'videos',
  // TODO: Remove images and change to photos
  IMAGES: 'images',
  REVIEWS: 'reviews',
  REPERTOIRE: 'repertoire',
  ORG_GRAPH: 'company-graph',
  ROLE_GRAPH: 'role-graph',
  CALENDAR: 'calendar',
  BIO: 'bio',
  CONTACT: 'contact',
  WORKS: 'works',
  CAST_CREW: 'cast-crew',
  VENUES: 'venues',
  ABOUT: 'about',
  RENTALS: 'rentals',
  INSIGHTS: 'insights',
};

export const PROFILE_TYPES = {
  PRO: 'pro',
  FREE: 'free',
  FREEMIUM: 'freemium',
  CHURNED: 'churned',
  BASIC: 'basic',
};

export const ENTITY_TABS_DEFAULT_TITLES = {
  [ENTITY_MAIN_TABS.HOME]: `${TP}.OVERVIEW`,
  [ENTITY_MAIN_TABS.PERFORMANCES]: `${TP}.m_PERF`,
  [ENTITY_MAIN_TABS.VIDEOS]: `${TP}.OPERA_ONLINE`,
  [ENTITY_MAIN_TABS.IMAGES]: `${TP}.m_IMAGES`,
  [ENTITY_MAIN_TABS.REVIEWS]: `${TP}.m_ARTICLE`,
  [ENTITY_MAIN_TABS.REPERTOIRE]: `${TP}.m_LISTREPERTOIRE`,
  [ENTITY_MAIN_TABS.BIO]: `${TP}.m_BIO`,
  [ENTITY_MAIN_TABS.CONTACT]: `${TP}.m_CONTACT`,
  [ENTITY_MAIN_TABS.WORKS]: `${TP}.m_WORKS`,
  [ENTITY_MAIN_TABS.CAST_CREW]: `${TP}.FN_CREW_ENSEMBLES`,
  [ENTITY_MAIN_TABS.VENUES]: `${TP}.m_VENUES`,
  [ENTITY_MAIN_TABS.ABOUT]: `${TP}.ABOUT`,
  [ENTITY_MAIN_TABS.RENTALS]: `${TP}.FN_TAB_PRODUCTION_RENTALS`,
  [ENTITY_MAIN_TABS.INSIGHTS]: `${TP}.INSIGHTS_METRICS_TITLE`,
  [ENTITY_MAIN_TABS.CALENDAR]: `${TP}.FN_UPCOMING_PERFORMANCES`,
  [ENTITY_MAIN_TABS.ROLE_GRAPH]: `${TP}.FN_ROLE_GRAPH`,
  [ENTITY_MAIN_TABS.ORG_GRAPH]: `${TP}.FN_CAREER_GRAPH`,
};

export const ENTITY_TABS_TITLE = {
  [ENTITY_TYPE.ARTIST]: {
    ...ENTITY_TABS_DEFAULT_TITLES,
    [ENTITY_MAIN_TABS.CONTACT]: `${TP}.FN_REPRESENTATION_AND_CONTACT`,
  },
  [ENTITY_TYPE.ORGANIZATION]: ENTITY_TABS_DEFAULT_TITLES,
  [ENTITY_TYPE.PRODUCTION]: ENTITY_TABS_DEFAULT_TITLES,
  [ENTITY_TYPE.WORK]: ENTITY_TABS_DEFAULT_TITLES,
  [ENTITY_TYPE.PERFORMANCE]: ENTITY_TABS_DEFAULT_TITLES,
  [ENTITY_TYPE.MANAGER]: ENTITY_TABS_DEFAULT_TITLES,
};

export const ENTITY_VALID_TABS = {
  [ENTITY_TYPE.ARTIST]: [
    ENTITY_MAIN_TABS.HOME,
    ENTITY_MAIN_TABS.WORKS,
    ENTITY_MAIN_TABS.PERFORMANCES,
    ENTITY_MAIN_TABS.REPERTOIRE,
    ENTITY_MAIN_TABS.VIDEOS,
    ENTITY_MAIN_TABS.IMAGES,
    ENTITY_MAIN_TABS.REVIEWS,
    ENTITY_MAIN_TABS.BIO,
    ENTITY_MAIN_TABS.CONTACT,
  ],
  [ENTITY_TYPE.ORGANIZATION]: [
    ENTITY_MAIN_TABS.HOME,
    ENTITY_MAIN_TABS.WORKS,
    ENTITY_MAIN_TABS.PERFORMANCES,
    ENTITY_MAIN_TABS.VIDEOS,
    ENTITY_MAIN_TABS.IMAGES,
    ENTITY_MAIN_TABS.REVIEWS,
    ENTITY_MAIN_TABS.VENUES,
    ENTITY_MAIN_TABS.ABOUT,
    ENTITY_MAIN_TABS.CONTACT,
    ENTITY_MAIN_TABS.RENTALS,
  ],
  [ENTITY_TYPE.PRODUCTION]: [
    ENTITY_MAIN_TABS.HOME,
    ENTITY_MAIN_TABS.VIDEOS,
    ENTITY_MAIN_TABS.IMAGES,
    ENTITY_MAIN_TABS.CAST_CREW,
    ENTITY_MAIN_TABS.REVIEWS,
    ENTITY_MAIN_TABS.ABOUT,
    ENTITY_MAIN_TABS.PERFORMANCES,
  ],
  [ENTITY_TYPE.WORK]: [
    ENTITY_MAIN_TABS.HOME,
    ENTITY_MAIN_TABS.PERFORMANCES,
    ENTITY_MAIN_TABS.VIDEOS,
    ENTITY_MAIN_TABS.IMAGES,
  ],
};

export const EDIT_PROFILE_TABS = {
  schedule: {
    key: 'schedule',
    name: `${TP}.FN_TAB_SCHEDULE`,
  },
  seasons: {
    key: 'seasons',
    name: `${TP}.FN_TAB_SEASONS`,
  },
  reviews: {
    key: 'reviews',
    name: `${TP}.FN_TAB_REVIEWS`,
  },
  images: {
    key: 'photos',
    name: `${TP}.FN_TAB_PHOTOS`,
  },
  videos: {
    key: 'videos',
    name: `${TP}.FN_TAB_VIDEOS`,
  },
};

export const NOTIFICATION_LINKS = {
  schedule: {
    key: 'schedule',
    name: `${TP}.FN_TAB_SCHEDULE`,
  },
  contactInfo: {
    key: 'contact-info',
    name: `${TP}.FN_TAB_CONTACT_INFO`,
  },
  repertoire: {
    key: 'repertoire',
    name: `${TP}.FN_TAB_REPERTOIRE`,
  },
  reviews: {
    key: 'reviews',
    name: `${TP}.FN_TAB_REVIEWS`,
  },
  images: {
    key: 'photos',
    name: `${TP}.FN_TAB_PHOTOS`,
  },
  videos: {
    key: 'videos',
    name: `${TP}.FN_TAB_VIDEOS`,
  },
  redMask: {
    key: 'schedule',
    name: `${TP}.NOTIFICATION_TITLE_REDMASK_VALIDATION`,
  },
  changeRequest: {
    key: 'schedule',
    name: `${TP}.NOTIFICATION_TITLE_CHANGE_REQUEST`,
  },
  profileAccessRequests: {
    key: 'profileAccessRequests',
    name: `${TP}.NOTIFICATION_TITLE_ACCESS_REQUEST`,
  },
};

// TODO: Standarise the text case like other aggregation types and merge this list to AGGREGATION_TYPES constant
export const PROFILE_AGGREGATION_TYPES = {
  PROFESSION: 'professionId',
  PROFESSION_QUALIFICATION: 'professionQualificationId',
};

export const AGGREGATION_ON = {
  PRODUCTIONS: 'productions',
  ORGANIZATIONS: 'organizations',
  MANAGERS: 'managers',
};

export const AGGREGATION_TYPES = {
  CITY: 'city_id',
  COMPANY: 'company_id',
  FESTIVAL: 'festival_id',
  ORGANIZATION: 'organization_id',
  ORGANIZATION_TYPES: 'org_type_id',
  CREATOR: 'work_creator_id',
  WORK: 'work_id',
  VENUE: 'venue_id',
  LANGUAGE: 'language_id',
  SURTITLE: 'surtitle_id',
  SUBTITLE: 'subtitle_id',
  MONTH: 'on_month',
  PRODUCTION: 'production_id',
  COUNTRY: 'country_id',
  PROFESSION: 'profession_id',
  PROFESSION_QUALIFICATION: 'profession_qualification_id',
  WORK_ROLE: 'work_role_id',
  WORK_ROLE_REF: 'work_role_reference',
  CONDUCTOR: 'conductor_id',
  DIRECTOR: 'director_id',
  PROFILE: 'individual_id',
  YEAR: 'year_id',
  SEASON: 'season_id',
  PRODUCER: 'producer_id',
  CHORUS: 'chorus_id',
  ORCHESTRA: 'orchestra_id',
  CO_CAST: 'co_cast_id',
  GENRE: 'work_type_id',
};

export const SEASON_TYPES = {
  CALENDAR_YEAR: 'calendar_year',
  SCHOOL_YEAR: 'school_year',
};

export const SEASON_AGGREGATION_TYPES = {
  CALENDAR: {
    label: `${TP}.FN_SEASON_CALENDAR_YEAR`,
    value: SEASON_TYPES.CALENDAR_YEAR,
  },
  SCHOOL: {
    label: `${TP}.FN_SEASON_SCHOOL_YEAR`,
    value: SEASON_TYPES.SCHOOL_YEAR,
  },
};

export const WORK_CREATOR_PROFESSION_TYPES = {
  COMPOSER: 'composer',
};

export const ENTITY_AGGREGATION_TYPE_MAP = {
  [ENTITY_TYPE.ARTIST]: AGGREGATION_TYPES.PROFILE,
  [ENTITY_TYPE.ORGANIZATION]: AGGREGATION_TYPES.ORGANIZATION,
  [ENTITY_TYPE.PRODUCTION]: AGGREGATION_TYPES.PRODUCTION,
  [ENTITY_TYPE.WORK]: AGGREGATION_TYPES.WORK,
};

export const PARTNER_TYPE = {
  PRODUCER: 'producer',
  CO_PRODUCER: 'co-producer',
  GUEST: 'host',
};

export const SOCIAL_NETWORK = {
  ID: [2, 5],
  ORDER_BY: 5,
  OTHER_ID: 5,
};

export const JSON_LD_TYPES = {
  SOCIAL_PROFILE: 'SOCIAL_PROFILE',
  LOCAL_BUSINESS: 'LOCAL_BUSINESS',
  EVENT: 'EVENT',
  VIDEO: 'VIDEO',
  LIVESTREAM: 'LIVESTREAM',
  VOD: 'VOD',
  BREADCRUMB: 'BREADCRUMB',
  WEBPAGE: 'WEBPAGE',
};

export const GENDER_OPTIONS = {
  MALE: {
    label: `${TP}.FN_MALE_GENDER`,
    value: 'male',
  },
  FEMALE: {
    label: `${TP}.FN_FEMALE_GENDER`,
    value: 'female',
  },
  OTHER: {
    label: `${TP}.FN_OTHER_GENDER`,
    value: 'other',
  },
  PREFER_NOT_SAY: {
    label: `${TP}.FN_PREFER_NOT_SAY_GENDER`,
    value: 'prefer_not_to_say',
  },
};

export const DATE_FORMATS = {
  FULL_DATE_FROM_STRING: 'YYYY-MMMM-DD',
  URL_FULL_DATE: 'DD-MMMM-YYYY',
  URL_MONTH_YEAR: 'MMMM-YYYY',
  FULL_DATE: 'YYYY-MM-DD',
  DATE_LABEL: 'DD MMM YYYY',
  DATE_FULL_LABEL: 'DD MMMM YYYY',
  DATE: 'DD',
  SHORT_MONTH: 'MM',
  MONTH_ABBR: 'MMM',
  YEAR: 'YYYY',
  MONTH_YEAR: 'MMM YYYY',
  FULL_MONTH_YEAR: 'MMMM YYYY',
  DATE_WITH_SLASH: 'DD/MM/YYYY',
  DAY_MONTH: 'DD MMM',
  DATE_LABEL_WITH_DAY: 'ddd DD MMM YYYY',
  FULL_DATE_TIME: 'DD MMM, YYYY | HH:mm',
  DATE_MONTH: 'Do MMMM',
  FULL_DAY: 'dddd',
  TIME: 'HH:mm',
  MONTH_DAY_YEAR: 'MMMM D YYYY',
  DAY_SHORT_MONTH_YEAR: 'DD MMM YYYY',
};

export const ARTIST_IMPRESSION_TRACKING_EVENTS = {
  ARTIST_CARD_IMPRESSION: 'artistCardImpression',
  ARTIST_RECOMMENDATION_IMPRESSION: 'artistRecommendationImpression',
  CAST_LINK_IMPRESSION: 'castLinkImpression',
  CAST_AND_CREW_IMPRESSION: 'castAndCrewImpression',
  ARTIST_LISTING_FILTER_IMPRESSION: 'artistListingFilterImpression',
  CASTING_TOOL_ARTIST_IMPRESSION: 'castingToolArtistImpression',
  ARTIST_SLUG_IMPRESSION: 'artistSlugImpression',
};

export const COMPANY_IMPRESSION_TRACKING_EVENTS = {
  COMPANY_LISTING_IMPRESSION: 'companyListingImpression',
  COMPANY_SEARCH_FILTER_IMPRESSION: 'companySearchFilterImpression',
  COMPANY_SLUG_IMPRESSION: 'companySlugImpression',
  CASTING_TOOL_COMPANY_IMPRESSION: 'castingToolCompanyImpression',
};

export const FESTIVAL_IMPRESSION_TRACKING_EVENTS = {
  FESTIVAL_LISTING_IMPRESSION: 'festivalListingImpression',
  FESTIVAL_SEARCH_FILTER_IMPRESSION: 'festivalSearchFilterImpression',
  CASTING_TOOL_FESTIVAL_IMPRESSION: 'castingToolFestivalImpression',
  FESTIVAL_SLUG_IMPRESSION: 'festivalSlugImpression',
};

export const LSVOD_IMPRESSION_TRACKING_EVENTS = {
  LSVOD_CARD_IMPRESSION: 'lsVodCardImpression',
};

export const INSIGHTS_CLICK_TRACKING_EVENTS = {
  TICKET_CLICKED_EVENT: 'ticketClickedEvent',
  TAB_CLICKED_EVENT: 'tabClickedEvent',
  LSVOD_CARD_CLICKED_EVENT: 'lsvod_card_clicked_event',
};

export const TRACK_CASTING_TOOL_IMPRESSION = entityType => (entityType ? `${entityType}CastingToolImpression` : '');
export const TRACK_SLUG_IMPRESSION = entityType => (entityType ? `${entityType}SlugImpression` : '');
export const TRACK_SEARCH_FILTER_IMPRESSION = entityType => (entityType ? `${entityType}SearchFilterImpression` : '');
export const TRACK_CARD_IMPRESSION = entityType => (entityType ? `${entityType}CardImpression` : '');
export const TRACK_LISTING_IMPRESSION = entityType => (entityType ? `${entityType}ListingImpression` : '');
export const TRACK_HEADER_TITLE_IMPRESSION = entityType => (entityType ? `${entityType}HeaderTitleImpression` : '');

export const IMPRESSION_TRACKING_EVENTS = {
  SEARCH_RESULT_CARD_IMPRESSION: 'searchResultCardImpression',
  CASTING_TOOL_IMPRESSION: 'castingToolImpression',
  CASTING_TOOL_SEARCH_FILTER_IMPRESSION: 'castingToolSearchFilterImpression',
  ENTITY_MEDIA_IMPRESSION: 'entityMediaImpression',
};

export const RECOMMENDATION_IMPRESSION = 'RecommendationImpression';
export const STUBPAGE_IMPRESSION = 'DetailsPageViewImpression';

export const RESPONSE_STATUS_CODES = {
  SUCCESS: 200,
  PERMANENT_REDIRECT: 301,
  TEMPORARY_REDIRECT: 302,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  CONFLICT: 409,
};

export const PROFILE_UPDATES = [
  {
    type: 'schedule',
  },
  {
    type: 'reviews',
  },
  {
    type: 'images',
  },
  {
    type: 'videos',
  },
];

export const VALIDATION_REQUEST_UPDATES = [
  {
    type: 'redMask',
  },
  {
    type: 'changeRequest',
  },
];

export const ACCESS_REQUESTS = [
  {
    type: 'profileAccessRequests',
  },
];

export const PERIOD_VALUE = {
  UPCOMING: 'upcoming',
  ALL: 'all',
};

export const PROD_DURATION = [
  {
    label: `${TP}.FN_UPCOMING`,
    value: PERIOD_VALUE.UPCOMING,
  },
  {
    label: `${TP}.FN_FILTER_ALL`,
    value: PERIOD_VALUE.ALL,
  },
];

export const CONTACT_TYPES = {
  PHONE: 0,
  EMAIL: 1,
  SNS: 2,
  WEBSITE: 3,
  ADDRESS: 4,
  OTHER: 5,
};

export const MUSICAL_WORK_STARTING_YEAR = 1417;

export const MONTHS = [
  `${TP}.m_JANUARY`,
  `${TP}.m_FEBRUARY`,
  `${TP}.m_MARCH`,
  `${TP}.m_APRIL`,
  `${TP}.m_MAY`,
  `${TP}.m_JUNE`,
  `${TP}.m_JULY`,
  `${TP}.m_AUGUST`,
  `${TP}.m_SEPTEMBER`,
  `${TP}.m_OCTOBER`,
  `${TP}.m_NOVEMBER`,
  `${TP}.m_DECEMBER`,
];

export const PERFORMANCE_DATE_MODE_TYPES = {
  STAGE: 'stage',
  LIVESTREAM: 'digital-ls',
  FULL_VIDEO: 'digital-vod',
};

export const AGREEMENT_TYPES = ['opera_E', 'opera_NE', 'concert_E', 'concert_NE'];

export const AGENCY_AGREEMENT_TYPES = {
  OPERA: 'opera',
  CONCERT: 'concert',
  GENERAL_MANAGER: 'general management',
};

const PARTNERS = {
  CUETV: 'CueTv',
};

export const partnerType = {
  [PARTNERS.CUETV]: {
    id: 1,
    name: 'CueTV',
    slug: 'cuetv',
    img: '/images/source-partner-icons/cuetv.svg',
  },
};

export const VALIDATION_STATUS = {
  PENDING: 1,
  PROCESSING: 2,
  APPROVED: 3,
  REJECTED: 4,
  HIDDEN: 5,
};

export const PAGERULE_ENTITY_TYPE_MAP = {
  [ENTITY_TYPE.ARTIST]: 'profiles',
  [ENTITY_TYPE.ORGANIZATION]: 'organizations',
  [ENTITY_TYPE.MANAGER]: 'agencies',
  [ENTITY_TYPE.PRODUCTION]: 'productions',
};

export const SORT_OPTION_VALUES = {
  YEAR_ASC: 'year',
  YEAR_DESC: '-year',
  MUSICAL_WORK_ASC: 'work.name',
  MUSICAL_WORK_DESC: '-work.name',
  COMPOSERS_ASC: 'workCreator.name',
  COMPOSERS_DESC: '-workCreator.name',
  CITY_ASC: 'city',
  CITY_DESC: '-city',
  COMPANY_ASC: 'company.name',
  COMPANY_DESC: '-company.name',
  NAME_ASC: 'name',
  NAME_DESC: '-name',
  FIRST_NAME: 'first_name',
  FIRST_NAME_ASC: 'firstName',
  FIRST_NAME_DESC: '-firstName',
  LAST_NAME: 'last_name',
  LAST_NAME_ASC: 'lastName',
  LAST_NAME_DESC: '-lastName',
  PERFORMANCE_ASC: 'performance',
  PERFORMANCE_DESC: '-performance',
  ORDER_ASC: 'order',
  ORDER_DESC: '-order',
  CREATED_AT_ASC: 'createdAt',
  CREATED_AT_DESC: '-createdAt',
  CREATED_AT_ASC_V2: 'created_at',
  CREATED_AT_DESC_V2: '-created_at',
  PROD_COUNT_ASC: 'num_of_prod',
  PROD_COUNT_DESC: '-num_of_prod',
  CREATION_COUNT_ASC: 'creation_count',
  CREATION_COUNT_DESC: '-creation_count',
  CUSTOM_DATE: 'customDate',
  RANDOM: 'random',
  RELEVANCE_DESC: '-relevance',
  PROFILES_COUNT_ASC: 'num_of_profiles',
  PROFILES_COUNT_DESC: '-num_of_profiles',
  FUTURE_PRODUCTION_COUNT_ASC: 'future_production_count',
  FUTURE_PRODUCTION_COUNT_DESC: '-future_production_count',
};

export const SORT_OPTION_TYPES = {
  MUSICAL_WORK_ASC: {
    label: `${TP}.m_MUSICALWORK`,
    suffix: `${TP}.SORT_DIRECTION_ASC`,
    value: `${SORT_OPTION_VALUES.MUSICAL_WORK_ASC},${SORT_OPTION_VALUES.YEAR_DESC}`,
    alternate: 'work_id',
    icon: 'sort_white',
    radio: true,
  },
  MUSICAL_WORK_DESC: {
    label: `${TP}.m_MUSICALWORK`,
    suffix: `${TP}.SORT_DIRECTION_DESC`,
    value: `${SORT_OPTION_VALUES.MUSICAL_WORK_DESC},${SORT_OPTION_VALUES.YEAR_DESC}`,
    alternate: 'work_id',
    icon: 'sort_white',
    radio: true,
  },
  COMPOSERS_ASC: {
    label: `${TP}.m_COMPOSERS`,
    suffix: `${TP}.SORT_DIRECTION_ASC`,
    value: `${SORT_OPTION_VALUES.COMPOSERS_ASC},${SORT_OPTION_VALUES.YEAR_DESC}`,
    alternate: 'composer_id',
    icon: 'sort_white',
    radio: true,
  },
  COMPOSERS_DESC: {
    label: `${TP}.m_COMPOSERS`,
    suffix: `${TP}.SORT_DIRECTION_DESC`,
    value: `${SORT_OPTION_VALUES.COMPOSERS_DESC},${SORT_OPTION_VALUES.YEAR_DESC}`,
    alternate: 'composer_id',
    icon: 'sort_white',
    radio: true,
  },
  COMPANY_ASC: {
    label: `${TP}.FN_COMPANY`,
    suffix: `${TP}.SORT_DIRECTION_ASC`,
    value: `${SORT_OPTION_VALUES.COMPANY_ASC},${SORT_OPTION_VALUES.YEAR_DESC}`,
    icon: 'sort_white',
    radio: true,
  },
  COMPANY_DESC: {
    label: `${TP}.FN_COMPANY`,
    suffix: `${TP}.SORT_DIRECTION_DESC`,
    value: `${SORT_OPTION_VALUES.COMPANY_DESC},${SORT_OPTION_VALUES.YEAR_DESC}`,
    icon: 'sort_white',
    radio: true,
  },
  YEAR_ASC: {
    label: `${TP}.SORT_YEAR`,
    value: `${SORT_OPTION_VALUES.YEAR_ASC}`,
    icon: 'sort_white',
    radio: true,
  },
  YEAR_DESC: {
    label: `${TP}.SORT_ENDYEAR_NAME`,
    value: `${SORT_OPTION_VALUES.YEAR_DESC}`,
    icon: 'sort_white',
    radio: true,
  },
  NAME_ASC: {
    label: `${TP}.FN_SORT_NAME_ASC`,
    value: `${SORT_OPTION_VALUES.NAME_ASC}`,
    icon: 'sort_white',
    radio: true,
  },
  NAME_DESC: {
    label: `${TP}.FN_SORT_NAME_DESC`,
    value: `${SORT_OPTION_VALUES.NAME_DESC}`,
    icon: 'sort_white',
    radio: true,
  },
  LAST_NAME_ASC: {
    label: `${TP}.FN_SORT_NAME_ASC`,
    value: `${SORT_OPTION_VALUES.LAST_NAME_ASC}`,
    icon: 'sort_white',
    radio: true,
  },
  LAST_NAME_DESC: {
    label: `${TP}.FN_SORT_NAME_DESC`,
    value: `${SORT_OPTION_VALUES.LAST_NAME_DESC}`,
    icon: 'sort_white',
    radio: true,
  },
  SINCE_YEAR_ASC: year => ({
    label: `${TP}.FN_SINCE_YEAR_ASC`,
    value: `${SORT_OPTION_VALUES.YEAR_ASC}`,
    icon: 'sort_white',
    labelProp: { year },
    radio: true,
  }),
  SINCE_YEAR_DESC: year => ({
    label: `${TP}.FN_SINCE_YEAR_DESC`,
    value: `${SORT_OPTION_VALUES.YEAR_DESC}`,
    icon: 'sort_white',
    labelProp: { year },
    radio: true,
  }),
  CITY_ASC: {
    label: `${TP}.m_CITY`,
    value: `${SORT_OPTION_VALUES.CITY_ASC}`,
    icon: 'sort_white',
    radio: true,
  },
  CITY_DESC: {
    label: `${TP}.m_CITY`,
    value: `${SORT_OPTION_VALUES.CITY_DESC}`,
    icon: 'sort_white',
    radio: true,
  },
  PROD_COUNT_ASC: {
    label: `${TP}.FN_SORT_PROD_COUNT_ASC`,
    value: `${SORT_OPTION_VALUES.PROD_COUNT_ASC}`,
    icon: 'sort_white',
    radio: true,
  },
  PROD_COUNT_DESC: {
    label: `${TP}.FN_SORT_PROD_COUNT_DESC`,
    value: `${SORT_OPTION_VALUES.PROD_COUNT_DESC}`,
    icon: 'sort_white',
    radio: true,
  },
};

export const UPCOMING_PRODUCTIONS_MEDIA_SORT_ORDER = `${SORT_OPTION_VALUES.PERFORMANCE_ASC},${SORT_OPTION_VALUES.ORDER_ASC},${SORT_OPTION_VALUES.CREATED_AT_DESC}`;
export const PAST_PRODUCTIONS_MEDIA_SORT_ORDER = `${SORT_OPTION_VALUES.PERFORMANCE_DESC},${SORT_OPTION_VALUES.ORDER_ASC},${SORT_OPTION_VALUES.CREATED_AT_DESC}`;

export const PRIVACY_ATTRIBUTE_IDS = {
  PRIVATE: 1,
  PUBLIC: 2,
  FREE_AND_PRO: 4,
  CASTING_TOOL: 5,
  ARTIST: 6,
  AGENCY: 7,
  AOS: 8,
};

export const PRIVACY_ATTRIBUTE_PROFILE_TYPES = {
  [PROFILE_TYPE_IDS.ARTIST]: PRIVACY_ATTRIBUTE_IDS.ARTIST,
  [PROFILE_TYPE_IDS.AGENT]: PRIVACY_ATTRIBUTE_IDS.AGENCY,
  [PROFILE_TYPE_IDS.AOS]: PRIVACY_ATTRIBUTE_IDS.AOS,
};

export const PRODUCTION_ADDED_IDENTIFIERS_IDS = {
  UNKNOWN: 1,
  ADMIN: 2,
  COMPANY: 3,
  ARTIST: 4,
  AGENCY: 5,
  FESTIVAL: 6,
  ORGANIZATION: 7,
};

export const CONTRIBUTORS = {
  CAST: 'cast',
  CREW: 'crew',
  PRODUCER: 'producer',
  CO_PRODUCER: 'co-producer',
  ENSEMBLE: 'ensemble',
  OTHER: 'other',
  INSTRUMENTALIST: 'instrumentalist',
  OTHER_INSTRUMENTALIST: 'other_instrumentalist',
};

export const WORK_ROLE_TYPES = {
  CAST: 'cast',
  CREW: 'crew',
  OTHER: 'other',
  INSTRUMENTALIST: 'instrumentalist',
  OTHER_INSTRUMENTALIST: 'other_instrumentalist',
};

export const PRODUCTION_VALIDITY_IDENTIFIERS = {
  NONE: 'none',
  ARTIST: 'artist',
  ADMIN: 'admin',
  ORGANIZATION: 'organization',
  ADMIN_AND_ORG: 'admin,organization', // NOTE: This is a most used case, hence special case
};

export const CONTACT_TYPE = {
  PHONE: 'phone-number',
  WEBSITE: 'website',
  EMAIL: 'email',
  WORK_EMAIL: 'work_email',
  SNS: 'sns',
  ADDRESS: 'address',
  OTHER: 'other',
};

export const CONTACT_ICON = {
  [CONTACT_TYPE.PHONE]: 'phone',
  [CONTACT_TYPE.WEBSITE]: 'website',
  [CONTACT_TYPE.EMAIL]: 'email',
  [CONTACT_TYPE.ADDRESS]: 'address',
};

export const CONTACT_LABEL = {
  [CONTACT_TYPE.PHONE]: `${TP}.m_REGISTRATION_PHONENUMBER`,
  [CONTACT_TYPE.WEBSITE]: `${TP}.m_WEBSITE`,
  [CONTACT_TYPE.EMAIL]: `${TP}.m_EMAIL`,
  [CONTACT_TYPE.ADDRESS]: `${TP}.m_ADDRESS`,
};

export const REGEX_CONSTANTS = {
  CUETV_URL: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/).*cuetv\.online.*/g,
  DOMAIN_REGEX: '^(http://|https://|)(.*.|)DOMAIN_NAME(/|$)',
  VALID_URL_REGEX: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9-]+([-\\.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/g,
};

export const VIDEO_ATTRIBUTE_SLUGS = {
  LIVESTREAM: 'live-stream',
  VIDEO_ON_DEMAND: 'on-demand',
  PROFILE_BANNER_IMAGE: 'profile-banner-image',
  ENTITY_ALL_IMAGE: 'entity-all-images',
  ENTITY_ALL_PRODUCTION_IMAGE: 'entity-production-images',
  TRAILER: 'trailer',
  VIDEO_TYPE: 'video-type',
  ABOUT_ORGANIZATION: 'about-organization',
};

export const profileType = {
  PRO: 'pro',
  FREE: 'free',
  CHURNED: 'churned',
  BASIC: 'basic',
};

export const STRIPE_IDENTITY_STATUS = {
  VERIFIED: 'verified',
};

export const PRODUCTS_IDS = {
  ARTIST_FREE_PLAN: 75,
};

export const SUBSCRIPTION_SOURCE = {
  AGENCY: 'Agency Roster',
  MANUAL: 'Manual Subscription',
  OTHER: 'Other',
  OFFER: 'Special Offer',
  HUBSPOT: 'Hubspot Deal',
  SUPPORT: 'Customer Support',
  REGISTRATION: 'Registration',
};

export const ACCESS_TYPE = {
  PROFILE: 'profile',
  AGENCY: 'agency',
  ORGANIZATION: 'organization',
};

export const SCROLL_DIRECTION = {
  UP: 'UP',
  DOWN: 'DOWN',
};

export const PAYWALL_TYPES = {
  HARD_WALL: 'hard_wall',
  SOFT_WALL: 'soft_wall',
  ARCHIVED_WALL: 'archived_wall',
};

export const SUBSCRIPTION_PRICE = {
  AGENCY: {
    ANNUALLY: '€ 850',
    MONTHLY: '€ 70.80',
  },
  CASTING_TOOL: {
    ANNUALLY: '€ 1500',
    MONTHLY: '€ 125',
  },
  ARTIST: {
    ANNUALLY: '€ 10.00',
    MONTHLY: '€ 15.00',
  },
};

export const RED_MASK_STATUS = {
  APPROVED: 'approved',
};

export const SUBSCRIPTION_STATUSES = {
  ACTIVE: 'active',
  RENEWING: 'renewing',
  GRACE: 'grace',
  EXPIRED: 'expired',
  CANCELLED: 'cancelled',
  DRAFT: 'draft',
  PENDINGENDING: 'pendingending',
  ENDED: 'ended',
  HOLD: 'hold',
  PROCESSING: 'processing',
};

export const INSIGHTS_TYPES = {
  VIEW_IMPRESSIONS: `UP_VIEW_IMPRESSIONS`,
  IMPRESSIONS: `${TP}.TAB_INSIGHTS_IMPRESSIONS`,
  INCREASE_IMPRESSIONS: `UP_INCREASE_IMPRESSIONS`,
  IMPRESSIONS_BY_CITY: `UP_VIEW_IMPRESSIONS_BY_CITY`,
  IMPRESSIONS_BY_COUNTRY: `UP_VIEW_IMPRESSIONS_BY_COUNTRY`,
  ACCESS_PAGE_VIEWS_BY_CITY: `UP_ACCESS_PAGE_VIEWS_BY_CITY`,
  ACCESS_PAGE_VIEWS_BY_COUNTRY: `UP_ACCESS_PAGE_VIEWS_BY_COUNTRY`,
  ACCESS_PAGE_VIEWS: 'UP_ACCESS_PAGE_VIEWS',
  INCREASE_PAGE_VIEWS: `UP_INCREASE_PAGE_VIEWS`,
  ADVANCED_INSIGHTS: 'UP_VIEW_ADVANCED_INSIGHTS',
  PAGEVIEWS: 'PAGE_VIEWS',
  SEARCH_APPEARANCES_BY_CITY: `UP_VIEW_SEARCH_APPEARANCES_BY_CITY`,
  SEARCH_APPEARANCES_BY_COUNTRY: `UP_VIEW_SEARCH_APPEARANCES_BY_COUNTRY`,
  VIEW_SEARCH_APPEARANCES: `UP_VIEW_SEARCH_APPEARANCES`,
  INCREASE_SEARCH_APPEARANCES: `UP_INCREASE_SEARCH_APPEARANCES`,
  SEARCH_APPEARANCES: `${TP}.TAB_INSIGHTS_SEARCH_APPEARANCES`,
};

export const POPUP_SOURCES = {
  SHOW_AND_HIDE: 'showAndHide',
  SHOW_AND_HIDE_PHOTOS: 'showAndHidePhotos',
  SHOW_AND_HIDE_VIDEOS: 'showAndHideVideos',
  SHOW_AND_HIDE_REVIEWS: 'showAndHideReviews',
  SHOW_AND_HIDE_PERFORMANCES: 'showAndHidePerformances',
  SHOW_HIDE_OTHER_ARTIST_FROM_TOP_SECTION: 'showHideOtherArtistFromTopSection',
  SHOW_HIDE_REPERTOIRE_ON_PUBLIC_OVERVIEW_PAGE: 'showHideRepertoireOnPublicOverviewPage',
  SHOW_HIDE_ARTIST_YOU_HAVE_WORKED_WITH_ON_PUBLIC_SITE: 'showHideArtistYouHaveWorkedWithOnPublicSite',
  SHOW_HIDE_ORG_YOU_HAVE_WORKED_WITH_ON_PUBLIC_SITE: 'showHideOrgYouHaveWorkedWithOnPublicSite',
  SHOW_HIDE_VENUES_YOU_HAVE_WORKED_WITH_ON_PUBLIC_SITE: 'showHideVenuesYouHaveWorkedWithOnPublicSite',
  SHOW_HIDE_AGENCY_INFO_ON_PUBLIC_SITE: 'showHideAgencyInfoOnPublicSite',
  SHOW_HIDE_AGENCY_INFO_ON_CASTING_TOOL: 'showHideAgencyInfoOnCastingTool',
  SHOW_HIDE_FUTURE_REPERTOIRE_ON_PUBLIC_SITE: 'showHideFutureRepertoireOnPublicSite',
  SHOW_HIDE_FUTURE_REPERTOIRE_ON_CASTING_TOOL: 'showHideFutureRepertoireOnCastingTool',
  SHOW_HIDE_REPERTOIRE_ON_CASTING_TOOL: 'showHideRepertoireOnCastingTool',
  SHOW_HIDE_REPERTOIRE_ON_PUBLIC_SITE: 'showHideRepertoireOnPublicSite',
  SHOW_HIDE_CONTACT_INFORMATION_ON_CASTING_TOOL: 'showHideContactInformationOnCastingTool',
  SHOW_HIDE_CONTACT_INFORMATION_ON_PUBLIC_SITE: 'showHideContactInformationOnPublicSite',
  DEFAULT_TRIAL: 'defaultTrial',
  SEARCH_APPEARANCES_INSIGHTS: 'searchAppearancesInsights',
  PAGE_VIEW_INSIGHTS: 'pageViewInsights',
  ARTIST_COLLABORATION: 'artistCollaboration',
  FUTURE_REPERTOIRE: 'futureRepertoire',
  ORGANIZATION: 'organization',
  VENUES_PERFORMED: 'venuesPerformed',
  ARTIST_SUGGESTIONS: 'artistSuggestions',
  REPERTOIRE: 'repertoire',
  REDMASK_REQUEST: 'redMaskRequest',
  IMPRESSIONS: 'impressions',
  SUGGESTED_ARTIST: 'suggestedArtist',
  DISCOVER_ARTIST: 'discoverArtist',
  FEATURE_REVIEW: 'featureReview',
  [INSIGHTS_TYPES.ADVANCED_INSIGHTS]: 'advancedInsights',
  [INSIGHTS_TYPES.IMPRESSIONS]: 'impressions',
  [INSIGHTS_TYPES.VIEW_IMPRESSIONS]: 'viewImpressions',
  [INSIGHTS_TYPES.INCREASE_IMPRESSIONS]: 'increaseImpressions',
  [INSIGHTS_TYPES.IMPRESSIONS_BY_CITY]: 'impressionsByCity',
  [INSIGHTS_TYPES.IMPRESSIONS_BY_COUNTRY]: 'impressionsByCountry',
  [INSIGHTS_TYPES.ACCESS_PAGE_VIEWS]: 'accessPageViews',
  [INSIGHTS_TYPES.INCREASE_PAGE_VIEWS]: 'increasePageViews',
  [INSIGHTS_TYPES.ACCESS_PAGE_VIEWS_BY_CITY]: 'accessPageViewsByCity',
  [INSIGHTS_TYPES.ACCESS_PAGE_VIEWS_BY_COUNTRY]: 'accessPageViewsByCountry',
  [INSIGHTS_TYPES.SEARCH_APPEARANCES]: 'searchAppearances',
  [INSIGHTS_TYPES.INCREASE_SEARCH_APPEARANCES]: 'increaseSearchAppearances',
  [INSIGHTS_TYPES.VIEW_SEARCH_APPEARANCES]: 'viewSearchAppearances',
  [INSIGHTS_TYPES.SEARCH_APPEARANCES_BY_CITY]: 'searchAppearancesByCity',
  [INSIGHTS_TYPES.SEARCH_APPEARANCES_BY_COUNTRY]: 'searchAppearancesByCountry',
};

export const POPUP_SOURCES_LABELS = {
  [POPUP_SOURCES.REPERTOIRE]: 'UP_ACTION_ADD_FUTURE_REPERTOIRE',
  [POPUP_SOURCES.SHOW_AND_HIDE]: '',
  [POPUP_SOURCES.REDMASK_REQUEST]: 'UP_ACTION_REQUEST_RED_MASK',
  [POPUP_SOURCES.SHOW_AND_HIDE_PHOTOS]: 'UP_ACTION_SH_PHOTOS',
  [POPUP_SOURCES.SHOW_AND_HIDE_VIDEOS]: 'UP_ACTION_SH_VIDEOS',
  [POPUP_SOURCES.SHOW_AND_HIDE_REVIEWS]: 'UP_ACTION_SH_REVIEWS',
  [POPUP_SOURCES.SHOW_AND_HIDE_PERFORMANCES]: 'UP_ACTION_SH_PERFORMANCES',
  [POPUP_SOURCES.SHOW_HIDE_OTHER_ARTIST_FROM_TOP_SECTION]: 'UP_ACTION_EXPLORE_ARTIST_FROM_TOP_SECTION',
  [POPUP_SOURCES.SHOW_HIDE_REPERTOIRE_ON_PUBLIC_OVERVIEW_PAGE]: 'UP_ACTION_SHOW_REPERTOIRE_ON_PUBLIC_OVERVIEW_PAGE',
  [POPUP_SOURCES.SHOW_HIDE_ARTIST_YOU_HAVE_WORKED_WITH_ON_PUBLIC_SITE]:
    'UP_ACTION_SHOW_HIDE_ARTIST_YOU_HAVE_WORKED_WITH_ON_PUBLIC_SITE',
  [POPUP_SOURCES.SHOW_HIDE_ORG_YOU_HAVE_WORKED_WITH_ON_PUBLIC_SITE]:
    'UP_ACTION_SHOW_HIDE_ORG_YOU_HAVE_WORKED_WITH_ON_PUBLIC_SITE',
  [POPUP_SOURCES.SHOW_HIDE_VENUES_YOU_HAVE_WORKED_WITH_ON_PUBLIC_SITE]:
    'UP_ACTION_SHOW_HIDE_VENUES_YOU_HAVE_WORKED_WITH_ON_PUBLIC_SITE',
  [POPUP_SOURCES.SHOW_HIDE_AGENCY_INFO_ON_PUBLIC_SITE]: 'UP_ACTION_SHOW_HIDE_AGENCY_INFO_ON_PUBLIC_SITE',
  [POPUP_SOURCES.SHOW_HIDE_AGENCY_INFO_ON_CASTING_TOOL]: 'UP_ACTION_SHOW_HIDE_AGENCY_INFO_ON_CASTING_TOOL',
  [POPUP_SOURCES.SHOW_HIDE_FUTURE_REPERTOIRE_ON_PUBLIC_SITE]: 'UP_ACTION_SHOW_HIDE_FUTURE_REPERTOIRE_ON_PUBLIC_SITE',
  [POPUP_SOURCES.SHOW_HIDE_FUTURE_REPERTOIRE_ON_CASTING_TOOL]: 'UP_ACTION_SHOW_HIDE_FUTURE_REPERTOIRE_ON_CASTING_TOOL',
  [POPUP_SOURCES.SHOW_HIDE_REPERTOIRE_ON_CASTING_TOOL]: 'UP_ACTION_SHOW_HIDE_REPERTOIRE_ON_CASTING_TOOL',
  [POPUP_SOURCES.SHOW_HIDE_REPERTOIRE_ON_PUBLIC_SITE]: 'UP_ACTION_SHOW_HIDE_REPERTOIRE_ON_PUBLIC_SITE',
  [POPUP_SOURCES.SHOW_HIDE_CONTACT_INFORMATION_ON_CASTING_TOOL]: 'UP_ACTION_SHOW_HIDE_CONTACT_INFO_ON_CASTING_TOOL',
  [POPUP_SOURCES.SHOW_HIDE_CONTACT_INFORMATION_ON_PUBLIC_SITE]: 'UP_ACTION_SHOW_HIDE_CONTACT_INFO_ON_PUBLIC_SITE',

  [POPUP_SOURCES[INSIGHTS_TYPES.ADVANCED_INSIGHTS]]: 'UP_VIEW_ADVANCED_INSIGHTS',
  [POPUP_SOURCES[INSIGHTS_TYPES.VIEW_IMPRESSIONS]]: 'UP_VIEW_IMPRESSIONS',
  [POPUP_SOURCES[INSIGHTS_TYPES.INCREASE_IMPRESSIONS]]: 'UP_INCREASE_IMPRESSIONS',
  [POPUP_SOURCES[INSIGHTS_TYPES.IMPRESSIONS_BY_CITY]]: 'UP_VIEW_IMPRESSIONS_BY_CITY',
  [POPUP_SOURCES[INSIGHTS_TYPES.IMPRESSIONS_BY_COUNTRY]]: 'UP_VIEW_IMPRESSIONS_BY_COUNTRY',
  [POPUP_SOURCES[INSIGHTS_TYPES.INCREASE_PAGE_VIEWS]]: 'UP_INCREASE_PAGE_VIEWS',
  [POPUP_SOURCES[INSIGHTS_TYPES.ACCESS_PAGE_VIEWS]]: 'UP_ACCESS_PAGE_VIEWS',
  [POPUP_SOURCES[INSIGHTS_TYPES.ACCESS_PAGE_VIEWS_BY_CITY]]: 'UP_ACCESS_PAGE_VIEWS_BY_CITY',
  [POPUP_SOURCES[INSIGHTS_TYPES.ACCESS_PAGE_VIEWS_BY_COUNTRY]]: 'UP_ACCESS_PAGE_VIEWS_BY_COUNTRY',
  [POPUP_SOURCES[INSIGHTS_TYPES.INCREASE_SEARCH_APPEARANCES]]: 'UP_INCREASE_SEARCH_APPEARANCES',
  [POPUP_SOURCES[INSIGHTS_TYPES.VIEW_SEARCH_APPEARANCES]]: 'UP_VIEW_SEARCH_APPEARANCES',
  [POPUP_SOURCES[INSIGHTS_TYPES.SEARCH_APPEARANCES_BY_CITY]]: 'UP_VIEW_SEARCH_APPEARANCES_BY_CITY',
  [POPUP_SOURCES[INSIGHTS_TYPES.SEARCH_APPEARANCES_BY_COUNTRY]]: 'UP_VIEW_SEARCH_APPEARANCES_BY_COUNTRY',
};

export const POPUP_TYPES = {
  CHURNED: 'churned',
  UPGRADE: 'upgrade',
  TRIAL: 'trial',
};

export const ENSEMBLE_RENDER_ORDER = [
  {
    type: 'orchestra',
    label: `${TP}.m_FN_ORCHESTRA`,
  },
  {
    type: 'chorus',
    label: `${TP}.m_FN_CHORUS`,
  },
  {
    type: 'chamber-ensemble',
    label: `${TP}.FN_CHAMBER_ENSEMBLE`,
  },
  {
    type: 'ballet',
    label: `${TP}.FN_BALLET_OR_GROUP`,
  },
  {
    type: 'extras',
    label: `${TP}.ENSEMBLE_EXTRA`,
  },
  {
    type: 'others',
    label: `${TP}.FN_CASTING_TOOL_OTHER_ROLES`,
  },
];

export const PERFORMANCE_TYPES = {
  UPCOMING: 'upcoming',
  PAST: 'past',
};

export const CHART_TYPES = {
  COMBO: 'combo',
  BAR: 'bar',
};

export const PREFERRED_ENTITY_MAP = { [ENTITY_TYPE.ARTIST]: 1, [ENTITY_TYPE.VENUE]: 3, [ENTITY_TYPE.ORGANIZATION]: 12 };

export const ARTIST_CUSTOMIZE = {
  ARTIST: 'ARTISTS_WORKED_WITH',
  ORGANIZATION: 'ORGANIZATIONS_WORKED_WITH',
  VENUE: 'VENUES_PERFORMED',
  REPERTOIRE: 'SHOW_REPERTOIRE',
  FUTURE_REPERTOIRE: 'SHOW_FUTURE_REPERTOIRE',
  AGENCY: 'SHOW_AGENCY_INFO',
  SUGGESTED: 'SUGGESTED_ENTITIES',
  SHOWREEL: 'DISPLAY_INTRO_SHOWREEL',
  ARTIST_CONTACT: 'SHOW_CONTACT_INFO',
};

export const MEDIA_SHOWREEL_OPTIONS = {
  INTRO: {
    id: 1,
    slug: 'intro-show-reel',
  },
  AUDITION: {
    id: 2,
    slug: 'audition-show-reel',
  },
};

export const FEATURED_ARTIST_ENTITIES_SECTIONS = {
  [ENTITY_TYPE.ARTIST]: {
    title: `${TP}.FN_ARTIST_YOU_WORKED_WITH_TITLE`,
    displayTitle: 'ARTIST_WORKED_WITH_TITLE',
    description: `${TP}.FN_SECTION_SHOW_PUBLIC_PROFILE_INFO`,
    entityType: ENTITY_TYPE.ARTIST,
    key: ARTIST_CUSTOMIZE[ENTITY_TYPE.ARTIST],
    modalSubText: `${TP}.FN_SELECT_ARTIST_WORKED_WITH`,
    placeholder: `${TP}.FN_SEARCH_ARTIST_PRODUCTION_ASSOCIATED_WITH`,
    suggestedText: `${TP}.FN_SUGGESTED_ARTISTS`,
    warningText: `${TP}.m_ARTISTS`,
    emptyDescription: `${TP}.FN_EMPTY_ARTIST_DESCRIPTION_TEXT`,
    nudgeType: POPUP_SOURCES.ARTIST_COLLABORATION,
  },
  [ENTITY_TYPE.ORGANIZATION]: {
    title: `${TP}.FN_ORG_YOU_WORKED_WITH_TITLE`,
    displayTitle: 'ORG_HAS_COLLABORATED_WITH_TITLE',
    description: `${TP}.FN_SECTION_SHOW_PUBLIC_PROFILE_INFO`,
    entityType: ENTITY_TYPE.ORGANIZATION,
    key: ARTIST_CUSTOMIZE[ENTITY_TYPE.ORGANIZATION],
    modalSubText: `${TP}.FN_SELECT_ORGANIZATION_WORKED_WITH`,
    placeholder: `${TP}.FN_SEARCH_ORGANIZATION_PRODUCTION_ASSOCIATED_WITH`,
    suggestedText: `${TP}.FN_SUGGESTED_ORGANIZATION`,
    warningText: `${TP}.FN_ORGANIZATIONS`,
    emptyDescription: `${TP}.FN_EMPTY_ORG_DESCRIPTION_TEXT`,
    nudgeType: POPUP_SOURCES.ORGANIZATION,
  },
  [ENTITY_TYPE.VENUE]: {
    title: `${TP}.FN_VENUES_YOU_HAVE_PERFORMED_WITH`,
    displayTitle: 'VENUES_PERFORMED_AT_TITLE',
    description: `${TP}.FN_SECTION_SHOW_PUBLIC_PROFILE_INFO`,
    entityType: ENTITY_TYPE.VENUE,
    key: ARTIST_CUSTOMIZE[ENTITY_TYPE.VENUE],
    modalSubText: `${TP}.FN_SELECT_VENUE_PERFORMED_WITH`,
    placeholder: `${TP}.FN_SEARCH_VENUE_PRODUCTION_ASSOCIATED_WITH`,
    suggestedText: `${TP}.FN_SUGGESTED_VENUES`,
    warningText: `${TP}.m_VENUES`,
    emptyDescription: `${TP}.FN_EMPTY_VENUE_DESCRIPTION_TEXT`,
    nudgeType: POPUP_SOURCES.VENUES_PERFORMED,
  },
};

export const METRIC_TYPES = {
  IMPRESSIONS_TIMESERIES: 'impressions_timeseries',
  IMPRESSIONS_COUNTRY_CITY_DISTRIBUTION: 'impressions_country_city_distribution',
  PAGEVIEWS_TIMESERIES: 'pageviews_timeseries',
  PAGEVIEWS_COUNTRY_CITY_DISTRIBUTION: 'pageviews_country_city_distribution',
  SEARCH_APPEARANCES_TIMESERIES: 'search_appearances_timeseries',
  SEARCH_APPEARANCES_COUNTRY_CITY_DISTRIBUTION: 'search_appearances_country_city_distribution',
};

export const MEDIA_TYPES_IDS = {
  IMAGE: 1,
  VIDEO: 2,
  AUDIO: 3,
  LS: 4,
  VOD: 5,
};

export const LOGIN_SOURCE = {
  RETURNING_USER: 'returningUser',
};

export const REPERTOIRE_SECTION_TYPES = {
  FUTURE: 'future',
  PAST: 'past',
};

export const ENGAGEMENT_BANNER_EMPTY_SECONDARY_TEXT = {
  [ENTITY_TYPE.ARTIST]: 'CL_EMPTY_ARTIST_SECONDARY_TEXT',
  [ENTITY_TYPE.ORGANIZATION]: 'CL_EMPTY_ORG_SECONDARY_TEXT',
  [ENTITY_TYPE.VENUE]: 'CL_EMPTY_VENUE_SECONDARY_TEXT',
};

export const USER_PRODUCTIONS_SOURCES = {
  REGISTRATION: 'registration',
  CHECKLIST: 'checklist',
  EDIT: 'edit',
};

export const VALID_AGENCY_STATUSES = ['agent', 'admin'];

export const PROFILE_ACCESS_REQUEST_STATUSES = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  PENDING: 'pending',
};

export const PREMIUM_NUDGE_VIEW_TYPES = {
  CONCISE: 'concise',
  DEFAULT: 'dotted',
  INLINE: 'inline',
  DARK: 'dark',
  INLINE_WITH_BORDER: 'inlineWithBorder',
};

export const THEMES = {
  DARK: 'dark',
};

export const PREMIUM_NUDGE_POSITION = {
  MIDDLE: 'middle',
  AFTER: 'after',
  TOP: 'top',
};

export const EMPTY_PLACEHOLDER_TYPES = {
  BUTTON: 'button',
  CARD: 'card',
};

export const REPERTOIRE_STATUSES = {
  PREPARED: 'prepared',
  UNPREPARED: 'unprepared',
  RETIRE: 'retire',
};

export const TABS_TO_SHOW = {
  LANGUAGE_SELECTOR: 'languageSelector',
  PROFILE_SELECTOR: 'profileSelector',
  NOTIFICATIONS: 'notifications',
};

export const ENTITY_DISPLAY_STATUS = {
  SHOW: 1,
  HIDE: 2,
  REJECTED: 3,
};

export const CONTACTS_NAMES = {
  ADDRESS: 'address',
  EMAIL: 'email',
  PHONE: 'phone',
  POC_EMAIL: 'point_of_contact_email',
  POC_PHONE: 'point_of_contact_phone',
  BOX_OFFICE: 'box_office',
  WORK_EMAIL: 'work_email',
  WORK_PHONE: 'work_phone',
  WEBSITE: 'website',
  OTHER: 'other',
  SNS: 'sns',
  BOX_OFFICE_EMAIL: 'box_office_email',
  BOX_OFFICE_PHONE: 'box_office_phone',
  BOX_OFFICE_WEBSITE: 'box_office_website',
};

export const PROFILE_DOCUMENT_TYPES = {
  CV: 'CV',
};

export const PAGINATION_LIMITS = {
  ORGANIZATION_INDEX_PAGE_LIST: 20,
};
