import { getLanguage } from 'utils/queriesUtil';

import * as API from 'utils/API';

export const DEFAULT_QUERY_CONFIG = {
  retry: false,
  retryOnMount: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 10 * 60 * 1000,
  getNextPageParam: lastPage => {
    const { limit, page, total } = lastPage;
    const totalFetched = limit * page;

    return totalFetched < total ? page + 1 : undefined;
  },
  getPreviousPageParam: () => false,
};

export const transformQueryFn = (queryFn, ...transformFns) => (...args) =>
  queryFn(...args).then(response => {
    if (transformFns?.length) {
      return transformFns.reduce((transformedResponse, fn) => fn(transformedResponse, ...args), response);
    }

    return response;
  });

export const withSelectFn = (selectFn = response => response) => (props = {}) => ({
  ...props,
  queryConfig: {
    ...props?.queryConfig,
    select: response => {
      const transformedResponse = selectFn(response);
      const customSelector = props?.queryConfig?.select;

      if (customSelector) {
        return customSelector(transformedResponse);
      }

      return transformedResponse;
    },
  },
});

// TODO: Deprecate this function
export const getQueryKey = (name, { language, entityType, entityId, limit }, extras = []) => {
  const queryKey = [getLanguage(language), name, `${entityType || ''}`, `${entityId || ''}`];

  if (limit) {
    queryKey.push(`${limit}`);
  }

  if (extras?.length) {
    queryKey.push(extras);
  }

  return {
    queryKey,
    getUpdatedQueryKey: ({ limit: newLimit = limit, extras: newExtras = extras }) =>
      getQueryKey(name, { entityType, entityId, limit: newLimit }, newExtras),
  };
};

const cleanObjectKeys = obj => {
  const newObj = {};

  Object.keys(obj || {}).forEach(key => {
    if (obj?.[key]) {
      let value = obj[key];

      if (typeof value === 'number') {
        value = value.toString();
      } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        value = cleanObjectKeys(value);
      }

      newObj[key] = value;
    }
  });

  return newObj;
};

const evaluateEndpoint = (endpoint, params = {}) => {
  if (typeof endpoint === 'function') {
    return endpoint(params);
  }

  return endpoint;
};

export const postQuery = endpoint => config => ({
  ...(config || {}),
  mutationFn: ({ data, files, queryParams, endpointParams } = {}) =>
    API.postRequest(evaluateEndpoint(endpoint, endpointParams), { queryParams, data, files }),
});

export const updateQuery = endpoint => config => ({
  ...(config || {}),
  mutationFn: ({ id, data, files, queryParams, endpointParams } = {}) =>
    API.updateRequest(evaluateEndpoint(endpoint, endpointParams), { id, queryParams, data, files }),
});

export const deleteQuery = endpoint => config => ({
  ...(config || {}),
  mutationFn: ({ id, queryParams, endpointParams } = {}) =>
    API.deleteRequest(evaluateEndpoint(endpoint, endpointParams), { id, queryParams }),
});

// NOTE: Avoid using transformFn, it's not a good pattern - do transformation in the component unless transformation is needed in multiple places
export const getQuery = (name, queryFn, transformFn) => (props, cookies, language) => {
  const transformedProps = typeof transformFn === 'function' ? transformFn(props) : props;
  const { queryConfig, extraQueryKeys = [], ...apiProps } = transformedProps || {};
  const {
    type,
    showApprovedHidden,
    asEdit,
    aggregationType,
    aggregationOn,
    entity,
    entityType,
    entityId,
    id,
    ids,
    limit,
    distinctLimit,
    mediaLimit,
    filters,
    sort,
    page,
  } = apiProps || {};

  const baseKey = [getLanguage(language), name];

  if (entityType) {
    baseKey.push(`${entityType}`);
  }

  if (type && typeof type === 'string') {
    baseKey.push(type);
  }

  if (aggregationType && typeof aggregationType === 'string') {
    baseKey.push(aggregationType);
  }

  if (aggregationOn && typeof aggregationOn === 'string') {
    baseKey.push(aggregationOn);
  }

  if (entityId) {
    baseKey.push(`${entityId}`);
  }

  if (entity?.id) {
    baseKey.push(`${entity?.id}`);
  }

  if (id) {
    baseKey.push(`${id}`);
  }

  if (ids) {
    baseKey.push(ids);
  }

  if (limit) {
    baseKey.push(`${limit}`);
  }

  if (page) {
    baseKey.push(`${page}`);
  }

  if (distinctLimit) {
    baseKey.push(`${distinctLimit}`);
  }

  if (mediaLimit) {
    baseKey.push(`${mediaLimit}`);
  }

  if (showApprovedHidden) {
    baseKey.push('APPROVED_HIDDEN');
  }

  if (asEdit) {
    baseKey.push('EDIT_REQUEST');
  }

  if (Object.keys(filters || {})?.length > 0) {
    baseKey.push(cleanObjectKeys(filters));
  }

  if (sort) {
    baseKey.push(sort);
  }

  const config = { ...(queryConfig || {}) };
  if (Object.prototype.hasOwnProperty.call(config, 'enabled') && typeof config?.enabled === 'undefined') {
    config.enabled = false;
  }

  return {
    queryKey: [...baseKey, ...extraQueryKeys],
    queryFn: async ({ pageParam } = {}) => queryFn({ ...apiProps, page: pageParam || page || 1 }, cookies),
    ...DEFAULT_QUERY_CONFIG,
    ...config,
  };
};

export const getProductionGroupedList = (list, limitPerGroup) => {
  const productionMap = list.reduce((acc, item) => {
    const productionId = item?.production?.id;
    if (productionId) {
      if (!acc.get(productionId)) {
        acc.set(productionId, []);
      }
      acc.get(productionId).push(item);
    }
    return acc;
  }, new Map());

  return Object.values([...productionMap.values()]).map(data => ({
    production: data?.[0]?.production,
    data: limitPerGroup ? data?.slice(0, limitPerGroup) : data,
    total: data?.length,
  }));
};
