import merge from 'lodash/merge';
import { PROFESSION_IDS } from 'constants/index';
import dataProvider from './JSONServerDataProvider';
// eslint-disable-next-line import/extensions
import config from '../config/config';
import { GET_LIST, GET_ONE, CREATE, UPDATE, DELETE, GET_MANY, GET } from '../containers/App/constants';
import route from '../constants/routes';
import {
  ACCOUNT_SETTINGS,
  COMPETITIONS,
  FILTER_AGGREGATION_SEARCH,
  PRODUCTIONS_AGGREGATIONS,
  ORGANIZATION_CONTACTS,
} from '../constants/endpointConstants';
import { ARTIST_TYPE_ID, REVIEW_ENTITIES } from '../constants/consts';
import { SUBSCRIPTIONS_STATUSES } from '../containers/UserContainers/AccountSettingsContainer/constants';
import { FIRST_PAGE, PAGINATION_LIMIT_1000 } from './queries/constants';

const Provider = dataProvider();

const login = ({ email, password, otp, isTrustedDevice, popupSource }) => {
  const commonData = {
    isTrustedDevice,
    application: 'frontbase',
    popupSource,
  };

  const data = otp
    ? {
        ...commonData,
        otpLogin: {
          token: otp,
          email,
          otpType: 'EMAIL_LOGIN',
        },
        type: 'otpLogin',
      }
    : {
        ...commonData,
        user: {
          email,
          password,
        },
        type: 'user',
      };

  return Provider(CREATE, 'auth/login', { data });
};

const registerUserWithoutPassword = data =>
  Provider(CREATE, 'auth/register', {
    data,
  });

const setUserSession = data =>
  Provider(CREATE, 'auth/setUserSession', {
    data,
  });

const validateEmailOTP = payload =>
  Provider(CREATE, 'api/users/emails/verifywithotp', {
    data: {
      email: payload.email,
      otp: payload.code,
    },
  });

const requestValidateEmailOTP = email =>
  Provider(CREATE, 'api/users/emails/resendotp', {
    data: {
      ...email,
    },
  });

const autoLogin = token =>
  Provider(CREATE, 'api/login', {
    data: {
      type: 'autoLogin',
      application: 'frontbase',
      autoLogin: {
        token,
      },
    },
  });

const refreshToken = query =>
  Provider(UPDATE, 'api/login/refresh-token', {
    data: {
      type: 'user',
    },
    queryParams: {
      token: query,
    },
  });

const getConstants = serverCookies => Provider(GET_LIST, 'api/login/constants', {}, serverCookies);

const getUser = (userId, serverCookies) =>
  Provider(
    GET_ONE,
    'api/users',
    {
      id: userId,
    },
    serverCookies,
  );

const getProfilePermissions = (profileId, serverCookies) =>
  Provider(
    GET_ONE,
    'api/profiles/permissions',
    {
      id: profileId,
    },
    serverCookies,
  );

const getUserPermissions = (userId, serverCookies) =>
  Provider(
    GET_ONE,
    'api/users/permissions',
    {
      id: userId,
    },
    serverCookies,
  );

const getWork = (workId, query, serverCookies) =>
  Provider(
    GET_ONE,
    'api/works',
    {
      id: workId,
      queryParams: query,
    },
    serverCookies,
  );

const getProfiles = (userId, showHidden, showInvalid, serverCookies) =>
  Provider(
    GET_LIST,
    'api/profiles',
    {
      queryParams: {
        access_user_id: userId,
        show_hidden: !!showHidden,
        show_invalid: !!showInvalid,
        limit: 100,
      },
    },
    serverCookies,
  );

const getProductionBookletList = (url, query, serverCookies) =>
  Provider(
    GET_LIST,
    url || 'api/productions/documents',
    {
      queryParams: {
        ...query,
      },
    },
    serverCookies,
  );

const getCastAndCrewList = (query, productionId, serverCookies) =>
  Provider(
    GET_LIST,
    `api/productions/${productionId}/productionPerformances`,
    {
      queryParams: {
        ...query,
      },
    },
    serverCookies,
  );

const getAccessRequests = serverCookies =>
  Provider(
    GET_LIST,
    'api/profiles/accessrequests',
    {
      queryParams: {
        limit: 100,
      },
    },
    serverCookies,
  );

const getProfileData = (profileId, query = null, serverCookies) =>
  Provider(
    GET_ONE,
    'api/profiles',
    {
      id: profileId,
      queryParams: query,
    },
    serverCookies,
  );

const getProductionDescriptionList = (url, query, serverCookies) =>
  Provider(
    GET_LIST,
    url || 'api/productions/documents',
    {
      queryParams: {
        ...query,
      },
    },
    serverCookies,
  );

const getProductionSynopsisList = (query, id, serverCookies) =>
  Provider(
    GET_LIST,
    `api/productions/${id}/synopsis`,
    {
      queryParams: {
        ...query,
      },
    },
    serverCookies,
  );

/**
 * TODO: ServerCookies to be included at API call
 */
const getAccessRequestByToken = (token, serverCookies) =>
  Provider(
    GET_LIST,
    'api/profiles/profileaccessrequests',
    {
      queryParams: { link_token: token },
    },
    serverCookies,
  );

const getActiveProfile = (userId, query, serverCookies) =>
  Provider(
    GET_ONE,
    'api/settings',
    {
      id: userId,
      queryParams: {
        ...query,
        exactSetting: 'active_profile_id',
      },
    },
    serverCookies,
  );

const registerUser = (data, serverCookies) =>
  Provider(
    CREATE,
    'api/users',
    {
      data,
    },
    serverCookies,
  );

/**
 * TODO: ServerCookies to be included at API call
 */
const validateEmail = (data, serverCookies) =>
  Provider(
    CREATE,
    'api/users/validate',
    {
      data,
    },
    serverCookies,
  );

const createRegistration = (data, userId, serverCookies) =>
  Provider(
    CREATE,
    `api/users/${userId}/registrations`,
    {
      data,
    },
    serverCookies,
  );
const updateRegistration = (id, data, serverCookies) =>
  Provider(
    UPDATE,
    'api/users/registrations',
    {
      id,
      data,
    },
    serverCookies,
  );

const updateUser = (id, data, serverCookies) =>
  Provider(
    UPDATE,
    'api/users',
    {
      id,
      data,
    },
    serverCookies,
  );

const createUserProfile = (data, serverCookies) =>
  Provider(
    CREATE,
    'api/profiles',
    {
      data,
    },
    serverCookies,
  );

/**
 * TODO: ServerCookies to be included at API call
 */
const createCheckout = (data, serverCookies) =>
  Provider(
    CREATE,
    'api/checkouts',
    {
      data,
    },
    serverCookies,
  );
const getPaymentIntent = (query, serverCookies) =>
  Provider(
    GET_MANY,
    'api/checkouts/payments/complete',
    {
      queryParams: {
        ...query,
      },
    },
    serverCookies,
  );

const sendPaymentIntentToAPI = (data, query, serverCookies) =>
  Provider(
    CREATE,
    'api/checkouts/payments/complete',
    {
      data,
      queryParams: {
        ...query,
      },
    },
    serverCookies,
  );

const requestPaymentIntentToAddCard = (userId, serverCookies) =>
  Provider(CREATE, `api/users/${userId}/paymentmethods/intent`, { data: { gimme: 'plz' } }, serverCookies);
const requestCheckoutPaymentIntent = (checkout, provider) =>
  Provider(GET, `api/checkouts/payments/complete`, {
    queryParams: {
      checkout_id: checkout.id,
      provider_id: provider,
    },
  });
const requestTokenToAddPayPal = (userId, data, serverCookies) =>
  Provider(CREATE, `api/users/${userId}/paymentmethods/agreement`, { data }, serverCookies);

const createPaymentMethod = (userId, data, serverCookies) =>
  Provider(
    CREATE,
    `api/users/${userId}/paymentmethods`,
    {
      data,
    },
    serverCookies,
  );

/**
 * TODO: ServerCookies to be included at API call
 */
const completeCheckout = (query, data, serverCookies) =>
  Provider(
    CREATE,
    'api/stripecheckout',
    {
      data,
      queryParams: {
        ...query,
      },
    },
    serverCookies,
  );

const resetUserPassword = (email, serverCookies) =>
  Provider(
    CREATE,
    'api/resetPassword',
    {
      data: {
        email,
        url: `${config.FRONTBASE_URL}${route.RESET_PASSWORD}`,
      },
    },
    serverCookies,
  );

/**
 * TODO: ServerCookies to be included at API call
 */
const updateUserPassword = (key, password, serverCookies) =>
  Provider(
    UPDATE,
    'api/updatePassword',
    {
      id: key,
      data: {
        password,
      },
    },
    serverCookies,
  );

const changePassword = (id, oldPassword, newPassword, serverCookies) =>
  Provider(
    UPDATE,
    'api/users',
    {
      id,
      data: {
        id,
        password: newPassword,
        passwordValidation: oldPassword,
      },
    },
    serverCookies,
  );

const checkPasswordKeyStatus = (key, serverCookies) =>
  Provider(GET_ONE, `api/updatePassword`, { id: key }, serverCookies);

/**
 * TODO: ServerCookies to be included at API call
 */
const getWorks = (query, pagination, serverCookies) =>
  Provider(
    GET_LIST,
    'api/works',
    {
      queryParams: {
        ...query,
      },
      pagination: {
        limit: 100,
        page: 1,
        ...pagination,
      },
    },
    serverCookies,
  );

const getArticle = (articleId, serverCookies) =>
  Provider(
    GET_ONE,
    'api/articles',
    {
      id: articleId,
    },
    serverCookies,
  );

const getRevivalStatus = (id, serverCookies) => Provider(GET_ONE, `api/production/revivals/${id}`, serverCookies);

const getArticles = (query, serverCookies) =>
  Provider(
    GET_LIST,
    'api/articles',
    {
      queryParams: query,
      pagination: {
        limit: 20,
      },
    },
    serverCookies,
  );

const getArticlesAggregations = ({ type, query = null, abortSignal, ...restQueryParams }, serverCookies) =>
  Provider(
    GET_LIST,
    'api/articles/aggregations',
    {
      queryParams: {
        aggregation_type: type,
        aggregation_query: query,
        ...restQueryParams,
      },
      pagination: {
        limit: 20,
        page: 1,
      },
    },
    serverCookies,
    abortSignal,
  );

/**
 * TODO: ServerCookies to be included at API call
 */
const getProductions = (query, pagination, serverCookies) =>
  Provider(
    GET_LIST,
    'api/productions',
    {
      queryParams: {
        ...query,
      },
      pagination: {
        limit: 100,
        page: 1,
        ...pagination,
      },
    },
    serverCookies,
  );

/**
 * TODO: ServerCookies to be included at API call
 */
const getPerformances = (query, pagination, serverCookies) =>
  Provider(
    GET_LIST,
    'api/performances',
    {
      queryParams: {
        ...query,
      },
      pagination: {
        limit: 100,
        page: 1,
        ...pagination,
      },
    },
    serverCookies,
  );

const getCompetitionInfo = (competitionId, queryParams, serverCookies) =>
  Provider(
    GET_ONE,
    `api/${COMPETITIONS}`,
    {
      id: competitionId,
      queryParams,
    },
    serverCookies,
  );

const searchProfiles = (
  profileType = ARTIST_TYPE_ID,
  queryString = '',
  exclude_id = '',
  pagination,
  filters = {},
  from,
  serverCookies,
) => {
  const url = from ? 'api/profiles/search' : 'api/profiles';
  return Provider(
    GET_LIST,
    url,
    {
      queryParams: {
        query: queryString,
        exclude_id,
        profile_type_id: profileType,
        ...filters,
      },
      pagination: {
        limit: 10,
        page: 1,
        ...pagination,
      },
    },
    serverCookies,
  );
};

const searchComposers = (queryString = '', pagination, filters = {}, from, serverCookies) => {
  const url = from ? 'api/profiles/search' : 'api/profiles';
  return Provider(
    GET_LIST,
    url,
    {
      queryParams: {
        query: queryString,
        profession_id: PROFESSION_IDS.COMPOSER,
        ...filters,
      },
      pagination: {
        limit: 10,
        page: 1,
        ...pagination,
      },
    },
    serverCookies,
  );
};

/**
 * TODO: ServerCookies to be included at API call
 */
const getProfile = (profileType = ARTIST_TYPE_ID, queryString = '', pagination, serverCookies) =>
  Provider(
    GET_LIST,
    'api/profiles',
    {
      queryParams: {
        name: queryString,
        profile_type_id: profileType,
      },
      pagination: {
        ...pagination,
      },
    },
    serverCookies,
  );
const getProfileByProfession = (profileType = ARTIST_TYPE_ID, query = {}, pagination, serverCookies) =>
  Provider(
    GET_LIST,
    'api/profiles',
    {
      queryParams: {
        profile_type_id: profileType,
        ...query,
      },
      pagination: {
        ...pagination,
      },
    },
    serverCookies,
  );

const getAgencies = (query, pagination, serverCookies) =>
  Provider(
    GET_LIST,
    'api/agencies',
    {
      queryParams: {
        ...query,
      },
      pagination: {
        limit: 100,
        page: 1,
        ...pagination,
      },
    },
    serverCookies,
  );

/**
 * TODO: ServerCookies to be included at API call
 */
const getAgency = (agencyId, query, serverCookies) =>
  Provider(
    GET_ONE,
    'api/agencies',
    {
      id: agencyId,
      queryParams: {
        ...query,
      },
    },
    serverCookies,
  );

const updateAgency = (agencyId, data, query, serverCookies) =>
  Provider(
    UPDATE,
    'api/agencies',
    {
      id: agencyId,
      data,
      queryParams: {
        ...query,
      },
    },
    serverCookies,
  );

const getOffices = (query, pagination, serverCookies) =>
  Provider(
    GET_LIST,
    'api/offices',
    {
      queryParams: {
        ...query,
      },
      pagination: {
        limit: 100,
        page: 1,
        ...pagination,
      },
    },
    serverCookies,
  );

const deleteAgencyOffice = (officeId, agencyId, profileId, serverCookies) =>
  Provider(
    DELETE,
    'api/offices',
    {
      id: officeId,
      queryParams: {
        agency_id: agencyId,
        profile_id: profileId,
      },
    },
    serverCookies,
  );

const updateAgencyOffice = (officeId, agencyId, profileId, data, serverCookies) =>
  Provider(
    UPDATE,
    'api/offices',
    {
      id: officeId,
      queryParams: {
        agency_id: agencyId,
        profile_id: profileId,
      },
      data,
    },
    serverCookies,
  );

const createAgencyOffice = (agencyId, profileId, data, serverCookies) =>
  Provider(
    CREATE,
    'api/offices',
    {
      queryParams: {
        agency_id: agencyId,
        profile_id: profileId,
      },
      data,
    },
    serverCookies,
  );

const createReferralStatus = (data, serverCookies) =>
  Provider(
    CREATE,
    `api/users/${data?.sender?.user?.id}/referralStatus`,
    {
      data,
    },
    serverCookies,
  );

const updateReferralStatus = (userId, referralId, data, serverCookies) =>
  Provider(
    UPDATE,
    `api/users/${userId}/referralStatus/${referralId}`,
    {
      data,
    },
    serverCookies,
  );

const createGenericReferral = (data, serverCookies) =>
  Provider(
    CREATE,
    `api/users/${data?.userId}/referrals`,
    {
      data,
    },
    serverCookies,
  );

const getArtistProfessions = (query, pagination = {}, serverCookies) =>
  Provider(
    GET_LIST,
    'api/professions',
    {
      queryParams: query,
      pagination: {
        limit: 100,
        page: 1,
        ...pagination,
      },
    },
    serverCookies,
  );

const getAgencyArtists = (agencyId, query, pagination, serverCookies) =>
  Provider(
    GET_LIST,
    'api/agencyArtists',
    {
      queryParams: {
        agency_id: agencyId,
        ...query,
      },
      pagination: {
        limit: 100,
        page: 1,
        ...pagination,
      },
    },
    serverCookies,
  );

const getAgencyArtist = (agencyId, artistId, query, serverCookies) =>
  Provider(
    GET_ONE,
    'api/agencyArtists',
    {
      id: artistId,
      queryParams: {
        agency_id: agencyId,
        ...query,
      },
    },
    serverCookies,
  );

const updateAgencyArtist = (agencyId, artistId, data = {}, query, actor, serverCookies) =>
  Provider(
    UPDATE,
    'api/agencyArtists',
    {
      id: artistId,
      queryParams: {
        agency_id: agencyId,
        ...query,
      },
      data: {
        ...data,
        actor,
      },
    },
    serverCookies,
  );

const getArtistsAgreements = (connectionsIds, pagination, serverCookies) =>
  Provider(
    GET_LIST,
    'api/agreements',
    {
      queryParams: {
        agency_connection_id: connectionsIds,
      },
      pagination: {
        limit: 1000,
        page: 1,
        ...pagination,
      },
    },
    serverCookies,
  );

const createArtistsAgreement = (artistId, agreements, activeProfileId, actor, serverCookies) =>
  Provider(
    CREATE,
    'api/agreements',
    {
      data: {
        connection: {
          id: artistId,
        },
        agreements,
        actor,
      },
      queryParams: {
        profile_id: activeProfileId,
      },
    },
    serverCookies,
  );

const removeArtistFromRoster = (agencyId, artistId, serverCookies) =>
  Provider(
    DELETE,
    'api/agencyArtists',
    {
      id: artistId,
      queryParams: {
        agency_id: agencyId,
      },
    },
    serverCookies,
  );

const addArtistToAgencyRoster = (agencyId, profileId, query = {}, data, serverCookies) =>
  Provider(
    CREATE,
    'api/agencyArtists',
    {
      queryParams: {
        agency_id: agencyId,
        profile_id: profileId,
        ...query,
      },
      data,
    },
    serverCookies,
  );

const getAgencyAgents = (agencyId, query, serverCookies) =>
  Provider(
    GET_LIST,
    'api/agencyAgents',
    {
      queryParams: {
        agency_id: agencyId,
        ...query,
      },
    },
    serverCookies,
  );

/**
 * TODO: ServerCookies to be included at API call
 */
const getAggregations = (endpoint, aggParams, pagination = {}, abortSignal, serverCookies = null) => {
  const endpointUpdated = endpoint ? `api/${endpoint}` : `api/${PRODUCTIONS_AGGREGATIONS}`;
  return Provider(
    GET_LIST,
    endpointUpdated,
    {
      queryParams: aggParams,
      pagination: {
        page: 1,
        limit: 10,
        ...pagination,
      },
    },
    serverCookies,
    abortSignal,
  );
};

/**
 * TODO: ServerCookies to be included at API call
 */
const getCitiesAggregations = (endpoint, aggParams, pagination = {}, serverCookies) => {
  const endpointUpdated = `api/${endpoint}` || `api/${PRODUCTIONS_AGGREGATIONS}`;
  return Provider(
    GET_LIST,
    endpointUpdated,
    {
      queryParams: aggParams,
      pagination: {
        page: 1,
        limit: 10,
        ...pagination,
      },
    },
    serverCookies,
  );
};

const getRentals = (query, serverCookies) =>
  Provider(
    GET_LIST,
    'api/productions',
    {
      queryParams: query,
      pagination: {
        limit: 20,
      },
    },
    serverCookies,
  );

const getRentalContactDetails = (companyId, serverCookies) =>
  Provider(
    GET_LIST,
    `api/companies/${companyId}/employees`,
    {
      pagination: {
        limit: 1000,
      },
    },
    serverCookies,
  );

const getRentalsAggregations = ({ type, query = null, ...restQueryParams }, serverCookies) =>
  Provider(
    GET_LIST,
    FILTER_AGGREGATION_SEARCH('api/productions'),
    {
      queryParams: {
        aggregation_type: type,
        aggregation_query: query,
        ...restQueryParams,
      },
      pagination: {
        limit: 20,
        page: 1,
      },
    },
    serverCookies,
  );

/**
 * TODO: ServerCookies to be included at API call
 */
const getRental = (prodId, rentalId, serverCookies) =>
  Provider(
    GET_ONE,
    `api/productions/${prodId}/rentals`,
    {
      id: rentalId,
    },
    serverCookies,
  );

/**
 * TODO: ServerCookies to be included at API call
 */
const getCountries = (query, slug = {}, serverCookies) =>
  Provider(
    GET_LIST,
    'api/countries',
    {
      queryParams: {
        ...slug,
        query,
      },
      pagination: {
        limit: 500,
      },
    },
    serverCookies,
  );

const getVenues = (query, pagination, serverCookies) =>
  Provider(
    GET_LIST,
    'api/venues',
    {
      queryParams: {
        ...query,
      },
      pagination: {
        limit: 12,
        ...pagination,
      },
    },
    serverCookies,
  );
const getRegions = (query, serverCookies) =>
  Provider(
    GET_LIST,
    'api/regions',
    {
      queryParams: {
        query,
      },
      pagination: {
        limit: 500,
      },
    },
    serverCookies,
  );

/**
 * TODO: ServerCookies to be included at API call
 */
const getCountriesWith = (section, query = {}, pagination = {}, serverCookies) =>
  Provider(
    GET_LIST,
    'api/countries',
    {
      queryParams: {
        has: section,
        ...query,
      },
      pagination: {
        limit: 1000,
        ...pagination,
      },
    },
    serverCookies,
  );

/**
 * TODO: ServerCookies to be included at API call
 */
const getCountry = (countryId, serverCookies) =>
  Provider(
    GET_ONE,
    'api/countries',
    {
      id: countryId,
    },
    serverCookies,
  );

/**
 * TODO: ServerCookies to be included at API call
 */
const getCity = (cityId, serverCookies) =>
  Provider(
    GET_ONE,
    'api/countries/cities',
    {
      id: cityId,
    },
    serverCookies,
  );

const getCompanies = (query, pagination, serverCookies) =>
  Provider(
    GET_LIST,
    'api/companies',
    {
      queryParams: {
        ...query,
      },
      pagination: {
        limit: 12,
        ...pagination,
      },
    },
    serverCookies,
  );

const updateSettingsField = (userId, identified, value, serverCookies) =>
  Provider(
    UPDATE,
    `api/${ACCOUNT_SETTINGS(userId)}`,
    {
      id: identified,
      data: {
        value,
      },
    },
    serverCookies,
  );

const getLanguagesList = serverCookies =>
  Provider(
    GET_LIST,
    'api/languages',
    {
      queryParams: {
        lang: 'en',
      },
      pagination: {
        limit: 200,
      },
    },
    serverCookies,
  );

const getStatistics = (typeId, query, serverCookies) =>
  Provider(
    GET_LIST,
    'api/stats/advanced',
    {
      queryParams: {
        id: typeId,
        ...query,
      },
    },
    serverCookies,
  );

const getRepertoire = (query, pagination, serverCookies) =>
  Provider(
    GET_LIST,
    'api/repertoire',
    {
      queryParams: {
        ...query,
      },
      pagination: {
        limit: 100,
        page: 1,
        ...pagination,
      },
    },
    serverCookies,
  );

const getReviews = (profileId, pagination, query, serverCookies) =>
  Provider(
    GET_LIST,
    'api/reviews',
    {
      queryParams: {
        viewing_entity_type: REVIEW_ENTITIES.PROFILES,
        viewing_entity_id: profileId,
        ...query,
      },
      pagination: {
        limit: 100,
        page: 1,
        ...pagination,
      },
    },
    serverCookies,
  );

const getReviewsV1 = (url, pagination, query, serverCookies) =>
  Provider(
    GET_LIST,
    url || 'api/reviews',
    {
      queryParams: {
        ...query,
      },
      pagination: {
        limit: 100,
        page: 1,
        ...pagination,
      },
    },
    serverCookies,
  );
const getVideosForProfile = (pagination, query, serverCookies) =>
  Provider(
    GET_LIST,
    'api/media',
    {
      queryParams: {
        ...query,
      },
      pagination: {
        limit: 100,
        page: 1,
        ...pagination,
      },
    },
    serverCookies,
  );

const getProductionDatesV1 = (pagination, id, query, serverCookies) =>
  Provider(
    GET_LIST,
    `api/productions/${id}/productionPerformances`,
    {
      queryParams: {
        ...query,
        as_edit: true,
      },
      pagination: {
        limit: 100,
        page: 1,
        ...pagination,
      },
    },
    serverCookies,
  );

const getProductionCalendarDates = (pagination, id, query, serverCookies) =>
  Provider(
    GET_LIST,
    `api/productions/${id}/performanceAggregations`,
    {
      queryParams: {
        ...query,
        as_edit: true,
      },
      pagination: {
        limit: 100,
        page: 1,
        ...pagination,
      },
    },
    serverCookies,
  );

const getReviewDetail = (id, serverCookies) => Provider(GET_ONE, 'api/reviews', { id }, serverCookies);

const getProductsList = (serverCookies, product_type = [], profile_type_id) =>
  Provider(
    GET_LIST,
    'api/products',
    {
      queryParams: {
        lang: 'en',
      },
      pagination: {
        limit: 100,
      },
      filter: {
        product_type,
        profile_type_id,
      },
    },
    serverCookies,
  );

const getUserSubscriptions = (userId, serverCookies) =>
  Provider(
    GET_LIST,
    `api/users/${userId}/subscriptions`,
    {
      queryParams: {
        lang: 'en',
      },
      pagination: {
        limit: 100,
      },
    },
    serverCookies,
  );

const getSubscriptionCancellationReasons = serverCookies =>
  Provider(
    GET_LIST,
    `api/users/subscriptions/cancellationreasons`,
    {
      queryParams: {
        lang: 'en',
        availability: 'common',
      },
      pagination: {
        limit: 100,
      },
    },
    serverCookies,
  );

const getUserSubscriptionChangeEstimate = (subId, data, serverCookies) =>
  Provider(
    CREATE,
    `api/users/subscriptions/${subId}/estimate`,
    {
      data,
    },
    serverCookies,
  );

const getUserPayments = (userId, query, pagination, serverCookies) =>
  Provider(
    GET_LIST,
    `api/users/${userId}/payments`,
    {
      queryParams: {
        lang: 'en',
        ...query,
      },
      pagination: {
        limit: 100,
        ...pagination,
      },
    },
    serverCookies,
  );

const getUserInvoices = (userId, serverCookies) =>
  Provider(
    GET_LIST,
    `api/users/${userId}/invoices`,
    {
      queryParams: {
        lang: 'en',
      },
      pagination: {
        limit: 100,
      },
    },
    serverCookies,
  );

const getSubscriptions = ({ query }, serverCookies) =>
  Provider(
    GET_LIST,
    `api/users/subscriptions`,
    {
      queryParams: {
        lang: 'en',
        ...query,
      },
      pagination: {
        limit: 100,
      },
    },
    serverCookies,
  );

const getActiveProfileSubscriptions = (userId, profileId, serverCookies) =>
  Provider(
    GET_LIST,
    'api/users/subscriptions',
    {
      queryParams: {
        lang: 'en',
        id: userId,
        profile_id: profileId,
      },
      pagination: {
        limit: 100,
      },
    },
    serverCookies,
  );

const getActiveProfileInvoices = (userId, subscriptionIds, serverCookies) =>
  Provider(
    GET_LIST,
    'api/users/invoices',
    {
      queryParams: {
        lang: 'en',
        user_id: userId,
        user_subscription_id: subscriptionIds,
      },
      pagination: {
        limit: 100,
      },
    },
    serverCookies,
  );

const cancelUserSubscription = ({ query: { userId, subscriptionId }, data: { cancelReason, note } }, serverCookies) =>
  Provider(
    UPDATE,
    'api/users/subscriptions',
    {
      queryParams: {
        lang: 'en',
        id: userId,
        subid: subscriptionId,
      },
      data: {
        status: SUBSCRIPTIONS_STATUSES.nonRenewing,
        invoiceNow: true,
        cancelReasonCode: cancelReason,
        cancelReasonDescription: note,
      },
    },
    serverCookies,
  );

const reactivateUserSubscription = (userId, subscriptionId, status, serverCookies) =>
  Provider(
    UPDATE,
    'api/users/subscriptions',
    {
      queryParams: {
        lang: 'en',
        id: userId,
        subid: subscriptionId,
      },
      data: {
        status,
        invoiceNow: true,
      },
    },
    serverCookies,
  );
const updateUserSubscription = (userId, subscriptionId, data, serverCookies) =>
  Provider(
    UPDATE,
    'api/users/subscriptions',
    {
      queryParams: {
        lang: 'en',
        id: userId,
        subid: subscriptionId,
      },
      data: { ...data, invoiceNow: true },
    },
    serverCookies,
  );
const removeUserSubscriptionCoupon = (subscriptionId, couponId, serverCookies) =>
  Provider(DELETE, `api/users/subscriptions/${subscriptionId}/coupons/${couponId}`, {}, serverCookies);

/**
 * TODO: ServerCookies to be included at API call
 */
const addUserSubscription = (userId, data, serverCookies) =>
  Provider(
    CREATE,
    'api/users/subscriptions',
    {
      queryParams: {
        lang: 'en',
        id: userId,
      },
      data,
    },
    serverCookies,
  );

const createUserSubscription = (data, serverCookies) =>
  Provider(
    CREATE,
    'api/users/subscriptions',
    {
      data,
    },
    serverCookies,
  );

const getUserPaymentMethods = (userId, serverCookies) =>
  Provider(
    GET_LIST,
    `api/users/${userId}/paymentmethods`,
    {
      pagination: {
        limit: 200,
      },
    },
    serverCookies,
  );

/**
 * TODO: ServerCookies to be included at API call
 */
const addUserBankCard = (userId, data, serverCookies) =>
  Provider(
    CREATE,
    `api/users/cards`,
    {
      queryParams: {
        id: userId,
      },
      data,
    },
    serverCookies,
  );

/**
 * TODO: ServerCookies to be included at API call
 */
const deleteUserPaymentMethod = (userId, pmId, serverCookies) =>
  Provider(DELETE, `api/users/${userId}/paymentmethods/${pmId}`, {}, serverCookies);

/**
 * TODO: ServerCookies to be included at API call
 */
const updateUserPaymentMethod = (userId, methodId, data, serverCookies) =>
  Provider(
    UPDATE,
    `api/users/${userId}/paymentmethods/${methodId}`,
    {
      data,
    },
    serverCookies,
  );

const getHomeNumbers = ({ id = 'all' }, serverCookies) => Provider(GET_ONE, 'api/stats/numbers', { id }, serverCookies);

const getOnthisday = (id, query, pagination = {}, serverCookies) =>
  Provider(
    GET_ONE,
    'api/stats/onthisday',
    {
      id,
      queryParams: {
        ...query,
      },
      pagination: {
        limit: 1000,
        ...pagination,
      },
    },
    serverCookies,
  );

const remoteLogout = serverCookies => Provider(DELETE, 'auth/logout', {}, serverCookies);

/**
 * TODO: ServerCookies to be included at API call
 */
const getList = (endpoint, params = {}, serverCookies) => Provider(GET_LIST, `api/${endpoint}`, params, serverCookies);

const getWholeList = (endpoint, params = {}, serverCookies) => {
  const paramsWithPagination = merge(params, { pagination: { page: FIRST_PAGE, ...PAGINATION_LIMIT_1000 } });
  return getList(endpoint, paramsWithPagination, serverCookies);
};

/**
 * TODO: ServerCookies to be included at API call
 */
const getOne = (endpoint, id, params, serverCookies) =>
  Provider(GET_ONE, `api/${endpoint}`, merge({ id }, params), serverCookies);

const updateOne = (endpoint, id, data, files, serverCookies) =>
  Provider(UPDATE, `api/${endpoint}`, { id, data, files }, serverCookies);

const deleteOne = (endpoint, id, params, serverCookies) =>
  Provider(
    DELETE,
    `api/${endpoint}`,
    {
      id,
      queryParams: params,
    },
    serverCookies,
  );

const getAuthRequest = (endpoint, params, serverCookies) => Provider(GET, endpoint, params, serverCookies);

const getRequest = (endpoint, params, serverCookies) => Provider(GET, `api/${endpoint}`, params, serverCookies);

const postRequest = (endpoint, params, serverCookies) => Provider(CREATE, `api/${endpoint}`, params, serverCookies);

const updateRequest = (endpoint, params, serverCookies) => Provider(UPDATE, `api/${endpoint}`, params, serverCookies);

const deleteRequest = (endpoint, params, serverCookies) => Provider(DELETE, `api/${endpoint}`, params, serverCookies);

const create = (endpoint, data, files, serverCookies) =>
  Provider(CREATE, `api/${endpoint}`, { data, files }, serverCookies);

const getArtistAgencies = (queryParams, pagination = {}, serverCookies) =>
  Provider(
    GET_LIST,
    `api/agencies/artists`,
    {
      queryParams,
      pagination: {
        limit: 100,
        page: 1,
        ...pagination,
      },
    },
    serverCookies,
  );

const getUserInfo = (userId, serverCookies) =>
  Provider(
    GET_ONE,
    'auth/userDetails',
    {
      id: userId,
    },
    serverCookies,
  );

const updateActiveProfile = (userId, identified, value, serverCookies) =>
  Provider(
    UPDATE,
    `auth/activeProfile`,
    {
      id: userId,
      data: {
        value,
      },
    },
    serverCookies,
  );

const getWorkCastCrew = (path, payload, serverCookies) =>
  Provider(
    GET_ONE,
    `api/proxy/${path}/${payload?.workId}`,
    { id: payload?.productionWorkId, queryParams: {} },
    serverCookies,
  );

const getVideo = (query = {}, serverCookies) =>
  Provider(
    GET_LIST,
    'api/cuetv/videos',
    {
      queryParams: {
        ...query,
      },
      pagination: {
        limit: 10,
      },
    },
    serverCookies,
  );

const getNotifications = (query, pagination, serverCookies) =>
  Provider(
    GET_ONE,
    'api/notifications/',
    {
      queryParams: {
        ...query,
      },
      pagination: {
        limit: 10,
        page: 1,
        ...pagination,
      },
    },
    serverCookies,
  );

const updateOrgTranslations = (orgId, payload, serverCookies) =>
  Provider(
    CREATE,
    `api/organizations/${orgId}/translations`,
    {
      data: {
        data: payload,
      },
    },
    serverCookies,
  );

const updateOrgDescription = (orgId, payload, serverCookies) =>
  Provider(
    UPDATE,
    `api/organizations/${orgId}/translations`,
    {
      id: payload?.id,
      data: {
        data: payload,
      },
    },
    serverCookies,
  );

const deleteOrgTranslations = (orgId, id, serverCookies) =>
  Provider(
    DELETE,
    `api/organizations/${orgId}/translations`,
    {
      id,
    },
    serverCookies,
  );

const getOrgTranslations = (orgId, serverCookies) =>
  Provider(CREATE, `api/organizations/${orgId}/translations`, {}, serverCookies);

const updateArtistTranslations = (artistId, payload, serverCookies) =>
  Provider(
    CREATE,
    `api/profiles/${artistId}/translations`,
    {
      data: {
        data: payload,
      },
    },
    serverCookies,
  );

const updateFestivalTranslations = (festivalId, payload, serverCookies) =>
  Provider(
    CREATE,
    `api/festivals/${festivalId}/translations`,
    {
      data: {
        data: payload,
      },
    },
    serverCookies,
  );

const getUserByEmail = (email, serverCookies) =>
  Provider(
    GET,
    'auth/userByEmail',
    {
      queryParams: {
        email,
      },
    },
    serverCookies,
  );

const getReferral = (token, email, serverCookies) =>
  Provider(
    GET,
    'api/users/referralStatus',
    {
      queryParams: {
        token,
        email,
      },
    },
    serverCookies,
  );

const createLoginLink = (userId, serverCookies) =>
  Provider(CREATE, `api/users/${userId}/autoLogin`, { data: { user_id: userId } }, serverCookies);

const getGiftToken = (token, email, serverCookies) =>
  Provider(
    GET,
    'api/users/giftTokens',
    {
      queryParams: {
        token,
      },
    },
    serverCookies,
  );

const queryUniversalSearchResults = (queryParams, serverCookies) =>
  Provider(
    GET,
    'api/search/results',
    {
      queryParams: {
        ...queryParams,
      },
    },
    serverCookies,
  );

const queryUniversalSearch = (queryParams, serverCookies) =>
  new Promise((resolve, reject) => {
    Provider(
      GET,
      'api/search',
      {
        queryParams: {
          ...queryParams,
        },
      },
      serverCookies,
    )
      .then(response => resolve(response?.data))
      .catch(error => reject(error));
  });

const getInsights = (props, serverCookies) =>
  Provider(
    GET,
    `api/profiles/${props.entityId}/stats`,
    {
      queryParams: {
        aggregate: props.aggregate,
        category: props.category,
        to: props.to,
        from: props.from,
        metric: props.metric,
      },
    },
    serverCookies,
  );

// @Todo: To be removed
// const getProfileInsightsRaw = (profileId, fromDate, toDate, insightType, queryLimit) =>
//   fetchJson('https://8xjramehe6.execute-api.eu-central-1.amazonaws.com/Prod/query', {
//     method: 'get',
//     headers: { 'x-api-key': 'pCMEBpmL0o63sIfULehGB5dRSLUQ3oUr4knY34yo' },
//     params: {
//       from_date: fromDate,
//       to_date: toDate,
//       view: insightType,
//       entity_id: profileId,
//       limit: queryLimit,
//     },
//   });

const getRequestsAccess = ({ user_id, limit, page, status }, serverCookies) =>
  Provider(
    GET_LIST,
    `api/profiles/profileaccessrequests`,
    {
      queryParams: {
        owner_user_id: user_id,
        status,
        sort: 'batch',
      },
      pagination: {
        limit,
        page,
      },
    },
    serverCookies,
  );

const getCurrentUserRequestsAccess = ({ user_id, limit, page, status }, serverCookies) =>
  Provider(
    GET_LIST,
    `api/profiles/profileaccessrequests`,
    {
      queryParams: {
        user_id,
        status,
        sort: 'batch',
      },
      pagination: {
        limit,
        page,
      },
    },
    serverCookies,
  );

const updateRequestsAccess = (id, status, serverCookies) =>
  Provider(
    UPDATE,
    `api/profiles/profileaccessrequests/${id}`,
    {
      data: { status },
    },
    serverCookies,
  );

const createOrganizationContacts = (orgId, data, serverCookies) =>
  Provider(
    CREATE,
    `api/${ORGANIZATION_CONTACTS(orgId)}`,
    {
      queryParams: {
        is_registration: true,
      },
      data,
    },
    serverCookies,
  );

export {
  getAuthRequest,
  getRequest,
  postRequest,
  updateRequest,
  deleteRequest,
  login,
  autoLogin,
  refreshToken,
  getConstants,
  getUser,
  getProfilePermissions,
  getUserPermissions,
  getWork,
  getProfiles,
  getAccessRequests,
  getProfileData,
  getAccessRequestByToken,
  getActiveProfile,
  registerUser,
  registerUserWithoutPassword,
  setUserSession,
  validateEmail,
  createRegistration,
  updateRegistration,
  updateUser,
  createUserProfile,
  createCheckout,
  completeCheckout,
  resetUserPassword,
  updateUserPassword,
  changePassword,
  checkPasswordKeyStatus,
  getWorks,
  getArticle,
  getArticles,
  getArticlesAggregations,
  getProductions,
  getPerformances,
  getCompetitionInfo,
  searchProfiles,
  getProfile,
  getProfileByProfession,
  getAgencies,
  getAgency,
  updateAgency,
  getOffices,
  deleteAgencyOffice,
  updateAgencyOffice,
  createAgencyOffice,
  getArtistProfessions,
  getAgencyArtists,
  getAgencyArtist,
  updateAgencyArtist,
  getArtistsAgreements,
  createArtistsAgreement,
  removeArtistFromRoster,
  addArtistToAgencyRoster,
  getAgencyAgents,
  getAggregations,
  getCitiesAggregations,
  getRentals,
  getRentalsAggregations,
  getRental,
  getCountries,
  getVenues,
  getCountry,
  getCity,
  getRegions,
  getCountriesWith,
  getCompanies,
  updateSettingsField,
  getLanguagesList,
  getStatistics,
  getRepertoire,
  getReviews,
  getReviewsV1,
  getReviewDetail,
  getProductsList,
  getUserSubscriptions,
  getActiveProfileSubscriptions,
  getActiveProfileInvoices,
  cancelUserSubscription,
  addUserSubscription,
  getUserPaymentMethods,
  addUserBankCard,
  deleteUserPaymentMethod,
  updateUserPaymentMethod,
  getHomeNumbers,
  getOnthisday,
  remoteLogout,
  getList,
  getWholeList,
  getOne,
  updateOne,
  deleteOne,
  create,
  getArtistAgencies,
  getUserInfo,
  updateActiveProfile,
  getPaymentIntent,
  sendPaymentIntentToAPI,
  requestPaymentIntentToAddCard,
  createPaymentMethod,
  requestTokenToAddPayPal,
  getSubscriptions,
  getUserInvoices,
  getUserPayments,
  getRevivalStatus,
  getProductionDescriptionList,
  getProductionSynopsisList,
  getProductionBookletList,
  reactivateUserSubscription,
  getVideosForProfile,
  getWorkCastCrew,
  getProductionDatesV1,
  getProductionCalendarDates,
  getCastAndCrewList,
  getNotifications,
  updateUserSubscription,
  getVideo,
  updateFestivalTranslations,
  getUserSubscriptionChangeEstimate,
  requestCheckoutPaymentIntent,
  removeUserSubscriptionCoupon,
  getRentalContactDetails,
  getUserByEmail,
  createLoginLink,
  getReferral,
  getSubscriptionCancellationReasons,
  createReferralStatus,
  updateReferralStatus,
  queryUniversalSearchResults,
  queryUniversalSearch,
  createGenericReferral,
  getGiftToken,
  createUserSubscription,
  updateArtistTranslations,
  getInsights,
  validateEmailOTP,
  requestValidateEmailOTP,
  getRequestsAccess,
  updateRequestsAccess,
  searchComposers,
  updateOrgTranslations,
  getOrgTranslations,
  deleteOrgTranslations,
  updateOrgDescription,
  createOrganizationContacts,
  getCurrentUserRequestsAccess,
};
