import { takeLatest, put, call, all } from 'redux-saga/effects';
import get from 'lodash/get';
import { TP } from 'constants/index';

import { getWithDefault } from 'utils/globals/app';
import {
  addAndUpdateContactsAction,
  updateOrgInfoAction,
  updateOrgVenueAction,
  updateOrgTranslationsAction,
  getOrgTranslationsAction,
} from './actions';
// eslint-disable-next-line import/named
import * as API from '../../utils/API';
import { enqueueSnackbarAction } from '../App/actions';
import { ORGANIZATION, ORGANIZATION_ADDRESSES, ORGANIZATION_CONTACTS, VENUES } from '../../constants/endpointConstants';

import { i18n } from '../../src/i18n';
const { create, updateOne, deleteOne } = API;

export function* addAndUpdateContacts({ url, payload, serverCookies }) {
  const callback = get(payload, 'callback');
  const endpoint = url ?? ORGANIZATION_CONTACTS(payload?.orgId);

  try {
    const newContacts = getWithDefault(payload, 'newContacts', []);
    const updatingContacts = getWithDefault(payload, 'updatingContacts', []);
    const deletedContactsIds = getWithDefault(payload, 'deletedContactsIds', []);
    yield all([
      ...(newContacts?.length > 0 ? [call(create, endpoint, { data: newContacts }, null, serverCookies)] : []),
      ...(updatingContacts?.length > 0
        ? [call(updateOne, endpoint, updatingContacts[0]?.id, { data: updatingContacts }, null, serverCookies)]
        : []),
      ...deletedContactsIds.map(deletedContactId => call(deleteOne, endpoint, deletedContactId, {}, serverCookies)),
    ]);

    if (callback && typeof callback === 'function') payload.callback(true);
    yield put(addAndUpdateContactsAction.success());
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_SUCCESS_UPDATING_CONTACTS`),
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (err) {
    if (callback && typeof callback === 'function') payload.callback(false);
    yield put(addAndUpdateContactsAction.failure());
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_UPDATING_CONTACTS`, {
          endpoint,
          error: err?.responseText ?? err?.message,
        }),
        options: {
          variant: 'error',
        },
      }),
    );
  }
}

export function* addAndUpdateOrgAddress({ payload, serverCookies }) {
  const callback = get(payload, 'callback');
  const endpoint = ORGANIZATION_CONTACTS(payload?.orgId);

  try {
    const newContacts = getWithDefault(payload, 'newContacts', []);
    const updatingContacts = getWithDefault(payload, 'updatingContacts', []);
    const deletedContactsIds = getWithDefault(payload, 'deletedContactsIds', []);
    yield all([
      ...(newContacts?.length > 0 ? [call(create, endpoint, { data: newContacts }, null, serverCookies)] : []),
      ...(updatingContacts?.length > 0
        ? [call(updateOne, endpoint, updatingContacts[0]?.id, { data: updatingContacts }, null, serverCookies)]
        : []),
      ...deletedContactsIds.map(deletedContactId => call(deleteOne, endpoint, deletedContactId, {}, serverCookies)),
    ]);

    if (callback && typeof callback === 'function') payload.callback(true);
    yield put(addAndUpdateContactsAction.success());
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_SUCCESS_UPDATING_CONTACTS`),
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (err) {
    if (callback && typeof callback === 'function') payload.callback(false);
    yield put(addAndUpdateContactsAction.failure());
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_UPDATING_CONTACTS`, {
          endpoint,
          error: err?.responseText ?? err?.message,
        }),
        options: {
          variant: 'error',
        },
      }),
    );
  }
}

export function* updateOrgVenue({ payload, serverCookies }) {
  const callback = get(payload, 'callback');
  const venues = get(payload, 'venues', []);
  try {
    yield all(venues?.map(v => call(updateOne, VENUES, v?.id, v, null, serverCookies)));
    if (callback && typeof callback === 'function') {
      payload.callback();
    }
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_SUCCESS_UPDATING_VENUE`),
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (err) {
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_UPDATING_VENUE`, { error: err?.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  }
}

export function* updateOrgInfo({ payload, serverCookies }) {
  const callback = get(payload, 'callback');
  const orgId = payload?.orgId;

  try {
    yield all([
      call(updateOne, ORGANIZATION, orgId, payload?.companyInfo, null, serverCookies),
      call(addAndUpdateContacts, {
        url: ORGANIZATION_ADDRESSES(orgId),
        payload: {
          orgId,
          newContacts:
            payload?.address?.newContacts?.map(newContact => ({ ...newContact, address: newContact?.value })) || [],
          updatingContacts:
            payload?.address?.updatedContacts?.map(newContact => ({
              ...newContact,
              address: newContact?.value,
            })) || [],
          deletedContactsIds: payload?.address?.deletedContacts || [],
        },
      }),
      call(addAndUpdateContacts, {
        url: ORGANIZATION_CONTACTS(orgId),
        payload: {
          orgId,
          newContacts: payload?.newContacts || [],
          updatingContacts: payload?.updatedContacts || [],
          deletedContactsIds: payload?.deletedContacts || [],
        },
      }),
    ]);

    if (callback && typeof callback === 'function') payload.callback(true);
  } catch (err) {
    if (callback && typeof callback === 'function') payload.callback(false);
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_UPDATING_ORG_INFO`, { error: err?.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  }
}

export function* updateOrgTranslations({ payload, serverCookies }) {
  const callback = get(payload, 'callback');

  try {
    const translations = yield call(API.updateOrgTranslations, payload?.orgId, payload?.translations, serverCookies);
    yield put(getOrgTranslationsAction.success(get(translations, 'data')));

    if (callback && typeof callback === 'function') payload.callback(true);
  } catch (err) {
    if (callback && typeof callback === 'function') payload.callback(false);
    yield put(
      enqueueSnackbarAction({
        message: `Error updating organization name: ${err?.message}`,
        options: {
          variant: 'error',
        },
      }),
    );
  }
}

export default function* organizationSaga() {
  yield takeLatest(addAndUpdateContactsAction.TRIGGER, addAndUpdateContacts);
  yield takeLatest(updateOrgInfoAction.TRIGGER, updateOrgInfo);
  yield takeLatest(updateOrgVenueAction.TRIGGER, updateOrgVenue);
  yield takeLatest(updateOrgTranslationsAction.TRIGGER, updateOrgTranslations);
}
