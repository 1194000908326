import first from 'lodash/first';
import { route } from 'constants/routes';
import { TP, ENTITY_MAIN_TABS, PROFESSION_TYPE } from 'constants/index';
import { getEntityAndTypeFromContributor } from 'utils/productions';

import { getRoutePartWithSlug } from '../common';

export const getComposerName = (composer = {}, onlyShortName) => {
  const { firstName, lastName, name, shortName } = composer || {};

  if (onlyShortName) {
    return shortName || name || '';
  }

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return name || firstName || lastName || '';
};

export const getWorkComposerList = work =>
  work?.creators?.filter(creator => creator?.profession?.slug === PROFESSION_TYPE.COMPOSER) || [];

export const getWorkComposers = (work, onlyShortName = false, asParts = false) => {
  const workComposers =
    work?.creators?.reduce((acc, creator) => {
      if (creator?.profession?.slug === PROFESSION_TYPE.COMPOSER) {
        const composerName = getComposerName(getEntityAndTypeFromContributor(creator).entity, onlyShortName);

        if (composerName) {
          acc.push(composerName);
        }
      }

      return acc;
    }, []) || [];

  if (asParts) {
    return workComposers;
  }
  return workComposers?.join(' • ');
};

export const getWorkTitle = (entity, withComposers = false, onlyShortName = false, asParts = false) => {
  const workName = entity?.original_name || entity?.originalName || entity?.name || '';

  let translation = '';
  if (workName && entity?.name && workName !== entity?.name) {
    translation = ` (${entity?.name})`;
  }

  let composers = '';
  if (withComposers) {
    composers = getWorkComposers(entity, onlyShortName, asParts);
  }

  if (asParts) {
    return {
      name: workName,
      translation,
      composers,
    };
  }

  let finalWorkName = workName;
  if (translation) {
    finalWorkName = `${workName} ${translation}`;
  }

  if (composers) {
    finalWorkName = `${finalWorkName}, ${composers}`;
  }

  return finalWorkName;
};

export const getCreatorsName = (entity, shortName = true) => {
  const workComposers =
    entity?.creators?.reduce((acc, creator) => {
      if (creator?.profession?.slug === PROFESSION_TYPE.COMPOSER) {
        const { entity: contributorEntity, entityType } = getEntityAndTypeFromContributor(creator);
        const composerName = getComposerName(contributorEntity, shortName);
        if (composerName) {
          acc.push({ name: composerName, id: contributorEntity?.id, entityType });
        }
      }

      return acc;
    }, []) || [];
  return workComposers;
};

export const getWorkTranslation = work => {
  const name = work?.original_name || work?.name || '';
  return name && work?.name && name !== work?.name ? work?.name : null;
};

export const getWorkRoleLabel = role => {
  let label = role?.original_name || role?.name || '';

  if (label && role?.name && label !== role?.name) {
    label += ` (${role?.name})`;
  }

  return label;
};

export const getBreadcrumbs = ({ work, tab }) => {
  const { title: activeTabName, key: activeTabValue } = tab || {};
  let customBreadcrumbs = [
    { title: `${TP}.m_HOME` },
    { path: `${route.WORKS}/`, title: `${TP}.m_MUSICALWORK` },
    {
      path: `${route.WORKS}/${first(work?.composers)?.slug}-${getRoutePartWithSlug(work)}/`,
      title: work?.original_name,
    },
  ];
  if (activeTabValue !== ENTITY_MAIN_TABS.HOME) {
    customBreadcrumbs = [
      ...customBreadcrumbs,
      {
        path: `${route.WORKS}/${getRoutePartWithSlug(work)}/${activeTabValue}/`,
        title: activeTabName,
      },
    ];
  }
  return customBreadcrumbs;
};
