import React, { useRef, useEffect, useCallback, useMemo, useContext, useState } from 'react';
import { useRouter } from 'next/router';
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line import/no-cycle
import useTracking from 'components/Globals/Analytics';

import getRouteContext, { getValidFilters, removeLanguageSuffixFromPath } from 'utils/globals/getRouteContext';
import usePermissions from 'utils/permissions';
import useAppContext from 'utils/hooks/useAppContext';
import { useQuery } from 'utils/react-query';
import { nameToSearchEntityType, pushVisitHistoryItem } from 'utils/search/visitsHistory';

import {
  ENTITY_TYPE,
  BASE_PAGE_ROUTES,
  URL_SLUG_TYPES,
  VALIDATION_STATUS,
  ENTITY_MAIN_TABS,
  LOCATION_TYPE,
  VALID_QUERY_PARAMS,
} from 'constants/index';
import { FILTER_TYPES, FILTER_SLUG_TYPE, ADVANCED_FILTER_TYPES } from 'constants/filters';

import { trackClearForm, trackSearchGo } from 'utils/tracking';
import getLinkProps from 'utils/globals/getLinkProps';
import { getCampaignAttributionDetails } from 'utils/cookie';
import { getEntityDetails } from 'utils/globals/ssrQueries';
import { useAppliedOrgTypeFilter, useAppliedProfessionFilter } from 'utils/globals/ssrQueries/hooks';
import { Router } from 'src/i18n';
import { GOOGLE_OLD_TRACKING_SERVICES } from 'components/Globals/Analytics/constants';
import { OB_UTM } from 'constants/cookieConstants';
import globalQueries from 'containers/Globals/queries';

const PageContext = React.createContext({});
const HISTORY_LIMIT = 100;

export const PageContextProvider = ({ children, serverCookies, isFingerPrintEnabled = false }) => {
  const navigation = useRef([]);
  const entityReFetchStatus = useRef(false);
  const router = useRouter();
  const { isLoggedIn, language } = useAppContext();
  const context = getRouteContext({ ...router, isLoggedIn }, language);
  const track = useTracking();
  const permissions = usePermissions(context?.entityId);
  const [appliedFilterSlugs, setAppliedFiltersSlugs] = useState([]);
  const [editModeToggle, setEditModeToggle] = useState(false);
  const { filterParams, queryParams, countrySlug } = context || {};
  const scrollToSection = queryParams?.[VALID_QUERY_PARAMS.SCROLL_TO];

  const scrollTo = useCallback(
    ({ top = 0, behavior = 'smooth', force = false, left = 0 } = {}) => {
      if (typeof window !== 'undefined' && (!scrollToSection || force)) {
        window.scrollTo({ top, left, behavior });
      }
    },
    [scrollToSection],
  );

  const showApprovedHiddenEntity = useMemo(() => {
    if (permissions?.isAdmin) {
      return true;
    }

    switch (context?.entityType) {
      case ENTITY_TYPE.ARTIST: {
        return permissions?.artistPermissions?.canEdit;
      }
      case ENTITY_TYPE.ORGANIZATION: {
        return permissions?.organizationPermissions?.canEdit;
      }
      default: {
        return false;
      }
    }
  }, [context?.entityType, permissions]);

  const { data: pageEntity, isLoading: pageEntityLoading = false, refetch: refetchEntity } = useQuery(
    getEntityDetails(
      {
        entityId: context?.entityId,
        entityType: context?.entityType,
        showApprovedHidden: context?.edit ? showApprovedHiddenEntity : false, // TODO: Remove this check with user permissions revamp
        asEdit: context?.edit,
        queryConfig: {
          enabled: !!context?.entityId && !!context?.entityType,
          onSuccess: () => {
            if (!entityReFetchStatus?.current) {
              entityReFetchStatus.current = false;
              setAppliedFiltersSlugs([]);
            }
          },
        },
      },
      null,
      language,
    ),
  );

  useEffect(() => {
    if (context?.entityId && !pageEntityLoading) {
      scrollTo();
    }
  }, [context?.entityId, pageEntityLoading, scrollTo]);

  const organizationType = useAppliedOrgTypeFilter({ slug: context?.orgTypeSlug });
  const artistProfession = useAppliedProfessionFilter(
    context.filters?.[FILTER_SLUG_TYPE[FILTER_TYPES.PROFESSION]]?.[0],
  );

  const { data: country } = useQuery(
    globalQueries.getLocation({
      type: LOCATION_TYPE.COUNTRY,
      filters: {
        slug: countrySlug,
      },
      queryConfig: { enabled: !!countrySlug },
    }),
  );

  const refetchEntityDetails = useCallback(() => {
    entityReFetchStatus.current = true;
    refetchEntity();
  }, [refetchEntity]);

  useEffect(() => {
    const entityType = context?.entityType;
    if (nameToSearchEntityType[entityType] && pageEntity) {
      pushVisitHistoryItem(pageEntity, nameToSearchEntityType[entityType]);
    }
  }, [context?.entityType, pageEntity]);

  const removeLocaleFromPath = useCallback(path => removeLanguageSuffixFromPath(path, language), [language]);

  const updatePageURL = useCallback((linkProps, shouldReplace = false) => {
    const action = shouldReplace ? 'replace' : 'push';
    if (linkProps?.href) {
      let path = linkProps?.as;

      if (!path && linkProps?.url) {
        path = linkProps?.url?.replace(/(?:\/\w{2})+$/g, '');
      }

      Router[action](linkProps.href, path, { shallow: linkProps.shallow ?? true });
    }
  }, []);

  const getLinkPropsWithPermissions = useCallback(
    args => {
      const { baseRoute, entityType, entity, edit, pro } = args || {};

      if (
        entityType === ENTITY_TYPE.ARTIST &&
        entity &&
        !edit &&
        entity?.validationStatus?.id !== VALIDATION_STATUS.HIDDEN
      ) {
        const hasCastingToolAccess = permissions?.castingToolPermissions?.hasAccess ?? false;
        const shouldSkipProPage =
          pro === false ||
          (pro !== true && context?.entityType === ENTITY_TYPE.ARTIST && context?.entityId === entity?.id);
        const newBaseRoute =
          !shouldSkipProPage && hasCastingToolAccess && !baseRoute && pro !== false
            ? BASE_PAGE_ROUTES.CASTING
            : baseRoute;

        return getLinkProps({ ...args, baseRoute: newBaseRoute });
      }

      return getLinkProps(args);
    },
    [permissions, context?.entityType, context?.entityId],
  );

  const updateQueryParams = useCallback(
    ({ deleteParams, appendParams, replaceExisting = false }) => {
      const [asPath, queryString] = Router.asPath.split('?');

      const currentQueryParams = new URLSearchParams(replaceExisting ? '' : queryString);

      if (deleteParams?.length > 0) {
        deleteParams.forEach(param => currentQueryParams.delete(param));
      }

      if (appendParams) {
        Object.entries(appendParams).forEach(([key, value]) => {
          if (value !== undefined) {
            currentQueryParams.set(key, value);
          }
        });
      }

      currentQueryParams.sort();
      const updateQueryString = currentQueryParams.toString();
      const newAsPath = removeLocaleFromPath(asPath);

      Router.replace(
        {
          href: Router.pathname,
          query: Object.fromEntries(currentQueryParams),
        },
        `${newAsPath}${updateQueryString ? `?${updateQueryString}` : ''}`,
        { shallow: true },
      );
    },
    [removeLocaleFromPath],
  );

  const scrollSectionIntoView = useCallback(
    sectionId => {
      if (sectionId && typeof window !== 'undefined') {
        const element = document?.querySelectorAll(`[data-scroll-id=${sectionId}]`)?.[0];

        if (element) {
          const scrollOffset = parseInt(element.getAttribute('data-scroll-offset') || 0, 10);
          const elementOffset = element.offsetTop - scrollOffset;
          scrollTo({ top: elementOffset, force: true });

          updateQueryParams({ deleteParams: [VALID_QUERY_PARAMS.SCROLL_TO] });

          return true;
        }
      }

      return false;
    },
    [scrollTo, updateQueryParams],
  );

  useEffect(() => {
    if (scrollToSection) {
      scrollSectionIntoView(scrollToSection);
    }
  }, [scrollToSection, scrollSectionIntoView]);

  const [isHeaderVisible, setHeaderVisibility] = useState(true);
  const [isGlobalNavigationVisible, setGlobalNavigationVisibility] = useState(true);
  const showGlobalHeader = useCallback(() => setHeaderVisibility(true), []);
  const hideGlobalHeader = useCallback(() => setHeaderVisibility(false), []);
  const showGlobalNavigation = useCallback(() => setGlobalNavigationVisibility(true), []);
  const hideGlobalNavigation = useCallback(() => setGlobalNavigationVisibility(false), []);

  const getNavigationState = () => navigation.current || [];

  const getHistoryIndex = useCallback(
    (native = true) => {
      const navigationState = getNavigationState();
      let lastIndex = navigationState.length - 1;

      if (!native) {
        const currentPathname = router.pathname;

        while (navigationState.length > 0 && navigationState?.[lastIndex]?.href === currentPathname) {
          lastIndex -= 1;
        }

        return lastIndex;
      }

      return lastIndex;
    },
    [router.pathname],
  );

  const getBackLinkProps = useCallback(
    (native = true) => {
      const navigationState = getNavigationState();
      const historyIndex = getHistoryIndex(native);
      const shallow = native;

      const linkProps = navigationState[historyIndex];

      if (linkProps) {
        return {
          as: removeLocaleFromPath(linkProps.as),
          href: linkProps.href,
          shallow,
        };
      }

      return getLinkPropsWithPermissions({ shallow, onlyLinkProps: true });
    },
    [getHistoryIndex, removeLocaleFromPath, getLinkPropsWithPermissions],
  );

  const navigateToHistory = useCallback(
    (native = true) => {
      const navigationState = getNavigationState();
      const historyIndex = getHistoryIndex(native);
      const shallow = native;

      const finalHistory = navigationState.slice(0, historyIndex + 1);
      const finalHistoryLength = finalHistory?.length;

      if (finalHistoryLength > 0) {
        navigation.current = finalHistory;
        if (window) {
          window.history.go(historyIndex - navigationState?.length);
        }
      } else {
        updatePageURL(getLinkPropsWithPermissions({ shallow, onlyLinkProps: true }));
      }
    },
    [getHistoryIndex, updatePageURL, getLinkPropsWithPermissions],
  );

  const onRouteChange = useCallback(
    newPathname => {
      if (navigation.current) {
        const linkProps = {
          as: router.asPath,
          href: router.pathname,
        };

        const navigationState = getNavigationState();

        if (navigationState.length > 0) {
          const { as: lastAs } = navigationState[navigationState.length - 1];

          if (isEqual(lastAs, newPathname)) {
            navigationState.pop();
          } else {
            navigationState.push(linkProps);
          }
        } else {
          navigationState.push(linkProps);
        }

        navigation.current = navigationState.slice(-HISTORY_LIMIT);
      }
    },
    [router],
  );

  useEffect(() => {
    router.events.on('routeChangeStart', onRouteChange);
    router.events.on('routeChangeComplete', showGlobalHeader);
    router.events.on('routeChangeComplete', showGlobalNavigation);

    return () => {
      router.events.off('routeChangeStart', onRouteChange);
      router.events.off('routeChangeComplete', showGlobalHeader);
      router.events.off('routeChangeComplete', showGlobalNavigation);
    };
  }, [router, showGlobalHeader, onRouteChange, showGlobalNavigation]);

  const hasAppliedFilters = useMemo(() => Object.keys(context.filters)?.length > 0, [context.filters]);

  const getCurrentValidFilters = useCallback(
    (filters, validFilters) => {
      const acceptableFilterKeys = (isEmpty(validFilters) ? context.validFilters : validFilters)?.map(
        type => FILTER_SLUG_TYPE[type],
      );

      return pick(filters, acceptableFilterKeys);
    },
    [context.validFilters],
  );

  const cleanFilterObject = useCallback(
    (obj, validFilters) =>
      Object.keys(getCurrentValidFilters(obj, validFilters)).reduce((acc, key) => {
        const value = obj[key];

        if (Array.isArray(value)) {
          const cleanedArray = value.filter(item => item !== null && item !== undefined && item !== '');

          if (cleanedArray.length > 0) {
            acc[key] = cleanedArray;
          }
        } else if (typeof value === 'object' && value !== null) {
          acc[key] = cleanFilterObject(value, validFilters);
        } else if (value !== null && value !== undefined && value !== '') {
          acc[key] = typeof value === 'number' ? `${value}` : value;
        }

        return acc;
      }, {}),
    [getCurrentValidFilters],
  );

  const checkIfFiltersChanged = useCallback(
    (newFilters = {}) => {
      const appliedFilters = context.filters;

      if (isEqual(Object.keys(context.filters), Object.keys(newFilters))) {
        return (
          Object.values(
            mapValues(newFilters, (values, key) => {
              if (
                [
                  URL_SLUG_TYPES.SINCE_YEAR,
                  URL_SLUG_TYPES.YEAR,
                  URL_SLUG_TYPES.MONTH_YEAR,
                  URL_SLUG_TYPES.DATE,
                  URL_SLUG_TYPES.BOOLEAN_SEARCH,
                  URL_SLUG_TYPES.PAGE,
                  URL_SLUG_TYPES.LETTER,
                  URL_SLUG_TYPES.QUERY,
                ].includes(key)
              ) {
                return !isEqual(values, appliedFilters?.[key]);
              }

              return !isEqual(
                values?.map(filter => filter?.id),
                appliedFilters?.[key]?.map(filter => filter?.id),
              );
            }),
          )?.findIndex(isChanged => isChanged) > -1
        );
      }

      return true;
    },
    [context.filters],
  );

  const getBaseRoute = useCallback(() => context.basePath, [context.basePath]);

  const onApplyFilters = useCallback(
    ({ filters, mainPath, subPath, entityType, entity, ...rest }) => {
      const filterEntityType = entityType ?? context.entityType;
      const filterEntityId = entityType ? entity?.id : context?.entityId;
      const validFilters = getValidFilters({ entityId: filterEntityId, entityType: filterEntityType, mainPath });
      const cleanedFilters = cleanFilterObject(filters, validFilters);
      const hasFiltersChanged = checkIfFiltersChanged(cleanedFilters);

      if (hasFiltersChanged || mainPath !== context.mainPath || subPath !== context.subPath) {
        track.click(trackSearchGo(cleanedFilters), GOOGLE_OLD_TRACKING_SERVICES);
        const newMainPath = mainPath || context.mainPath;
        const linkProps = getLinkPropsWithPermissions({
          baseRoute: context.basePath,
          path: subPath ? `${newMainPath}/${subPath}` : newMainPath,
          entityType,
          entity,
          ...rest,
          filters: cleanedFilters,
        });

        updatePageURL(linkProps);
        scrollTo({ behavior: 'auto' });
      }
    },
    [
      track,
      context.entityId,
      context.entityType,
      context.basePath,
      context.mainPath,
      context.subPath,
      cleanFilterObject,
      checkIfFiltersChanged,
      updatePageURL,
      getLinkPropsWithPermissions,
      scrollTo,
    ],
  );

  const onResetFilters = useCallback(
    (props = {}, keepFilterKeys = []) => {
      track.click(trackClearForm(), GOOGLE_OLD_TRACKING_SERVICES);
      onApplyFilters({ ...(props || {}), filters: pick(context.filters, keepFilterKeys) });
    },
    [track, context.filters, onApplyFilters],
  );

  const onUpdateAppliedFilters = useCallback(
    filters => {
      if (filters && !isEqual(appliedFilterSlugs, filters)) {
        setAppliedFiltersSlugs(filters || []);
      }
    },
    [appliedFilterSlugs, setAppliedFiltersSlugs],
  );

  const navigateToTab = useCallback(
    (tab, { entityType, entity, pro }) => {
      const linkProps = getLinkPropsWithPermissions({
        entityType,
        entity,
        pro,
        baseRoute: getBaseRoute(),
        path: tab === ENTITY_MAIN_TABS.HOME ? undefined : tab,
      });

      updatePageURL(linkProps);
    },
    [getBaseRoute, getLinkPropsWithPermissions, updatePageURL],
  );

  const filterTypeUsage = useMemo(() => {
    const {
      castingToolPermissions: { hasAccess: hasCastingToolAccess },
    } = permissions;
    const ctxFilterTypeUsage = context?.filterTypeUsage || {};
    const { advancedFilterTypes, freeFilterTypes } = ctxFilterTypeUsage;

    let limitExceeded = false;
    let limitReached = false;

    if (!hasCastingToolAccess) {
      if (isLoggedIn) {
        limitReached = advancedFilterTypes > 0;
        limitExceeded = advancedFilterTypes > 1;
      } else {
        limitReached = freeFilterTypes > 0 || advancedFilterTypes > 0;
        limitExceeded = freeFilterTypes > 1 || advancedFilterTypes > 1;
      }
    }

    return {
      ...ctxFilterTypeUsage,
      limitReached,
      limitExceeded,
    };
  }, [isLoggedIn, permissions, context?.filterTypeUsage]);

  const getFilterUsageState = useCallback(
    ({ type, upcoming = false }) => {
      const { usedAdvancedFilters, usedFreeFilters, limitReached, limitExceeded, focusFilterType } = filterTypeUsage;
      const {
        castingToolPermissions: { hasAccess: hasCastingToolAccess },
      } = permissions;
      const filterSlug = FILTER_SLUG_TYPE[type];
      const isAdvancedFilter = ADVANCED_FILTER_TYPES[context?.entityType]?.includes(type);

      let isLocked = isAdvancedFilter && !hasCastingToolAccess;

      if (isLoggedIn && !hasCastingToolAccess) {
        if (upcoming) {
          isLocked = limitReached && !usedAdvancedFilters?.includes(filterSlug);
        }
      }

      let isRestricted = isLocked;

      if (limitExceeded && focusFilterType === type) {
        isRestricted = true;
      } else if (limitReached) {
        if (isLoggedIn) {
          isRestricted = isAdvancedFilter && !hasCastingToolAccess && !usedAdvancedFilters?.includes(filterSlug);
        } else {
          isRestricted = !usedFreeFilters?.includes(filterSlug);
        }
      }

      return {
        isAdvancedFilter,
        isLoggedIn,
        isRestricted,
        limitReached,
      };
    },
    [isLoggedIn, context?.entityType, permissions, filterTypeUsage],
  );

  const utmParams = useMemo(() => {
    if (Object.keys(context?.utmParams).length) {
      return context.utmParams;
    }

    if (serverCookies[OB_UTM]) {
      return getCampaignAttributionDetails(serverCookies[OB_UTM]);
    }

    return {};
  }, [context?.utmParams, serverCookies]);

  const { enrichedFilters, enrichedFilterParams } = useMemo(() => {
    const overrideFilters = {};
    const overrideFilterParams = {};

    const ORG_TYPE_FILTER_SLUG = FILTER_SLUG_TYPE[FILTER_TYPES.ORGANIZATION_TYPE];
    if (organizationType) {
      if (context.filters?.[ORG_TYPE_FILTER_SLUG]) {
        overrideFilters[ORG_TYPE_FILTER_SLUG] = context.filters[ORG_TYPE_FILTER_SLUG]?.map(orgTypeFilter => {
          if (orgTypeFilter?.slug === organizationType?.slug) {
            return { id: organizationType?.id, slug: organizationType?.slug, name: organizationType?.name };
          }

          return orgTypeFilter;
        });
      }

      if (filterParams?.[ORG_TYPE_FILTER_SLUG]) {
        overrideFilterParams[ORG_TYPE_FILTER_SLUG] = [organizationType?.id];
      }
    }

    const ARTIST_PROFESSION_FILTER_SLUG = FILTER_SLUG_TYPE[FILTER_TYPES.PROFESSION];
    if (artistProfession) {
      if (context.filters?.[ARTIST_PROFESSION_FILTER_SLUG]) {
        overrideFilters[ARTIST_PROFESSION_FILTER_SLUG] = context.filters[ARTIST_PROFESSION_FILTER_SLUG]?.map(
          professionFilter => {
            if (professionFilter?.slug === artistProfession?.slug) {
              return {
                id: artistProfession?.id,
                slug: artistProfession?.slug,
                name: artistProfession?.name,
                stats: artistProfession?.stats,
                parentProfession: artistProfession?.parentProfession,
              };
            }

            return professionFilter;
          },
        );
      }

      if (filterParams?.[ARTIST_PROFESSION_FILTER_SLUG]) {
        overrideFilterParams[ARTIST_PROFESSION_FILTER_SLUG] = [artistProfession?.id];
      }
    }

    return {
      enrichedFilters: {
        ...context.filters,
        ...overrideFilters,
      },
      enrichedFilterParams: {
        ...filterParams,
        ...overrideFilterParams,
      },
    };
  }, [organizationType, artistProfession, context.filters, filterParams]);

  const navigate = useMemo(
    () => ({
      state: navigation.current,
      back: navigateToHistory,
      getBackLinkProps,
      to: updatePageURL,
      toTab: navigateToTab,
      getLinkProps: getLinkPropsWithPermissions,
      updateQueryParams,
      scrollTo,
      removeLocaleFromPath,
    }),
    [
      getBackLinkProps,
      getLinkPropsWithPermissions,
      navigateToHistory,
      navigateToTab,
      removeLocaleFromPath,
      scrollTo,
      updatePageURL,
      updateQueryParams,
    ],
  );

  return (
    <PageContext.Provider
      value={{
        ...context,
        filters: enrichedFilters,
        filterParams: enrichedFilterParams,
        orgTypeId: organizationType?.id, // TODO: @junaidhamzae Remove dependency on this variable
        organizationType,
        country,
        utmParams,
        isFingerPrintEnabled,
        entity: pageEntity,
        isLoading: pageEntityLoading,
        refetchEntityDetails,
        baseRoute: getBaseRoute(),
        activeTabKey: context.mainPath, // TODO: Remove | duplicate of mainPath
        hasAppliedFilters,
        applyFilters: onApplyFilters,
        resetFilters: onResetFilters,
        appliedFilterSlugs,
        setAppliedFilterSlugs: onUpdateAppliedFilters,
        getFilterUsageState,
        filterTypeUsage,
        showGlobalHeader,
        hideGlobalHeader,
        isHeaderVisible,
        isGlobalNavigationVisible,
        showGlobalNavigation,
        hideGlobalNavigation,
        language,
        navigate,
        scrollSectionIntoView,
        editModeToggle,
        setEditModeToggle,
        permissions,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

const usePageContext = () => useContext(PageContext);

export default usePageContext;
