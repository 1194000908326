import { useEffect } from 'react';
import useTracking from 'components/Globals/Analytics';
import { SECTIONS } from 'components/Globals/Analytics/constants';

function PwaTracker() {
  const track = useTracking();

  useEffect(() => {
    const matchStandalone = window.matchMedia('(display-mode: standalone)');

    const handlePwaView = actionType => {
      track.actionCallback({
        section: SECTIONS.VIEW_PWA,
        meta: { actionType },
      });
    };

    if (matchStandalone.matches) {
      handlePwaView('mount'); // Trigger when already in standalone mode on mount
    }

    const handleChange = event => {
      if (event.matches) {
        handlePwaView('transfer'); // Trigger when entering standalone mode
      }
    };

    matchStandalone.addEventListener('change', handleChange);

    return () => {
      matchStandalone.removeEventListener('change', handleChange);
    };
  }, []);

  return null;
}

export default PwaTracker;
