/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import rootReducer from './reducer';
import appReducer from '../containers/App/reducer';
import HomeReducer, { key as HomeReducerKey } from '../containers/Home/reducer';
import ProductionUpdateSteps, { key as ProductionUpdateStepsKey } from '../containers/ProductionUpdateSteps/reducer';
import Organization, { key as OrganizationReducerKey } from '../containers/Organizations/reducer';
import Artist, { key as ArtistKey } from '../containers/Artist/reducer';
import AgencyReducer, { key as AgencyKey } from '../containers/Managers/reducer';
import videosReducer from '../containers/Videos/reducer';
import AccountSettingsReducer, {
  key as AccountSettingsKey,
} from '../containers/UserContainers/AccountSettingsContainer/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const dynamicReducer = combineReducers({
    global: rootReducer,
    App: appReducer,
    AccountSettings: AccountSettingsReducer,
    Videos: videosReducer,
    ...injectedReducers,
  });

  return dynamicReducer;
}

export const reducersList = [
  // { key: regKey, reducer: registrationReducer },
  { key: HomeReducerKey, reducer: HomeReducer },
  { key: ProductionUpdateStepsKey, reducer: ProductionUpdateSteps },
  { key: AccountSettingsKey, reducer: AccountSettingsReducer },
  { key: OrganizationReducerKey, reducer: Organization },
  { key: ArtistKey, reducer: Artist },
  { key: AgencyKey, reducer: AgencyReducer },
];
