import { TP } from 'constants/index';

export const search_types = {
  PROFILES: 'profiles',
  PRODUCTIONS: 'productions',
  COMPANIES: 'company',
  VENUES: 'venues',
  AGENCIES: 'agencies',
  COMPOSERS: 'composers',
  WORKS: 'works',
  FESTIVALS: 'festival',
  ALL: 'All',
  UNKNOWN: 'unknown',
  OPERA_ONLINE: 'videos',
};

export const ADVANCED_SEARCH_TABS_NAMES = {
  ALL: 'All',
  PERFORMANCES: 'Performances',
  ARTISTS: 'Artists',
  COMPANIES: 'Companies',
  FESTIVAL: 'Festivals',
  MANAGER: 'Managers',
  VENUES: 'Venues',
  MUSICAL_WORKS: 'Musical Works',
  OPERA_ONLINE: 'Opera Online',
};

export const INDEX_PAGE_SEARCH_TABS_MAPPING = {
  '/search': ADVANCED_SEARCH_TABS_NAMES.ALL,
  '/artists': ADVANCED_SEARCH_TABS_NAMES.ARTISTS,
  '/productions': ADVANCED_SEARCH_TABS_NAMES.PERFORMANCES,
  '/productions/search': ADVANCED_SEARCH_TABS_NAMES.PERFORMANCES,
  '/companies/[[...action]]': ADVANCED_SEARCH_TABS_NAMES.COMPANIES,
  '/venues': ADVANCED_SEARCH_TABS_NAMES.VENUES,
  '/venues/search': ADVANCED_SEARCH_TABS_NAMES.VENUES,
  '/venues/[country]': ADVANCED_SEARCH_TABS_NAMES.VENUES,
  '/managers': ADVANCED_SEARCH_TABS_NAMES.MANAGER,
  '/managers/search': ADVANCED_SEARCH_TABS_NAMES.MANAGER,
  '/managers/[id]': ADVANCED_SEARCH_TABS_NAMES.MANAGER,
  '/works': ADVANCED_SEARCH_TABS_NAMES.MUSICAL_WORKS,
  '/works/search': ADVANCED_SEARCH_TABS_NAMES.MUSICAL_WORKS,
  '/festivals/[[...action]]': ADVANCED_SEARCH_TABS_NAMES.FESTIVAL,
  '/videos': ADVANCED_SEARCH_TABS_NAMES.OPERA_ONLINE,
  '/videos/search': ADVANCED_SEARCH_TABS_NAMES.OPERA_ONLINE,
};

export const ADVANCED_SEARCH_TABS_NAMES_LABELS = {
  [ADVANCED_SEARCH_TABS_NAMES.ALL]: `${TP}.FN_FILTER_ALL`,
  [ADVANCED_SEARCH_TABS_NAMES.PERFORMANCES]: `${TP}.FN_PERFORMANCES`,
  [ADVANCED_SEARCH_TABS_NAMES.ARTISTS]: `${TP}.m_ARTISTS`,
  [ADVANCED_SEARCH_TABS_NAMES.OPERA_ONLINE]: `${TP}.OPERA_ONLINE`,
  [ADVANCED_SEARCH_TABS_NAMES.COMPANIES]: `${TP}.m_OPCOS`,
  [ADVANCED_SEARCH_TABS_NAMES.FESTIVAL]: `${TP}.m_FESTIVALS`,
  [ADVANCED_SEARCH_TABS_NAMES.MUSICAL_WORKS]: `${TP}.FN_MUSICAL_WORKS_SECTION_NAME`,
  // [ADVANCED_SEARCH_TABS_NAMES.COMPOSERS]: `${TP}.m_COMPOSERS`, // TODO: Excluded from phase 1 release
  // [ADVANCED_SEARCH_TABS_NAMES.HIGHLIGHTS]: `${TP}.m_HIGHLIGHTS`, // TODO: Excluded from phase 1 release
  // [ADVANCED_SEARCH_TABS_NAMES.NEW_RARE]: `${TP}.NEW_AND_RARE`, // TODO: Excluded from phase 1 release
  [ADVANCED_SEARCH_TABS_NAMES.MANAGER]: `${TP}.AS_MANAGER`,
  // [ADVANCED_SEARCH_TABS_NAMES.COMPETITIONS]: `${TP}.m_COMPETITIONS`, // TODO: Excluded from phase 1 release
  [ADVANCED_SEARCH_TABS_NAMES.VENUES]: `${TP}.m_VENUES`,
  // [ADVANCED_SEARCH_TABS_NAMES.SEASONS]: `${TP}.m_SEASON`, // TODO: Excluded from phase 1 release
  // [ADVANCED_SEARCH_TABS_NAMES.STATISTICS]: `${TP}.m_STATISTICS`, // TODO: Excluded from phase 1 release
};

export const ADVANCED_SEARCH_TABS_LIST = [...Object.values(ADVANCED_SEARCH_TABS_NAMES)];

export const OPERA_ONLINE_TYPES = {
  COMPOSER: 'composer',
  MUSICAL_WORK: 'work',
  ORGANIZATION: 'organization',
  ARTISTS: 'artists',
  RESET: 'reset',
};

export const STATISTICS_TYPES = {
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  SEASONS: 'seasons',
  MUSICAL_WORK: 'work',
  COUNTRY: 'country',
  LINES: 'lines',
  RESET: 'reset',
};

export const COMPETITIONS_TYPES = {
  COMPETITION: 'competition',
  LOCATION: 'location',
  AGE_LIMIT_MAN: 'ageLimitMan',
  AGE_LIMIT_WOMAN: 'ageLimitWomen',
  DATE: 'date',
  STATUS: 'status',
  RESET: 'reset',
};

export const PERFORMANCE_TYPES = {
  LOCATION: 'location',
  ORGANIZATION: 'organization',
  COMPOSER: 'composer',
  MUSICAL_WORK: 'musicalWork',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  RESET: 'reset',
};
