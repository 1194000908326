import { call, put, select, takeEvery, takeLatest, cancelled } from 'redux-saga/effects';
import get from 'lodash/get';
import merge from 'lodash/merge';

import { fetchAbortController } from 'utils/globals/app';
import { TP } from 'constants/index';

import { i18n } from '../../src/i18n';
import * as API from '../../utils/API';
import {
  checkUpdatePasswordKeyAction,
  createEntity,
  deleteEntity,
  enqueueSnackbarAction,
  getAgenciesAction,
  getAggregationsAction,
  getCompaniesAction,
  getVenuesSearchAction,
  getList,
  getOne,
  getProfilesAction,
  getWholeList,
  resetPasswordAction,
  updateOne,
  updatePasswordAction,
  getDynamicCountriesAction,
} from './actions';

import { selectUserData } from './selectors';
import { formatResponseToEntity, formatResponseToList } from '../../utils/crudUtils';
import { COUNTRIES_ENDPOINT } from '../../constants/endpointConstants';
import { VALID_ENTITY_FILTER } from '../ProductionUpdateSteps/constants';

export function* getUserFromData() {
  return yield select(selectUserData());
}

export function* resetPassword({ payload, serverCookies }) {
  try {
    yield call(API.resetUserPassword, payload, serverCookies);
    yield put(resetPasswordAction.success());
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.AS_RESET_PASSWORD_SENT_NOTIFICATION`, {}),
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (err) {
    yield put(resetPasswordAction.failure(err.message));
  } finally {
    yield put(resetPasswordAction.fulfill());
  }
}

export function* updatePassword({ payload, serverCookies }) {
  try {
    yield call(API.updateUserPassword, payload.key, payload.password, serverCookies);
    yield put(updatePasswordAction.success());
  } catch (err) {
    yield put(updatePasswordAction.failure(err.message));
  } finally {
    yield put(updatePasswordAction.fulfill());
  }
}

export function* checkPasswordKey({ payload, serverCookies }) {
  try {
    yield call(API.checkPasswordKeyStatus, payload, serverCookies);
    yield put(checkUpdatePasswordKeyAction.success());
  } catch (err) {
    yield put(checkUpdatePasswordKeyAction.failure(err.message));
  } finally {
    yield put(checkUpdatePasswordKeyAction.fulfill());
  }
}

export function* fetchAggregations({ payload, serverCookies }) {
  const { query, pagination, endpoint } = payload;
  const abortController = fetchAbortController();
  const signal = abortController ? abortController.signal : null;
  try {
    const response = yield call(API.getAggregations, endpoint, query, pagination, signal, serverCookies);
    yield put(getAggregationsAction.success({ ...get(response, 'data'), aggregationType: query?.aggregation_type }));
  } catch (err) {
    if (yield cancelled() && abortController) {
      abortController.abort();
    }
    yield put(getAggregationsAction.failure(err.message));
  } finally {
    if (yield cancelled() && abortController) {
      // Cancel the API call if the saga was cancelled
      abortController.abort();
    }
    yield put(getAggregationsAction.fulfill());
  }
}

export function* fetchAgencies({ payload }) {
  try {
    const response = yield call(API.getAgencies, {
      query: payload.query || payload,
      validation_status: 'pending,processing,approved',
      ...get(payload, 'query', {}),
    });
    yield put(getAgenciesAction.success(get(response, 'data.data')));
  } catch (err) {
    yield put(getAgenciesAction.failure(err.message));
  } finally {
    yield put(getAgenciesAction.fulfill());
  }
}

export function* fetchCompanies({ payload, serverCookies }) {
  try {
    const response = yield call(API.getCompanies, payload.query, payload.pagination, serverCookies);
    yield put(getCompaniesAction.success(get(response, 'data.data')));
    if (payload.callback) {
      yield call(payload.callback, get(response, 'data.data'));
    }
  } catch (err) {
    yield put(getCompaniesAction.failure(err.message));
  } finally {
    yield put(getCompaniesAction.fulfill());
  }
}

export function* fetchVenues({ payload, serverCookies }) {
  try {
    const response = yield call(API.getVenues, payload.query, payload.pagination, serverCookies);
    yield put(getVenuesSearchAction.success(get(response, 'data.data')));
  } catch (err) {
    yield put(getVenuesSearchAction.failure(err.message));
  } finally {
    yield put(getVenuesSearchAction.fulfill());
  }
}

export function* fetchProfilesList({ payload }) {
  try {
    const response = yield call(
      API.searchProfiles,
      payload?.type,
      payload?.query,
      payload?.exclude_id,
      payload?.pagination,
      payload?.filters,
      payload?.from,
    );
    yield put(getProfilesAction.success(get(response, 'data.data')));
    if (payload.callback) {
      yield call(payload.callback, get(response, 'data.data'));
    }
  } catch (err) {
    yield put(getProfilesAction.failure(err.message));
  } finally {
    yield put(getProfilesAction.fulfill());
  }
}

export function* getListSaga({ payload, serverCookies }) {
  const { endpoint, sagaRoutine, params, callback, notifyOnError } = payload;
  if (endpoint === null || sagaRoutine === null) {
    throw new Error('Specify endpoint and sagaRoutine');
  }
  yield put(sagaRoutine.trigger(payload));
  try {
    yield put(sagaRoutine.request());
    const response = yield call(API.getList, endpoint, params, serverCookies);
    const list = formatResponseToList(response);
    yield put(sagaRoutine.success(list));
    if (Boolean(callback) && typeof callback === 'function') callback(list);
  } catch (err) {
    if (notifyOnError) {
      yield put(
        enqueueSnackbarAction({
          message: i18n.t(`${TP}.FN_ERROR_FETCHING_DATA`, { endpoint, error: err?.responseText || err?.message }),
          options: {
            variant: 'error',
          },
        }),
      );
    }
    yield put(sagaRoutine.failure(err.message));
  } finally {
    yield put(sagaRoutine.fulfill());
  }
}

export function* getWholeListSaga({ payload, serverCookies }) {
  // TODO: Implement dynamic "get all" flow (/countries/?limit=0 => /countries/?limit=COUNT)
  const payloadWithPagination = merge(payload, { params: { pagination: { page: 1, limit: 9999 } } });
  yield call(getListSaga, { payload: payloadWithPagination, serverCookies });
}

export function* getOneSaga({ payload, serverCookies }) {
  const { endpoint, sagaRoutine, id, params } = payload;
  if (endpoint === null || sagaRoutine === null || id === null) {
    throw new Error('Specify endpoint, id, sagaRoutine');
  }
  yield put(sagaRoutine.trigger());
  try {
    yield put(sagaRoutine.request());
    const response = yield call(API.getOne, endpoint, id, params, serverCookies);
    const data = formatResponseToEntity(response);
    yield put(sagaRoutine.success(data));
  } catch (err) {
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_FETCHING_DATA`, { endpoint, error: err?.responseText || err?.message }),
        options: {
          variant: 'error',
        },
      }),
    );
    yield put(sagaRoutine.failure(err.message));
  } finally {
    yield put(sagaRoutine.fulfill());
  }
}

export function* updateOneSaga({ payload, serverCookies }) {
  const { endpoint, sagaRoutine, id, params, files, successMessage, errorMessage, callback } = payload;
  if (endpoint === null || id === null) {
    throw new Error('Specify endpoint, id, sagaRoutine');
  }
  if (sagaRoutine) yield put(sagaRoutine.trigger(payload));
  try {
    if (sagaRoutine) yield put(sagaRoutine.request());
    const response = yield call(API.updateOne, endpoint, id, params, files, serverCookies);
    const data = formatResponseToEntity(response);
    if (sagaRoutine) yield put(sagaRoutine.success(data));
    if (Boolean(callback) && typeof callback === 'function') callback(data?.entity);

    if (!payload?.noSuccessMessage) {
      yield put(
        enqueueSnackbarAction({
          message: successMessage || i18n.t(`${TP}.FN_SUCCESS_UPDATING_DATA`, { endpoint }),
          options: {
            variant: 'success',
          },
        }),
      );
    }
  } catch (err) {
    yield put(
      enqueueSnackbarAction({
        message:
          errorMessage ||
          i18n.t(`${TP}.FN_ERROR_UPDATING_DATA`, { endpoint, error: err?.responseText ?? err?.message }),
        options: {
          variant: 'error',
        },
      }),
    );
    if (sagaRoutine) yield put(sagaRoutine.failure(err.message));
  } finally {
    if (sagaRoutine) yield put(sagaRoutine.fulfill());
  }
}

export function* deleteOneSaga({ payload, serverCookies }) {
  const { endpoint, sagaRoutine, id, params, successMessage, errorMessage, callback } = payload;
  if (endpoint === null || id === null) {
    throw new Error('Specify endpoint, id');
  }
  if (sagaRoutine) {
    yield put(sagaRoutine.trigger(payload));
  }
  try {
    if (sagaRoutine) {
      yield put(sagaRoutine.request());
    }
    yield call(API.deleteOne, endpoint, id, params, serverCookies);
    if (sagaRoutine) {
      yield put(sagaRoutine.success({ id }));
    }
    if (Boolean(callback) && typeof callback === 'function') callback(id);
    yield put(
      enqueueSnackbarAction({
        message: successMessage || i18n.t(`${TP}.FN_SUCCESS_DELETING_DATA`, { endpoint }),
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (err) {
    yield put(
      enqueueSnackbarAction({
        message:
          errorMessage ||
          i18n.t(`${TP}.FN_ERROR_DELETING_DATA`, { endpoint, error: err?.responseText || err?.message }),
        options: {
          variant: 'error',
        },
      }),
    );
    if (sagaRoutine) {
      yield put(sagaRoutine.failure(err.message));
    }
  } finally {
    if (sagaRoutine) {
      yield put(sagaRoutine.fulfill());
    }
  }
}

export function* createEntitySaga({ payload, serverCookies }) {
  const {
    endpoint,
    sagaRoutine,
    params,
    successMessage,
    errorMessageConverter,
    onError,
    errorMessage,
    callback,
    files,
    skipDefaultErrorMessage = false,
  } = payload;
  if (endpoint === null) {
    throw new Error('Specify endpoint, id');
  }
  if (sagaRoutine) {
    yield put(sagaRoutine.trigger(payload));
  }
  try {
    if (sagaRoutine) {
      yield put(sagaRoutine.request());
    }
    const response = yield call(API.create, endpoint, params, files, serverCookies);
    const data = formatResponseToEntity(response);
    if (sagaRoutine) {
      yield put(sagaRoutine.success(data));
    }

    if (Boolean(callback) && typeof callback === 'function') callback(data?.entity);

    if (!payload?.noSuccessMessage) {
      yield put(
        enqueueSnackbarAction({
          message: successMessage || i18n.t(`${TP}.FN_SUCCESS_CREATING_DATA`, { endpoint }),
          options: {
            variant: 'success',
          },
        }),
      );
    }
  } catch (err) {
    let httpMessage = err?.responseText?.message || err?.responseText || err?.message;

    if (typeof errorMessageConverter === 'function') {
      httpMessage = errorMessageConverter(err);
    }
    if (typeof onError === 'function') {
      onError(err);
    }

    if (!skipDefaultErrorMessage) {
      yield put(
        enqueueSnackbarAction({
          message: errorMessage || i18n.t(`${TP}.FN_ERROR_CREATING_DATA`, { endpoint, error: httpMessage }),
          options: {
            variant: 'error',
          },
        }),
      );
    }
    if (sagaRoutine) {
      yield put(sagaRoutine.failure(err.message));
    }
  } finally {
    if (sagaRoutine) {
      yield put(sagaRoutine.fulfill());
    }
  }
}

export function* fetchDynamicCountries({ payload, serverCookies }) {
  try {
    const response = yield call(
      API.getList,
      COUNTRIES_ENDPOINT,
      {
        queryParams: {
          validation_status: VALID_ENTITY_FILTER,
          ...(payload?.params || {}),
        },
      },
      serverCookies,
    );
    yield put(getDynamicCountriesAction.success(formatResponseToList(response)));
  } catch (err) {
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_FETCHING_DATA`, {
          endpoint: COUNTRIES_ENDPOINT,
          error: err?.responseText || err?.message,
        }),
        options: {
          variant: 'error',
        },
      }),
    );
    yield put(getDynamicCountriesAction.failure(err.message));
  } finally {
    yield put(getDynamicCountriesAction.fulfill());
  }
}

export default function* appSaga() {
  yield takeLatest(resetPasswordAction.TRIGGER, resetPassword);
  yield takeLatest(updatePasswordAction.TRIGGER, updatePassword);
  yield takeLatest(checkUpdatePasswordKeyAction.TRIGGER, checkPasswordKey);
  yield takeEvery(getAggregationsAction.TRIGGER, fetchAggregations);
  yield takeLatest(getAgenciesAction.TRIGGER, fetchAgencies);
  yield takeLatest(getCompaniesAction.TRIGGER, fetchCompanies);
  yield takeLatest(getVenuesSearchAction.TRIGGER, fetchVenues);
  yield takeLatest(getProfilesAction.TRIGGER, fetchProfilesList);
  yield takeEvery(getList.TRIGGER, getListSaga);
  yield takeEvery(getOne.TRIGGER, getOneSaga);
  yield takeEvery(updateOne.TRIGGER, updateOneSaga);
  yield takeEvery(deleteEntity.TRIGGER, deleteOneSaga);
  yield takeEvery(createEntity.TRIGGER, createEntitySaga);
  yield takeEvery(getWholeList.TRIGGER, getWholeListSaga);
  yield takeLatest(getDynamicCountriesAction.TRIGGER, fetchDynamicCountries);
}
